import React, { useEffect, useRef } from "react";
import "./WelcomeModal.css";
import TabShow from "../_Common/TabShow";
import {
  emailSvg,
  facebookSvg,
  tiktokSvg,
  instagramSvg,
  linkedInSvg,
  ytSvg,
  phoneSvg,
  CloseSvg2,
} from "../_Common/IconSvg";
import { Translate } from "../../../utils/lang/translate";
import GroupeBtn from "../_Common/GroupeBtn";
import { Event } from "../_Common";

const items = [
  { label: Translate("WelcomeMessage", "StopDesk") },
  { label: Translate("WelcomeMessage", "Confirmation") },
  { label: Translate("WelcomeMessage", "homeDelivery") },
  { label: Translate("WelcomeMessage", "Packing") },
  { label: Translate("WelcomeMessage", "Storage") },
  { label: Translate("WelcomeMessage", "Marketing") },
];

interface GriditemProps {
  label: string
}

function Griditem({ label }: GriditemProps) {
  return (
    <li className="services__Item">
      <span>{label}</span>
    </li>
  );
}

interface AboutUsModalProps {
  onClose: () => void,
  info?: any
}

const AboutUsModal = ({ onClose, info }: AboutUsModalProps) => {

  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if(modalRef.current && !modalRef.current?.contains(e.target as Node)){
      Event("ABOUT_US_MODAL", "CLOSE_ABOUT_US_MODAL", "CLICK_EVENT");
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <TabShow
      darkBg={true}
      noReturn={true}
      noPadding={true}
      style={{
        minWidth: "50vw",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <div ref={modalRef} className="FlWd modal__content about__us__modal__content RlPs">
        <div style={{ position: "absolute", top: "20px", right: "20px", zIndex: 10 }}>
          <GroupeBtn 
            data_btn={[
              {
                svgbtn: CloseSvg2,
                type: "Gray",
                action: () => {
                  Event("ABOUT_US_MODAL", "CLOSE_ABOUT_US_MODAL", "CLICK_EVENT");
                  onClose();
                },
              }
            ]}
          />
          </div>
        <div className="FlWd about__us__modal__header about__us__modal__header__bg">
          <img
            src={"/assets/img/maystrologowelcome.png"}
            alt="..."
          />
        </div>
        <p className="welcome__message">
          {Translate("WelcomeMessage", "primaryMessage").concat(" !")}
        </p>
        <p className="secondary__welcome__message">
          {Translate("WelcomeMessage", "secondarymessage")}
        </p>
        <ul className="services__grid">
          {items.map((item, index) => (
            <Griditem key={index} label={item.label} />
          ))}
        </ul>
        <div className="divider" />
        <div className="contact__us__container">
          <div className="contact__us__col">
            {Translate("WelcomeMessage", "contact_us")}
          </div>
          <div className="contact__us__col">
            <div className="contact__item">
              <span> {emailSvg} </span>
              <span>{info?.Email}</span>
            </div>
            <div className="contact__item">
              <span> {emailSvg} </span>
              <span>contact@maystro-delivery.com</span>
            </div>
            <div className="contact__item">
              <span> {phoneSvg} </span>
              <span>
                {" "}
                {Translate("WelcomeMessage", "customerSupport")}&#x200E; {info?.["Phone number"]}
                {" "}
              </span>
            </div>
            <div className="contact__item">
              <span> {phoneSvg} </span>
              <span>
                {" "}
                {Translate("WelcomeMessage", "commercialTeam")}&#x200E; (+213) 770 27 54 57
                {" "}
              </span>
            </div>
            <div className="contact__item">
              <span> {phoneSvg} </span>
              <span>
                {" "}
                {Translate("WelcomeMessage", "commercialTeam")}&#x200E; (+213) 770 58 56 10
                {" "}
              </span>
            </div>
            <div className="contact__item">
              <span> {phoneSvg} </span>
              <span>
                {" "}
                {Translate("WelcomeMessage", "commercialTeam")}&#x200E; (+213) 770 60 86 50
                {" "}
              </span>
            </div>
            <div className="contact__item">
              <span> {phoneSvg} </span>
              <span>
                {" "}
                {Translate("WelcomeMessage", "commercialTeam")}&#x200E; (+213) 770 60 87 98
                {" "}
              </span>
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="contact__us__container">
          <div className="contact__us__col">
            {Translate("WelcomeMessage", "stayConnected")}
          </div>
          <div className="contact__us__col">
            <div className="social__media">
              <a href={info?.Facebook_link}>{facebookSvg}</a>
              <a href={info?.Instagram}>{instagramSvg}</a>
              <a href={info?.Tiktok}>{tiktokSvg}</a>
              <a href={info?.Youtube}>{ytSvg}</a>
              <a href={info?.LinkedIn}>{linkedInSvg}</a>
            </div>
          </div>
        </div>
      </div>
    </TabShow>
  );
}
 
export default AboutUsModal;