import React from "react";
const MainCardItemSkeletonLoader = () => {
  return (
    <div className="dashboard-main-card-loading-item">
      <div className="dashboard-main-card-loading-title"/>
      <div className="dashboard-main-card-loading-text"/>
      <div className="dashboard-main-card-loading-info"/>
    </div>
  );
}
 
export default MainCardItemSkeletonLoader;