import React from "react";
import Card from "../../_Common/Card/Card";
const DashboardNotificationSkeletonLoader = () => {
  return (
    <Card
      padding={8}
    >
      <div className="InFlx AlgnItm spcBtwn">
        <div className="InFlx AlgnItm" style={{ flexGrow: 1, gap: "10px" }}>
          <div className="dashboard-notification-card-loading-icon"/>
          <div className="dashboard-notification-card-loading-text"/>
        </div>
      </div>
    </Card>
  );
}
 
export default DashboardNotificationSkeletonLoader;