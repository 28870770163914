import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { userActions } from "../../../actions";
import GroupeForm from "../../_Common/GroupeForm";
import CheckBoxForm from "../../_Common/CheckBoxForm";
import Loader from "../../_Common/Loader";
import HeadLog from "../_Common/HeadLog";
import ContainerLog from "../_Common/ContainerLog";
import Button from "../../_Common/Button";
import { Link, Redirect } from "react-router-dom";
import { Translate } from "../../../utils/lang/translate";
import Cookies from "js-cookie";
import { Event, initGAPg } from "../../StorePages/_Common";
import {
  eye_hide_password_Icon,
  eye_show_password_Icon,
} from "../../StorePages/_Common/IconSvg";

interface ErrorInterface {
  username?: string;
  password?: string;
}

const LoginPage = ({ loginIn, data, Login }: any) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [remember, setRemember] = useState<boolean>(false);
  const [submited, setSubmitted] = useState<boolean>(false);
  const [errors, setErrors] = useState<ErrorInterface>({
    username: "",
    password: "",
  });
  const [passwordShown, setPasswordShown] = useState(false);

  useEffect(() => {
    document.title = "Maystro Delivery - " + Translate("titles", "login");
    initGAPg();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    name === "username" ? setUsername(value) : setPassword(value);
  };

  const handleChangecheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setRemember(checked);
    Event("LOGIN", "USE_REMEMBER_ME", !checked ? "UNCHECK" : "CHECK");
  };

  const clickForgotPassword = () => {
    Event("LOGIN", "FORGOT_PASSWORD", "CLICK_EVENT");
  };

  const goToSignUpPage = () => {
    Event("LOGIN", "DONT_HAVE_ACCOUNT", "CLICK_EVENT");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let valid = true,
      err: ErrorInterface = {};
    Event("LOGIN", "SUBMIT_LOGIN_INFOS", "CLICK_EVENT");
    if (!username) {
      err.username = Translate("error", "e16");
      valid = false;
    }
    if (!password) {
      err.password = Translate("error", "e17");
      valid = false;
    }
    setErrors(err);

    if (!valid) {
      Event("LOGIN", "LOGIN_INFOS_ERRORS", `[${Object.keys(err).join(",")}]`);
      return;
    }
    let user = { username, password };
    setSubmitted(true);
    Login(user);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  if (submited && loginIn === "1") {
    if (
        (data.is_store && data.is_enabled && data.is_verfied) || 
        (!data.is_store && data.is_enabled)
      ) {
      var date = new Date();
      date.setDate(date.getDate() + 365);
      Cookies.set("auth", data.token, {
        expires: remember ? date : undefined,
      });
      Cookies.set("c_store", data.store_id, {
        expires: remember ? date : undefined,
      });
      window.location.href = "/";
    } else {
      if(!data.is_enabled) {
        return <Redirect to="/disabled-account"/>
      }
      if (!data.is_verfied) {
        return <Redirect to="/succes/register" />;
      }
    }
  }

  return (
    <ContainerLog>
      <HeadLog
        titleHead={Translate("auths", "login")}
        descHead={Translate("auths", "logintext")}
      />
      {submited && loginIn === "2" ? (
        <div style={{ color: "red" }}>{Translate("error", "e18")}</div>
      ) : (
        ""
      )}
      <form method="post" onSubmit={handleSubmit} className="StMarMx">
        <GroupeForm
          id={"Username"}
          name={"username"}
          placeholder={Translate("auths", "username")}
          text={Translate("auths", "username")}
          type={"text"}
          workfun={handleChange}
          error={errors.username}
        />
        <GroupeForm
          id={"Password"}
          name={"password"}
          placeholder={Translate("auths", "password")}
          text={Translate("auths", "password")}
          onClick={togglePassword}
          type={passwordShown ? "text" : "Password"}
          more={
            <Link
              tabIndex={-1}
              to="/password/reset"
              onClick={clickForgotPassword}
            >
              {Translate("auths", "forgetpassword")}
            </Link>
          }
          workfun={handleChange}
          error={errors.password}
          icon={passwordShown ? eye_show_password_Icon : eye_hide_password_Icon}
        />
        <CheckBoxForm
          id={"RememberPasword"}
          name={"remember"}
          text={Translate("auths", "rememberpassword")}
          workfun={handleChangecheck}
        />
        <div className="SmtGrp MrAot StMarMx">
          <Button BtnText={Translate("auths", "signin")} />
        </div>
      </form>
      <div className="StOpcVal">
        <div className="StMrg">
          <span className="StWdDst">
            {Translate("auths", "donthaveaccount")}
          </span>
          <Link
            className="blueapp stbold StWdDst"
            to="/register"
            onClick={goToSignUpPage}
          >
            {Translate("auths", "createaccount")}
          </Link>
        </div>
        {/* <div className="StMrg">
                      <span>
                          {Translate("auths","signsocial")}
                      </span>
                  </div> */}
      </div>
      {loginIn === "0" && <Loader styl={{ borderRadius: "24px" }} />}
    </ContainerLog>
  );
};

function mapState(state: any) {
  const { loginIn, data } = state.authentication;
  return { loginIn, data };
}

const actionCreators = {
  Login: userActions.Login,
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };
