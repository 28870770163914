import { ListSvg, MoneySvg2, StockSvg, TruckSvg2, ViewSvg2, addSvg2, editSvg2 } from "../components/StorePages/_Common/IconSvg";
import { Translate } from "../utils/lang/translate";

export const teamConstants = {
  GET_TEAM_MEMBERS_REQUEST: "GET_TEAM_MEMBERS_REQUEST",
  GET_TEAM_MEMBERS_SUCCESS: "GET_TEAM_MEMBERS_SUCCESS",
  GET_TEAM_MEMBERS_FAILURE: "GET_TEAM_MEMBERS_FAILURE",
  GET_TEAM_DATA_REQUEST: "GET_TEAM_DATA_REQUEST",
  GET_TEAM_DATA_SUCCESS: "GET_TEAM_DATA_SUCCESS",
  GET_TEAM_DATA_FAILURE: "GET_TEAM_DATA_FAILURE",
  ADD_TEAM_MEMBER_REQUEST: "ADD_TEAM_MEMBER_REQUEST",
  ADD_TEAM_MEMBER_SUCCESS: "ADD_TEAM_MEMBER_SUCCESS",
  ADD_TEAM_MEMBER_FAILURE: "ADD_TEAM_MEMBER_FAILURE",
  TOGGLE_TEAM_MEMBER_REQUEST: "DELETE_TEAM_MEMBER_REQUEST",
  TOGGLE_TEAM_MEMBER_SUCCESS: "DELETE_TEAM_MEMBER_SUCCESS",
  TOGGLE_TEAM_MEMBER_FAILURE: "DELETE_TEAM_MEMBER_FAILURE",
  UPLOAD_TEAM_MEMBER_PICTURE_REQUEST: "UPLOAD_TEAM_MEMBER_PICTURE_REQUEST",
  UPLOAD_TEAM_MEMBER_PICTURE_SUCCESS: "UPLOAD_TEAM_MEMBER_PICTURE_SUCCESS",
  UPLOAD_TEAM_MEMBER_PICTURE_FAILURE: "UPLOAD_TEAM_MEMBER_PICTURE_FAILURE",
  CLEAR_ADD_DELETE_STORE: "CLEAR_ADD_DELETE_STORE",
  CLEAR_UPDATE_STORE: "CLEAR_UPDATE_STORE",
  GET_TEAM_MEMBER_DATA_REQUEST: "GET_TEAM_MEMBER_DATA_REQUEST",
  GET_TEAM_MEMBER_DATA_SUCCESS: "GET_TEAM_MEMBER_DATA_SUCCESS",
  GET_TEAM_MEMBER_DATA_FAILURE: "GET_TEAM_MEMBER_DATA_FAILURE",
  UPDATE_TEAM_MEMBER_REQUEST: "UPDATE_TEAM_MEMBER_REQUEST",
  UPDATE_TEAM_MEMBER_SUCCESS: "UPDATE_TEAM_MEMBER_SUCCESS",
  UPDATE_TEAM_MEMBER_FAILURE: "UPDATE_TEAM_MEMBER_FAILURE"
};

export const permissionsColors = {
  add: "#2BC194",
  edit: "#E68411",
  view: "#0094FF"
}

export const permissions = [
  {
    id: "view_order",
    name: Translate("settings", "view_order"),
    icons: [ViewSvg2, ListSvg],
    color: permissionsColors.view
  },
  {
    id: "view_stock",
    name: Translate("settings", "view_stock"),
    icons: [ViewSvg2, StockSvg],
    color: permissionsColors.view
  },
  {
    id: "add_order",
    name: Translate("settings", "add_order"),
    icons: [addSvg2, ListSvg],
    color: permissionsColors.add
  },
  {
    id: "view_pickup",
    name: Translate("settings", "view_pickup"),
    icons: [ViewSvg2, TruckSvg2],
    color: permissionsColors.view
  },
  {
    id: "change_order",
    name: Translate("settings", "change_order"),
    icons: [editSvg2, ListSvg],
    color: permissionsColors.edit
  },
  {
    id: "view_payment",
    name: Translate("settings", "view_payment"),
    icons: [ViewSvg2, MoneySvg2],
    color: permissionsColors.view
  }
]

