import React from "react";

interface StatusBadgeProps {
  text: string,
  bgClass: string,
  colorClass: string
}

const StatusBadge = ({ text, bgClass, colorClass }: StatusBadgeProps) => {

  return (
    <div
      className={`InFlx AlgnItm JstfCnt ${bgClass} ${colorClass}`} 
      style={{ 
        fontSize: "20px", 
        letterSpacing: "0.3px", 
        fontWeight: 600, 
        padding: "6px 16px",
        borderRadius: "50px" 
      }}
    >
      {text}
    </div>
  );
}
 
export default StatusBadge;