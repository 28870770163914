export const userConstants = {
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SENTUSER: "USERS_REGISTER_SENTUSER",

  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",

  UPDATE_STORE_SUCCESS: "UPDATE_STORE_SUCCESS",
  UPDATE_STORE_REQUEST: "UPDATE_STORE_REQUEST",
  UPDATE_STORE_FAILURE: "UPDATE_STORE_FAILURE",

  CREATE_TEAM_SUCCESS: "CREATE_TEAM_SUCCESS",
  CREATE_TEAM_REQUEST: "CREATE_TEAM_REQUEST",
  CREATE_TEAM_FAILURE: "CREATE_TEAM_FAILURE",

  UPDATE_TEAM_SUCCESS: "UPDATE_TEAM_SUCCESS",
  UPDATE_TEAM_REQUEST: "UPDATE_TEAM_REQUEST",
  UPDATE_TEAM_FAILURE: "UPDATE_TEAM_FAILURE",

  LIST_TEAM_SUCCESS: "LIST_TEAM_SUCCESS",
  LIST_TEAM_REQUEST: "LIST_TEAM_REQUEST",
  LIST_TEAM_FAILURE: "LIST_TEAM_FAILURE",

  INVITE_MEMBER_SUCCESS: "INVITE_MEMBER_SUCCESS",
  INVITE_MEMBER_REQUEST: "INVITE_MEMBER_REQUEST",
  INVITE_MEMBER_FAILURE: "INVITE_MEMBER_FAILURE",

  VERIFY_MEMBER_SUCCESS: "VERIFY_MEMBER_SUCCESS",
  VERIFY_MEMBER_REQUEST: "VERIFY_MEMBER_REQUEST",
  VERIFY_MEMBER_FAILURE: "VERIFY_MEMBER_FAILURE",

  CREATE_MEMBER_SUCCESS: "CREATE_MEMBER_SUCCESS",
  CREATE_MEMBER_REQUEST: "CREATE_MEMBER_REQUEST",
  CREATE_MEMBER_FAILURE: "CREATE_MEMBER_FAILURE",

  GET_TEAM_DETAIL_SUCCESS: "GET_TEAM_DETAIL_SUCCESS",
  GET_TEAM_DETAIL_REQUEST: "GET_TEAM_DETAIL_REQUEST",
  GET_TEAM_DETAIL_FAILURE: "GET_TEAM_DETAIL_FAILURE",

  DELETE_TEAM_SUCCESS: "DELETE_TEAM_SUCCESS",
  DELETE_TEAM_REQUEST: "DELETE_TEAM_REQUEST",
  DELETE_TEAM_FAILURE: "DELETE_TEAM_FAILURE",

  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",
  GET_USER_REQUEST: "GET_USER_REQUEST",

  GET_STOREINFO_SUCCESS: "GET_STORE_INFO_SUCCESS",
  GET_STOREINFO_FAILURE: "GET_STORE_INFO_FAILURE",
  GET_STOREINFO_REQUEST: "GET_STORE_INFO_REQUEST",

  UPLOAD_PICTURE_REQUEST: "UPLOAD_PICTURE_REQUEST",
  UPLOAD_PICTURE_SUCCESS: "UPLOAD_PICTURE_SUCCESS",
  UPLOAD_PICTURE_FAILURE: "UPLOAD_PICTURE_FAILURE",

  UPLOAD_USER_PICTURE_REQUEST: "UPLOAD_USER_PICTURE_REQUEST",
  UPLOAD_USER_PICTURE_SUCCESS: "UPLOAD_USER_PICTURE_SUCCESS",
  UPLOAD_USER_PICTURE_FAILURE: "UPLOAD_USER_PICTURE_FAILURE",

  RESET_SMS_REQUEST: "RESET_SMS_REQUEST",
  RESET_SMS_SUCCESS: "RESET_SMS_SUCCESS",
  RESET_SMS_FAILURE: "RESET_SMS_FAILURE",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  SET_IS_CANARY: "SET_IS_CANARY",

  LOGOUT: "USERS_LOGOUT",

  NEW_USER: "NEW_USER",

  GET_WEBHOOK_REQUEST: "GET_WEBHOOK_REQUEST",
  GET_WEBHOOK_SUCCESS: "GET_WEBHOOK_SUCCESS",
  GET_WEBHOOK_FAILURE: "GET_WEBHOOK_FAILURE",

  DELETE_WEBHOOK_REQUEST: "DELETE_WEBHOOK_REQUEST",
  DELETE_WEBHOOK_SUCCESS: "DELETE_WEBHOOK_SUCCESS",
  DELETE_WEBHOOK_FAILURE: "DELETE_WEBHOOK_FAILURE",

  POST_WEBHOOK_REQUEST: "POST_WEBHOOK_REQUEST",
  POST_WEBHOOK_SUCCESS: "POST_WEBHOOK_SUCCESS",
  POST_WEBHOOK_FAILURE: "POST_WEBHOOK_FAILURE",

  TEST_WEBHOOK_REQUEST: "TEST_WEBHOOK_REQUEST",
  TEST_WEBHOOK_SUCCESS: "TEST_WEBHOOK_SUCCESS",
  TEST_WEBHOOK_FAILURE: "TEST_WEBHOOK_FAILURE",

  GET_TYPES_WEBHOOK_REQUEST: "GET_TYPES_WEBHOOK_REQUEST",
  GET_TYPES_WEBHOOK_SUCCESS: "GET_TYPES_WEBHOOK_SUCCESS",
  GET_TYPES_WEBHOOK_FAILURE: "GET_TYPES_WEBHOOK_FAILURE",

  POST_BORDEREAU_REQUEST: "POST_BORDEREAU_REQUEST",
  POST_BORDEREAU_SUCCESS: "POST_BORDEREAU_SUCCESS",
  POST_BORDEREAU_FAILURE: "POST_BORDEREAU_FAILURE",

  CHECK_USERNAME_REQUEST: "CHECK_USERNAME_REQUEST",
  CHECK_USERNAME_SUCCESS: "CHECK_USERNAME_SUCCESS",
  CHECK_USERNAME_FAILURE: "CHECK_USERNAME_FAILURE",

  CHECK_PHONE_REQUEST: "CHECK_PHONE_REQUEST",
  CHECK_PHONE_SUCCESS: "CHECK_PHONE_SUCCESS",
  CHECK_PHONE_FAILURE: "CHECK_PHONE_FAILURE",

  CHECK_NAME_STORE_REQUEST: "CHECK_NAME_STORE_REQUEST",
  CHECK_NAME_STORE_SUCCESS: "CHECK_NAME_STORE_SUCCESS",
  CHECK_NAME_STORE_FAILURE: "CHECK_NAME_STORE_FAILURE",

  UPDATE_SHOW_EXCHANGE_MODALS: "UPDATE_SHOW_EXCHANGE_MODALS",
  UPDATE_SHOW_REWARDS_MODALS_REQUEST: "UPDATE_SHOW_REWARDS_MODALS_REQUEST",
  UPDATE_SHOW_REWARDS_MODALS_SUCCESS: "UPDATE_SHOW_REWARDS_MODALS_SUCCESS",
  UPDATE_SHOW_REWARDS_MODALS_FAILURE: "UPDATE_SHOW_REWARDS_MODALS_FAILURE",

  UPDATE_SHOW_OOKADO_MODAL: "UPDATE_SHOW_OOKADO_MODAL",

  UPDATE_SHOW_ABOUT_US_MODAL: "UPDATE_SHOW_ABOUT_US_MODAL",

  SHOW_BOTH_EXCHANGE_POPUPS: 0,
  SHOW_ONLY_EXCHANGE_GUIDE_POPUP: 1,
  SHOW_ONLY_EXCHANE_MODAL_POPUP: 2,
  DONT_SHOW_ANY_EXCHANGE_POPUPS: 3
};
