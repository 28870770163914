import React from "react";
import Helmet from "react-helmet";
import { FormatDate, Translate } from "../../utils/lang/translate";
import DayPicker, { DateUtils } from "react-day-picker";
import { DropDownList } from "./DropDownList";
import { CalnderSvg } from "../StorePages/_Common/IconSvg";
import "react-day-picker/lib/style.css";
import Cookies from "js-cookie";
const WEEKDAYS_SHORT = {
  ar: ["أحـ", "إثنـ" ,"ثلثـ" ,"أربـ" ,"خميـ" ,"جمـ" ,"سبت"],
  fr: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
};
const MONTHS = {
  ar: [
    "جانفي",
    "فيفري",
    "مارس",
    "افريل",
    "ماي",
    "جوان",
    "جويلية",
    "اوت",
    "سبتمبر",
    "اكتوبر",
    "توفمبر",
    "ديسمبر",
  ],
  fr: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
};

const WEEKDAYS_LONG = {
  ar: ["السبت", "الاثنين", "الثلثاء", "الاربعاء", "الخميس", "الجمعة", "الاحد"],
  fr: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
};
export default class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.ranges = {
      t0: 0,
      t1: 1,
      t2: 7,
      t3: 30,
      t4: 90,
      t5: new Date().getDate() - 1,
    };
    this.state = {
      from: this.props.from ? new Date(this.props.from) : null,
      to: this.props.to ? new Date(this.props.to) : null,
      enteredTo: this.props.to ? new Date(this.props.to) : null,
      showState: false,
    };
    this.refDate = React.createRef();
    this.handleView = this.handleView.bind(this);
    this.handleClickOutSide = this.handleClickOutSide.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.excuteDateRange = this.excuteDateRange.bind(this);
    this.isSelectingFirstDay = this.isSelectingFirstDay.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutSide);
  }
  componentDidMount() {
    if (this.props.initDate && !(this.props.from && this.props.to)) {
      this.handleChange("", "t2");
    }
  }
  componentDidUpdate(prevProps) {
    if(
      prevProps.from !== this.props.from 
      || prevProps.to !== this.props.to
    ){
      this.setState({
        from: this.props.from ? new Date(this.props.from) : null,
        to: this.props.to ? new Date(this.props.to) : null,
        enteredTo: this.props.to ? new Date(this.props.to) : null
      })
    }
  }
  isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }
  handleResetClick() {
    this.setState({
      from: null,
      to: null,
      enteredTo: null
    });
  }
  handleDayClick(day) {
    const { from, to } = this.state;
    if (from && to && day >= from && day <= to) {
      this.handleResetClick();
      return;
    }
    if (this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        from: day,
        to: null,
        enteredTo: null,
      });
    } else {
      this.setState({
        to: day,
        enteredTo: day,
      });
      this.excuteDateRange(from, day);
    }
  }

  handleDayMouseEnter(day) {
    const { from, to } = this.state;
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      });
    }
  }

  excuteDateRange(began, end) {
    let from, to;
    if (began) {
      from = began.toISOString().split("T")[0];
    }
    if (end) {
      to = end.toISOString().split("T")[0];
    }
    if (this.props.forceUp) {
      this.props.forceUp();
    }
    this.props.changeFrom(from);
    this.props.changeTo(to);
  }
  handleView() {
    let currentView = this.state.showState;
    if (currentView) {
      document.removeEventListener("mousedown", this.handleClickOutSide);
    } else {
      document.addEventListener("mousedown", this.handleClickOutSide);
    }
    this.setState({
      showState: !currentView,
    });
  }
  handleClickOutSide(e) {
    if (this.refDate && !this.refDate.current.contains(e.target)) {
      this.handleView();
    }
  }
  handleChange(text, value) {
    let from = new Date();
    let to = new Date();
    from.setDate(to.getDate() - this.ranges[value]);
    if (value === "t1") to.setDate(from.getDate());
    this.setState({
      from: from,
      to: to,
    });
    this.excuteDateRange(from, to);
  }
  render() {
    const { from, to, enteredTo } = this.state;
    const modifiers = { start: from, end: enteredTo };
    const disabledDays = { before: from };
    const selectedDays = [from, { from, to: enteredTo }];

    let lang = Cookies.get("lang");

    return (
      <div className="StDrc" ref={this.refDate}>
        <div className="CrsPoi FlHg" onClick={this.handleView}>
          <div
            className="InFlx AlgnItm FrInp StBrdRdS FlHg sTbxSz"
            style={{ padding: "5px 10px" }}
          >
            <span>
              {this.state.from
                ? FormatDate(new Date(this.state.from), true)
                : Translate("datepicker", "startdate")}
            </span>
            <span style={{ margin: "0 5px" }}>-</span>
            <span>
              {this.state.to
                ? FormatDate(new Date(this.state.to), true)
                : Translate("datepicker", "enddate")}
            </span>
            <i className="InvStMrtg InFlx">{CalnderSvg}</i>
          </div>
        </div>
        <div
          className={
            (this.state.showState ? "" : "hidElem2") +
            " ClSidTh StBgbrds StAbs StLanNl StBxSh1 responsPopup"
          }
          style={{ paddingTop: "1em", zIndex: "3", top: "45px", minWidth: "562px" }}
        >
          <div className="MrAot StwdMaxC StmpB">
            <label className="lnhg-vl">
              {Translate("datepicker", "daterange")}
            </label>
            <DropDownList
              style={{ padding: "9px 13px", width: "320px" }}
              initValue={""}
              onChange={this.handleChange}
              placeholder={Translate("datepicker", "selectraneg")}
              listSelect={
                !this.props.shopRange
                  ? [
                      { text: Translate("datepicker", "today"), value: "t0" },
                      {
                        text: Translate("datepicker", "yestoday"),
                        value: "t1",
                      },
                      { text: Translate("datepicker", "last7"), value: "t2" },
                      { text: Translate("datepicker", "last30"), value: "t3" },
                      { text: Translate("datepicker", "last90"), value: "t4" },
                      {
                        text: Translate("datepicker", "lastmonth"),
                        value: "t5",
                      },
                    ]
                  : [
                      { text: Translate("datepicker", "last7"), value: "t2" },
                      { text: Translate("datepicker", "last30"), value: "t3" },
                      { text: Translate("datepicker", "last90"), value: "t4" },
                      {
                        text: Translate("datepicker", "lastmonth"),
                        value: "t5",
                      },
                    ]
              }
            />
          </div>
          <div className="InFlx responsFlx" style={{ margin: "0 1em" }}>
            <div
              className="InFlx AlgnItm FrInp StBrdRdS"
              style={{ width: "224px", padding: "8px 13px" }}
            >
              <span>
                {this.state.from
                  ? FormatDate(new Date(this.state.from), true)
                  : Translate("datepicker", "startdate")}
              </span>
              <i className="StAutMr InFlx">{CalnderSvg}</i>
            </div>
            <div
              className="InFlx StAutMr FrInp StBrdRdS"
              style={{ width: "224px", padding: "8px 13px" }}
            >
              <span>
                {this.state.to
                  ? FormatDate(new Date(this.state.to), true)
                  : Translate("datepicker", "enddate")}
              </span>
              <i className="StAutMr InFlx">{CalnderSvg}</i>
            </div>
          </div>
          <DayPicker
            locale={lang}
            className="Range"
            months={MONTHS[lang]}
            weekdaysLong={WEEKDAYS_LONG[lang]}
            weekdaysShort={WEEKDAYS_SHORT[lang]}
            numberOfMonths={2}
            fromMonth={from}
            selectedDays={selectedDays}
            disabledDays={disabledDays}
            modifiers={modifiers}
            onDayClick={this.handleDayClick}
            onDayMouseEnter={this.handleDayMouseEnter}
            labels={{
              nextMonth: "Next Month",
              previousMonth: "Previous Month",
            }}
          />
          <Helmet>
            <style>{`
              .DayPicker-Month{
                background: var(--bdgclInp);
                display:block;
                padding:15px 10px;
                border-radius:7px;
              }
              .DayPicker-Caption{
                text-align:center;
              }
              .DayPicker-Caption > div{
                font-weight: 600 !important;
                font-size:.95em;
              }
              .Selectable .DayPicker-Day {
                  border-radius: 0 !important;
              }
              .Selectable .DayPicker-Day--start {
                  border-top-left-radius: 7px !important;
                  border-bottom-left-radius: 7px !important;
              }
              .Selectable .DayPicker-Day--end {
                  border-top-right-radius: 7px !important;
                  border-bottom-right-radius: 7px !important;
              }
              .DayPicker-Day:not(.DayPicker-Day--selected):hover{
                  background-color: var(--gcl) !important;
              }
              .DayPicker-Day{
                font-size:.9em;
              }
              .DayPicker-Day:focus{
                  outline:none !important;
              }
              .Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                background-color: var(--gcl) !important;
                color: var(--fntClr) !important;
              }
              .Range .DayPicker-Day {
                border-radius: 0 !important;
              }
              `}</style>
          </Helmet>
        </div>
      </div>
    );
  }
}
