import { Bundle, DataShop, LandingPageSettings, ProductShop } from "../types";
type Key = keyof Object;

export const convertTimeIntoString: (value: number) => string = (value) => {
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value - hours * 3600) / 60);
  const seconds = value - hours * 3600 - minutes * 60;

  const hoursString = hours === 0 ? "" : `${hours}h `;
  const minutesString = minutes === 0 ? "" : `${minutes}m `;
  const secondsString = `${seconds}s`;

  return hoursString + minutesString + secondsString;
};

export const convertMoneyIntoString: (
  value: number,
  currency: string
) => string = (value, currency) => {
  if (value === 0) return value + currency;
  return (
    value
      .toString()
      .split("")
      .reverse()
      .map((char, i) => (!!(i % 3) || i === 0 ? char : char + ","))
      .reverse()
      .join("") + currency
  );
};

export const filterObjectByChangingValues: (
  object1: Object,
  object2: Object
) => Object = (object1, object2) => {
  let result = {};
  for (const key in object1) {
    if (
      typeof object1[key as Key] !== "undefined" &&
      typeof object2[key as Key] !== "undefined" &&
      JSON.stringify(object1[key as Key]) !==
        JSON.stringify(object2[key as Key])
    ) {
      result = { ...result, [key]: object2[key as Key] };
    }
  }

  return result;
};

export const getProductFromResponse: (data: any) => ProductShop = (data) => {
  return {
    title: data.title,
    slug: data.slug,
    options: data.options?.map((option: any) => {
      return {
        name: option.name,
        quantity: option.quantity,
        reduced_price: option.reduced_price,
        price: option.price,
        id: option.id,
      };
    }),
    images: data.images?.map((image: any) => {
      return {
        url: image.url,
        id: image.id,
        order: image.order,
      };
    }),
    categories: data.categories,
    description: data.description,
    how_to_use: data.how_to_use,
    specifications: data.specifications,
    product_id: data.product_id,
    display_id: data.display_id,
    published: data.published,
    id: data.id,
    landing_page: data.landing_page,
    lang: data.lang,
    rate: data.rate,
    pricing_option: data.pricing_option,
    template_details: [
      { custom_sections: data.template_details[0]?.custom_sections ?? [] },
    ],
    is_variant: data.is_variant,
    variants: data.variants
  };
};

export const getBundleFromResponse: (data: any) => Bundle = (data) => {
  return {
    product_bundle: data.product_bundle?.map((prod: any) => {
      return {
        title: prod.title,
        id: prod.id,
        display_id: prod.display_id,
        product: prod.product,
        quantity: prod.quantity,
      };
    }),
    price: data.price,
    reduced_price: data.reduced_price,
    id: data.id,
    display_id: data.display_id,
    pricing_option: data.pricing_option,
  };
};

export const getSettingsFromResponse: (data: any) => DataShop = (data) => {
  return {
    settings_stage: data.settings_stage,
    logo: data.logo,
    name: data.name,
    website_title: data.website_title,
    store_categories: data.store_categories,
    theme_template: data.theme_template,
    subdomain_slug: data.subdomain_slug,
    pixel_key: data.pixel_key,
    analytics_data: data.analytics_data,
    published: data.published,
    domain: data.domain,
    products: data.products?.map((product: any) => {
      return getProductFromResponse(product);
    }),
    shop_bundles: data.shop_bundles?.map((bundle: any) => {
      return getBundleFromResponse(bundle);
    }),
  };
};

export const getLandingPageSettingsFromResponse: (
  data?: any
) => LandingPageSettings = (data) => {
  return {
    id: data?.id,
    header: data?.header,
    congratulation_message: data?.congratulation_message,
    social_media_url: {
      tiktok: data?.social_media_url?.tiktok,
      facebook: data?.social_media_url?.facebook,
      instagram: data?.social_media_url?.instagram,
    },
    custom_colors: {
      button_bg: data?.custom_colors?.button_bg,
      box_bg: data?.custom_colors?.box_bg,
      font_color: data?.custom_colors?.font_color,
      primary_color: data?.custom_colors?.primary_color,
      btn_font_color: data?.custom_colors?.btn_font_color,
    },
  };
};

export const createFormDataFromObject = (element: Object) => {
  const formData = new FormData();
  for (const key in element) {
    const value: any = element[key as Key];

    if (Array.isArray(value)) {
      value.map((elem: any) => {
        if (
          typeof elem === "object" &&
          !(elem instanceof File) &&
          !Array.isArray(elem) &&
          elem !== null
        ) {
          return formData.append(key, JSON.stringify(elem));
        }
        return formData.append(key, elem);
      });
    } else {
      formData.append(key, value);
    }
  }
  return formData;
};

export const createObjectFromKeys = (element: Object, keys: string[]) => {
  let result = {};
  keys.forEach((key: string) => {
    result = { ...result, [key]: element[key as Key] };
  });
  return result;
};

export const createFormDataFromArray = (array: any[], key: string) => {
  const formData = new FormData();
  array.forEach((elem) => {
    formData.append(key, elem);
  });
  return formData;
};

export const whichWilayaHasPermissionToStopDesk = (code: any) => {
  return (
    code === 16 ||
    code === 1 ||
    code === 2 ||
    code === 3 ||
    code === 4 ||
    code === 5 ||
    code === 6 ||
    code === 7 ||
    code === 12 ||
    code === 13 ||
    code === 15 ||
    code === 17 ||
    code === 19 ||
    code === 23 ||
    code === 25 ||
    code === 30 ||
    code === 31 ||
    code === 39 ||
    code === 45 ||
    code === 47
  );
};

export const convertDescriptionToSlug = (description: string) =>
  description
    .toLowerCase()
    .replace(/[éèêë]/gm, "e")
    .replace(/[àâä]/gm, "a")
    .replace(/[ç]/gm, "c")
    .replace(/[ùûü]/gm, "u")
    .replace(/[ôö]/gm, "o")
    .replace(/[ïî]/gm, "i")
    .replace(/[_]/gm, " ")
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-")
    .replace(/^-/g, "");

export const priceChecker = (price: any, reducedPrice: any): boolean => {
  if (!price) return false;
  if (!reducedPrice) return true;
  if (typeof price !== "number") price = parseInt(price);
  if (typeof reducedPrice !== "number") reducedPrice = parseInt(reducedPrice);
  return price > reducedPrice;
};

export const checkUserCanCreateOrderWithoutProduct = (id: string) => {
  return (
    id === "82bc5c9b-c65d-46ea-b426-c1237d83238e" ||
    id === "12895c73-990c-475b-8df6-533eaa9ea501" ||
    id === "3a2855aa-9319-481e-ac50-164083b8a297"
  );
};

export const getParamsFromUrl = (param: string) => {
  let vars: { [key: string]: string } = {};
  window.location.href
    .replace(window.location.hash, "")
    .replace(/[?&]+([^=&]+)=?([^&]*)?/gi, (m, key, value) => {
      vars[key] = value ?? "";
      return "";
    });
  if (param) {
    return vars[param] ?? null;
  }
  return vars;
};

export const checkPhoneNumberValidity = (phoneNumber: string) => {
  const phoneNumberRegex = new RegExp(
    `^((0|\\+213)((5|6|7)(\\d){8}|(49|27|29|32|33|34|25|26|37|43|46|21|23|36|48|38|31|45|35|41|24)(\\d){6}))|((\\+216)?(((2|5|6|7|9)\\d)|(46))(\\d){6})$`
  );
  return phoneNumberRegex.test(phoneNumber);
};

export const getUnfCountFilters = (filter: string) => {
  return [
    getApprovedUnfFilter(filter),
    getCancelledUnfFilter(filter),
    getCallbackUnfFilter(filter),
    getPostponedUnfFilter(filter),
  ];
};

const getApprovedUnfFilter = (filter: string) => {
  const urlParams = new URLSearchParams(filter);
  urlParams.set("status", "1");
  urlParams.delete("is_callback");
  urlParams.delete("postponed");
  return "?" + urlParams.toString();
};

const getCancelledUnfFilter = (filter: string) => {
  const urlParams = new URLSearchParams(filter);
  urlParams.set("status", "2");
  urlParams.delete("is_callback");
  urlParams.delete("postponed");
  return "?" + urlParams.toString();
};

const getCallbackUnfFilter = (filter: string) => {
  const urlParams = new URLSearchParams(filter);
  urlParams.set("status", "0");
  urlParams.set("is_callback", "True");
  urlParams.delete("postponed");
  return "?" + urlParams.toString();
};

const getPostponedUnfFilter = (filter: string) => {
  const urlParams = new URLSearchParams(filter);
  urlParams.set("status", "0");
  urlParams.delete("is_callback");
  urlParams.set("postponed", "True");
  return "?" + urlParams.toString();
};

export const getCurrentDeliveriesArray = (
  num_deliveries: number, 
  deliveries_per_tier: number[]
): number[] => {

  let current_deliveries_array: number[] = [];
  let remaining = num_deliveries;

  deliveries_per_tier.forEach((value) => {
    if(value > remaining){
      current_deliveries_array = [...current_deliveries_array, remaining];
      remaining = 0;
    }else{
      if(remaining > 0){
        current_deliveries_array = [...current_deliveries_array, value];
        remaining -= value;
      }else{
        current_deliveries_array = [...current_deliveries_array, 0];
      }
    }
  });

  return current_deliveries_array;
};
