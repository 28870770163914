import React, { useEffect, useMemo, useState } from "react";
import ContainerPage from "../_Common/ContainerPage";
import CustomLink from "../_Common/CustomLink";
import "./RewardsPage.css";
import GroupeBtn from "../_Common/GroupeBtn";
import { Translate } from "../../../utils/lang/translate";
import { 
  CoinSvg, 
  InfoSvg,
  DeliveryFeesSvg,
  ReturnFeesSvg,
  PremiumFeesSvg,
  GiftBoxSvg,
  CircledWarehouseSvg,
  CircledReturnBox,
  CircledDeliveryTruckSvg, 
  benefitsDeliveryTruck,
  benefitsPremium,
  benefitsReturnFees,
  benefitsFreePickupCheck,
} from "../_Common/IconSvg";
import RewardsTierChart from "./RewardsTierChart";
import RewardsOverviewModal from "./RewardsOverviewModal";
import RewardsHowitworksModal from "./RewardsHowitworksModal";
import { rewardsActions } from "../../../actions/rewards.actions";
import { connect } from "react-redux";
import { RequestStatus, RewardsData, TiersDataItem } from "../../../types";
import { alertActions, userActions } from "../../../actions";
import Loader from "../../_Common/Loader";
import { getCurrentDeliveriesArray } from "../../../utils/helpers";
import RewardsNewTierModal from "./RewardsNewTierModal";
import { Event } from "../_Common";
import { autoSpendPoints, renderTierStyle } from "../../../constants/rewards.constants";
import ToggleSwitch from "../../_Common/ToggleSwitch";
import { RewardsService } from "./types";
import Card from "../_Common/Card/Card";
import BenefitsProgressTier from "./components/BenefitsProgressTier";
import Cookies from "js-cookie";

const lang = Cookies.get("lang");

interface RewardsPageProps {
  GetRewards: () => void,
  dataRewards: RewardsData,
  GettingRewards: RequestStatus,
  SendAlert: (code: string, text: string, action: string) => void,
  dtStoreinfo: any,
  UpdateShowRewardsModals: (value: boolean) => void,
  UpdatingRewardsModals: RequestStatus,
  GetTiersData: () => void,
  GettingTiersData: RequestStatus,
  tiersData: TiersDataItem[],
  updatingAutoSpendpoints: RequestStatus,
  UpdateAutoSpendPoints: (value: number) => void
};

const RewardsPage = ({
  GetRewards,
  dataRewards,
  GettingRewards,
  SendAlert,
  dtStoreinfo,
  UpdateShowRewardsModals,
  UpdatingRewardsModals,
  GetTiersData,
  GettingTiersData,
  tiersData,
  updatingAutoSpendpoints,
  UpdateAutoSpendPoints
}: RewardsPageProps) => {

  const RewardsServices: RewardsService[] = [
    {
      code: 1,
      title: Translate("RewardsPage", `service1_name`),
      text: Translate("RewardsPage", `service1`),
      icon: DeliveryFeesSvg,
      secondaryIcon: CircledDeliveryTruckSvg
    },
    {
      code: 2,
      title: Translate("RewardsPage", `service2_name`),
      text: Translate("RewardsPage", `service2`),
      icon: PremiumFeesSvg,
      secondaryIcon: CircledWarehouseSvg,
      action: 
        <ToggleSwitch 
          isOn={
            GettingRewards === "2" && 
            (dataRewards.auto_spend_points === autoSpendPoints.BOTH 
            || dataRewards.auto_spend_points === autoSpendPoints.PREMIUM_FEES)
          }
          toggleHandler={() => {
            Event("VIEW_REWARDS", "PREMIUM_FEES_TOGGLE_SWITCH", "CLICK_EVENT");
            switch (dataRewards.auto_spend_points) {
              case autoSpendPoints.BOTH:
                UpdateAutoSpendPoints(autoSpendPoints.RETURN_FEES);
                break;
              
              case autoSpendPoints.PREMIUM_FEES:
                UpdateAutoSpendPoints(autoSpendPoints.NONE);
                break;

              case autoSpendPoints.RETURN_FEES:
                UpdateAutoSpendPoints(autoSpendPoints.BOTH);
                break;
              
              case autoSpendPoints.NONE:
                UpdateAutoSpendPoints(autoSpendPoints.PREMIUM_FEES);
                break;
              
              default:
                break;
            } 
          }} 
        />
    },
    {
      code: 3,
      title: Translate("RewardsPage", `service3_name`),
      text: Translate("RewardsPage", `service3`),
      icon: GiftBoxSvg,
      secondaryIcon: CircledDeliveryTruckSvg,
    },
    {
      code: 4,
      title: Translate("RewardsPage", `service4_name`),
      text: Translate("RewardsPage", `service4`),
      icon: ReturnFeesSvg,
      secondaryIcon: CircledReturnBox,
      action: 
        <ToggleSwitch 
          isOn={
            GettingRewards === "2" && 
            (dataRewards.auto_spend_points === autoSpendPoints.BOTH
            || dataRewards.auto_spend_points === autoSpendPoints.RETURN_FEES)
          }
          toggleHandler={() => {
            Event("VIEW_REWARDS", "RETURN_FEES_TOGGLE_SWITCH", "CLICK_EVENT");
            switch (dataRewards.auto_spend_points) {
              case autoSpendPoints.BOTH:
                UpdateAutoSpendPoints(autoSpendPoints.PREMIUM_FEES);
                break;
              
              case autoSpendPoints.PREMIUM_FEES:
                UpdateAutoSpendPoints(autoSpendPoints.BOTH);
                break;

              case autoSpendPoints.RETURN_FEES:
                UpdateAutoSpendPoints(autoSpendPoints.NONE);
                break;
              
              case autoSpendPoints.NONE:
                UpdateAutoSpendPoints(autoSpendPoints.RETURN_FEES);
                break;
              
              default:
                break;
            }
          }} 
        />
    },
  ]

  const storeServices: RewardsService[] = useMemo(() => {
    if(GettingRewards === "2"){
      return RewardsServices.filter(
        (item, _) => dataRewards.tier.services.find((service, _) => service.code === item.code)
      );
    }else{
      return [];
    }
  }, [GettingRewards]);

  const hard_coded_tiers_data: TiersDataItem[] = [
    {
      id: "32a1ed64-4a80-4c27-b66b-b71936f22481",
      name: "Member",
      ratio: "1.00",
      min_deliveries: 0,
      max_deliveries: 199
    },
    {
      id: "2c7781bc-76e5-4bcc-b9e9-789088462452",
      name: "Friend",
      ratio: "1.25",
      min_deliveries: 200,
      max_deliveries: 999
    },
    {
      id: "4e57af48-5dfe-42dd-9704-c15fda88d505",
      name: "Best Friend",
      ratio: "1.50",
      min_deliveries: 1000,
      max_deliveries: 1999
    },
    {
      id: "f3952099-d500-4dc9-a054-4cc9fe543864",
      name: "Family",
      ratio: "1.75",
      min_deliveries: 2000,
      max_deliveries: 9999
    },
    {
      id: "39dfa2aa-b197-4255-aea7-883606f417ce",
      name: "Ambassador",
      ratio: "2.00",
      min_deliveries: 10000,
      max_deliveries: 100000
    }
  ];

  const starterBenefits: Record<"Member" | "Friend" | "Best Friend" | "Family" | "Ambassador", JSX.Element | null> = {
    Member:
      <div 
        style={{ 
          border: "1px solid #05775E", 
          background: "linear-gradient(91deg, #075E4C 0.6%, #022C25 99.72%)",
          padding: "40px 20px", 
        }}
        className="InFlx Stclmnf align-items-start JstfCnt RlPs StBrdRd marginTopSmall HdOvrfl"
      >
        <h4 className="DlMg" style={{ fontSize: "20px", fontWeight: 800 }}>Free pick-up</h4>
        <div className="StAbs" style={{ top: "50%", right: 0, transform: "translateY(-50%)" }}>
          {benefitsFreePickupCheck}
        </div>
      </div>  
    ,
    Friend: 
      <div 
        style={{ 
          border: "1px solid #B94F04", 
          background: "linear-gradient(91deg, #B94F04 0.6%, #3C1807 99.72%)",
          padding: "40px 20px", 
        }}
        className="InFlx Stclmnf align-items-start RlPs StBrdRd marginTopSmall HdOvrfl"
      >
        <h4 className="DlMg" style={{ fontSize: "20px", fontWeight: 800 }}>150 DA</h4>
        <p className="DlMg" style={{ fontSize: "14px", fontWeight: 300 }}>{Translate("RewardsPage", "returnservicefees")}</p>
        <div className="StAbs" style={{ top: "50%", right: 0, transform: "translateY(-50%)" }}>
          {benefitsReturnFees}
        </div>
      </div>
    ,
    "Best Friend":
      <div 
        style={{ 
          border: "1px solid #B94F04", 
          background: "linear-gradient(91deg, #B94F04 0.6%, #3C1807 99.72%)",
          padding: "10px 20px", 
        }}
        className="InFlx Stclmnf align-items-start RlPs StBrdRd marginTopSmall HdOvrfl"
      >
        <h4 className="DlMg" style={{ fontSize: "20px", fontWeight: 800 }}>100 DA</h4>
        <p className="DlMg" style={{ fontSize: "14px", fontWeight: 300 }}>{Translate("RewardsPage", "returnservicefees")}</p>
        <div className="StAbs" style={{ bottom: "-8px", right: 0 }}>
          {benefitsReturnFees}
        </div>
      </div>
    ,
    Family:
      <div 
        style={{ 
          border: "1px solid #B94F04", 
          background: "linear-gradient(91deg, #B94F04 0.6%, #3C1807 99.72%)",
          padding: "10px 20px", 
        }}
        className="InFlx Stclmnf align-items-start RlPs StBrdRd marginTopSmall HdOvrfl"
      >
        <h4 className="DlMg" style={{ fontSize: "20px", fontWeight: 800 }}>Free</h4>
        <p className="DlMg" style={{ fontSize: "14px", fontWeight: 300 }}>{Translate("RewardsPage", "returnservicefees")}</p>
        <div className="StAbs" style={{ bottom: "-8px", right: 0 }}>
          {benefitsReturnFees}
        </div>
      </div>
    ,
    Ambassador: null
  }

  const pointsPerTierArray: number[] = useMemo(() => {
    if(GettingTiersData === "2" && GettingRewards === "2"){
      const array = hard_coded_tiers_data.map((tier, index) => {
        if(index !== hard_coded_tiers_data.length - 1){
          return hard_coded_tiers_data[index + 1].min_deliveries - tier.min_deliveries;
        }else{
          return hard_coded_tiers_data[hard_coded_tiers_data.length - 1].min_deliveries;
        }
      });

      if(dataRewards.num_deliveries < hard_coded_tiers_data[hard_coded_tiers_data.length - 2].min_deliveries){
        return array.slice(0, -2);
      }else{
        return array.slice(-2, -1);
      }

    }
    else {
      return [];
  };
  }, [GettingTiersData, GettingRewards]);


  const [showOverviewModal, setShowOverviewModal] = useState(false);
  const [showHowitworksModal, setShowHowitworksModal] = useState(false);
  const [showNewTierModal, setShowNewTierModal] = useState(true);

  useEffect(() => {
    GetRewards();
    GetTiersData();
  }, []);

  useEffect(() => {
    
    if(GettingRewards === "3"){
      SendAlert("50", "failed to fetch rewards", "");
    };

    if(UpdatingRewardsModals === "3"){
      SendAlert("50", "failed to update", "");
    }

    if(GettingTiersData === "3"){
      SendAlert("50", "failed to get tiers data", "");
    }

    if(updatingAutoSpendpoints === "3"){
      SendAlert("50", "failed to update", "");
    }

    if(updatingAutoSpendpoints === "2"){
      SendAlert("41", "successfuly updated!", "");
    }

  }, [GettingRewards, UpdatingRewardsModals, GettingTiersData, updatingAutoSpendpoints]);

  return (
    <>
      {
        (GettingRewards === "1" && GettingTiersData === "1")
        && 
        <Loader />
      }
      {
        (GettingRewards === "2" && GettingTiersData === "2")
        &&
        <ContainerPage
          decorationImage={dataRewards.tier.name === "Ambassador" ? "/assets/img/Ambassador_decoration.png" : null}
          page_title={Translate("RewardsPage", "rewards")}
          data_top={[
            <CustomLink 
              text={Translate("RewardsPage", "howItWorks")} 
              onClick={() => {
                Event("VIEW_REWARDS", "OPEN_HOW_IT_WORKS_MODAL", "CLICK_EVENT");
                setShowHowitworksModal(true);
              }}
            />,
          ]}
        >
          <div className="FlWd rewards-main-layout">
            <div className={`rewards-overview-layout ${renderTierStyle[dataRewards.tier.name].border} ClSidTh StBrdRd RlPs`}>
              <div style={{ position: "absolute", top: "20px", right: "20px", zIndex: 10 }}>
                <GroupeBtn 
                  data_btn={[
                    {
                      svgbtn: InfoSvg,
                      type: "Gray",
                      tooltip: Translate("footer", "help"),
                      action: () => {
                        Event("VIEW_REWARDS", "OPEN_OVERVIEW_MODAL", "CLICK_EVENT");
                        setShowOverviewModal(true);
                      },
                    }
                  ]}
                />
              </div>
              <div className="FlWd InFlx AlgnItm" style={{ gap: "24px", justifyContent: "space-evenly", flexWrap: "wrap" }}>
                <div className="InFlx Stclmnf AlgnItm" style={{ gap: "6px" }}>
                  <h3 className="DlMg overview-title">{Translate("RewardsPage", "redeemable_points")}</h3>
                  <div className="InFlx AlgnItm" style={{ gap: "10px" }}>
                    <div>{CoinSvg}</div>
                    <h2 className="DlMg overview-points-count">
                      {Number(dataRewards.num_points)}
                    </h2>
                  </div>
                  <h4 className="DlMg sTclPg overview-expires-date">
                    {Translate("RewardsPage", "expires_at")} { dataRewards.points_expire_at }
                  </h4>
                </div>
                <div className="overview-divider"/>
                <div className="InFlx Stclmnf AlgnItm" style={{ gap: "6px" }}>
                  <h3 className="DlMg overview-title">{Translate("RewardsPage", "your_current_tier")}</h3>
                  <div className="InFlx AlgnItm" style={{ gap: "5px" }} dir="ltr">
                    {renderTierStyle[dataRewards.tier.name].svg}
                    <h2 className={`DlMg overview-current-tier ${renderTierStyle[dataRewards.tier.name].text}`}>
                      {dataRewards.tier.name}
                    </h2>
                  </div>
                  <h4 className="DlMg sTclPg overview-expires-date">
                    {
                      dataRewards.tier.name === "Member"
                      ? Translate("RewardsPage", "delivermore")
                      :`${Translate("RewardsPage", "valid_until")} ${dataRewards.expire_at}`
                    }
                  </h4>
                </div>
                <div className="overview-divider"/>
                <div className="InFlx Stclmnf AlgnItm" style={{ gap: "6px" }}>
                  <h3 className="DlMg overview-title">{Translate("RewardsPage", "your_deliveries")}</h3>
                  <div className="InFlx align-items-end" style={{ gap: "5px" }} dir="ltr">
                    <h2 className="DlMg overview-current-deliveries">
                      {dataRewards.num_deliveries}
                    </h2>
                    {
                      dataRewards.num_deliveries < hard_coded_tiers_data[hard_coded_tiers_data.length - 2].min_deliveries
                      &&
                      <h2 className="DlMg sTclPg overview-target-deliveries">
                        /{
                          dataRewards.num_deliveries < dataRewards.tier.min_deliveries ? 
                            dataRewards.tier.min_deliveries 
                          : 
                            dataRewards.next_tier.min_deliveries
                        }
                      </h2>
                    }
                  </div>
                  <h4 className="DlMg sTclPg overview-expires-date">
                    {
                      dataRewards.num_deliveries > hard_coded_tiers_data[hard_coded_tiers_data.length - 2].min_deliveries
                      ? <> {Translate("RewardsPage", "morerewardsawaiting")} </>
                      : dataRewards.num_deliveries < dataRewards.tier.min_deliveries ?
                        <> {dataRewards.tier.min_deliveries - dataRewards.num_deliveries} {Translate("RewardsPage", "remainingDeliveriesToKeep")} {dataRewards.tier.name} </>
                        : <> {dataRewards.points_to_next_tier} {Translate("RewardsPage", "remainingDeliveries")} {dataRewards.next_tier.name} </>
                    }
                  </h4>
                </div>
              </div>
            </div>
            <div className="rewards-chart-layout ClSidTh StBrdRd">
              <RewardsTierChart
                pointsArray={getCurrentDeliveriesArray(dataRewards.num_deliveries, pointsPerTierArray)}
                pointsPerTierArray={pointsPerTierArray}
                deliveriesNumber={dataRewards.num_deliveries}
                tierNames={
                  dataRewards.num_deliveries < hard_coded_tiers_data[hard_coded_tiers_data.length - 2].min_deliveries
                  ?
                    [
                    'Member',
                    'Friend',
                    'Best Friend',
                    ]
                  :
                    [
                      'Family'
                    ]
                }
                backgroundColors={
                  dataRewards.num_deliveries < hard_coded_tiers_data[hard_coded_tiers_data.length - 2].min_deliveries
                  ?
                    [
                      ['#008AFF', '#019C6E'],
                      ['#019C6E', '#8FAB24'],
                      ['#A0AB24', '#F5A200'], 
                    ]
                  :
                    dataRewards.num_deliveries < hard_coded_tiers_data[hard_coded_tiers_data.length - 1].min_deliveries
                    ?
                      [
                        ['#F5A201', '#EE7200'],
                      ]
                    :
                      [
                        ['#AB851D', '#EAD9B5'],
                      ]
                }
              />
              <h5 className="DlMg doughnut-chart-remaining-deliveries">
              {
                dataRewards.num_deliveries > hard_coded_tiers_data[hard_coded_tiers_data.length - 2].min_deliveries
                ? <> {Translate("RewardsPage", "morerewardsawaiting2")} </>
                : dataRewards.num_deliveries < dataRewards.tier.min_deliveries ?
                  <> {dataRewards.tier.min_deliveries - dataRewards.num_deliveries} {Translate("RewardsPage", "remainingDeliveriesToKeep")} {dataRewards.tier.name} </>
                  : <> {dataRewards.points_to_next_tier} {Translate("RewardsPage", "remainingDeliveries")} {dataRewards.next_tier.name} </>
              }
              </h5>
            </div>
            <div className="rewards-spend-points-layout ClSidTh StBrdRd">
              <h3 className="DlMg rewards-overview-title">{Translate("RewardsPage", "spend_your_points")}</h3>
              <div className="FlWd InFlx Stclmnf" style={{ gap: "10px" }}>
                {
                  storeServices.map((item, index) => (
                    <>
                      <div key={index} className="InFlx align-items-start" style={{ gap: "10px" }}>
                        <div>{item.icon}</div>
                        <div className="FlWd InFlx spctwB">
                          <div className="InFlx Stclmnf align-items-start" style={{ gap: "5px" }}>
                            <h4 className="DlMg rewards-spend-points-secondary-title">{item.title}</h4>
                            <h5 className="DlMg sTclPg rewards-spend-points-text">{item.text}</h5>
                          </div>
                        </div>
                        {item.action}
                      </div>
                      {
                        index !== dataRewards.tier.services.length - 1
                        &&
                        <div className="FlWd divider"/>
                      }
                    </>        
                  ))
                }
              </div>
            </div>
            {
              dataRewards.tier.name !== "Ambassador"
              &&
              <div className="rewards-benefits-layout ClSidTh StBrdRd">
                <Card className="rewards-benefits-tier-layout" bgColor="var(--gcl)">
                  <div className="InFlx AlgnItm JstfCnt" style={{ gap: "40px", margin: "auto" }}>
                    <div className="RlPs">
                      <div 
                        className={`StAbs StTpNl StRgNl StBtNl StlfNl ${renderTierStyle[dataRewards.tier.name].bgColorClass}`}
                        style={{ filter: "blur(70px)" }} 
                      />
                      {renderTierStyle[dataRewards.tier.name].benefitsSvg}
                    </div>
                    <div className="InFlx Stclmnf" style={{ gap: "12px", maxWidth: "350px" }}>
                      <h3 className="DlMg" style={{ fontSize: "24px", fontWeight: 700 }}>
                        <span>{Translate("RewardsPage", "youaareour")}</span>
                        <span 
                          style={{ margin: "0 5px" }}
                          className={`${renderTierStyle[dataRewards.tier.name].text}`}
                        >
                          {dataRewards.tier.name}
                        </span>
                      </h3>
                      <p className="DlMg sTclPg rewards-spend-points-secondary-title">
                        {Translate("RewardsPage", "benefitstiertext")}
                      </p>
                    </div>
                  </div>
                </Card>
                <Card className="rewards-benefits-tier-benefits-layout" bgColor="var(--gcl)">
                  <h3 className="DlMg rewards-overview-title">{Translate("RewardsPage", "yourtierbenefits")}</h3>
                  {
                    dataRewards.delivery_percentage !== null
                    &&
                    <div 
                      style={{ 
                        border: "1px solid #008AFF", 
                        background: "linear-gradient(91deg, #145294 0.93%, #11335A 98.67%)",
                        padding: "10px 20px", 
                      }}
                      className="InFlx Stclmnf align-items-start RlPs StBrdRd marginTopSmall HdOvrfl"
                    >
                      <h4 className="DlMg" style={{ fontSize: "20px", fontWeight: 800 }}>
                        <span>{Translate("RewardsPage", "upto")}</span>
                        <span dir="ltr" style={{ margin: "0 3px" }}>{dataRewards.delivery_percentage}%</span>
                      </h4>
                      <p className="DlMg" style={{ fontSize: "14px", fontWeight: 300 }}>{Translate("RewardsPage", "deliveryservicefees")}</p>
                      <div 
                        className="StAbs" 
                        style={lang === "ar" ? { bottom: "-8px", left: 0 } : { bottom: "-8px", right: 0 }}
                      >
                        {benefitsDeliveryTruck}
                      </div>
                    </div>
                  }
                  {
                    dataRewards.return_fees !== null
                    &&
                    <div 
                      style={{ 
                        border: "1px solid #B94F04", 
                        background: "linear-gradient(91deg, #B94F04 0.6%, #3C1807 99.72%)",
                        padding: "10px 20px", 
                      }}
                      className="InFlx Stclmnf align-items-start RlPs StBrdRd marginTopSmall HdOvrfl"
                    >
                      <h4 className="DlMg" style={{ fontSize: "20px", fontWeight: 800, color: "white" }}>{dataRewards.return_fees} {lang === "ar" ? dtStoreinfo.country.ar_currency : dtStoreinfo?.country.lt_currency}</h4>
                      <p className="DlMg" style={{ fontSize: "14px", fontWeight: 300, color: "white" }}>{Translate("RewardsPage", "returnservicefees")}</p>
                      <div 
                        className="StAbs" 
                        style={
                          lang === "ar" ? 
                              { left: 0 } 
                            : 
                              { right: 0 }
                        }
                      >
                        {benefitsReturnFees}
                      </div>
                    </div>
                  }
                  {
                    (
                      dataRewards.tier.name === "Member"
                      && !dtStoreinfo?.stock_managed
                    )
                    &&
                    <div 
                      style={{ 
                        border: "1px solid #05775E", 
                        background: "linear-gradient(91deg, #075E4C 0.6%, #022C25 99.72%)",
                        padding: "40px 20px", 
                      }}
                      className="InFlx Stclmnf align-items-start JstfCnt RlPs StBrdRd marginTopSmall HdOvrfl"
                    >
                      <h4 className="DlMg" style={{ fontSize: "20px", fontWeight: 800, color: "white" }}>{Translate("RewardsPage", "freepickup")}</h4>
                      <div 
                        className="StAbs" 
                        style={
                          lang === "ar"
                          ?
                            { top: "50%", left: 0, transform: "translateY(-50%)" }
                          :
                            { top: "50%", right: 0, transform: "translateY(-50%)" }
                        }
                      >
                        {benefitsFreePickupCheck}
                      </div>
                    </div>
                  }
                  {
                    dataRewards.storage_percentage !== null
                    &&
                    <div 
                      style={{ 
                        border: "1px solid #B94F04", 
                        background: "linear-gradient(91deg, #B94F04 0.6%, #3C1807 99.72%)",
                        padding: "10px 20px", 
                      }}
                      className="InFlx Stclmnf align-items-start RlPs StBrdRd marginTopSmall HdOvrfl"
                    >
                      <h4 dir="ltr" className="DlMg" style={{ fontSize: "20px", fontWeight: 800, color: "white" }}>{dataRewards.storage_percentage}%</h4>
                      <p className="DlMg" style={{ fontSize: "14px", fontWeight: 300, color: "white" }}>{Translate("RewardsPage", "premiumfees")}</p>
                      <div 
                        className="StAbs" 
                        style={
                          lang === "ar" ? 
                              { left: 0, bottom: "-10px" } 
                            : 
                              { right: 0 }
                        }
                      >
                        {benefitsPremium}
                      </div>
                    </div>
                  }
                </Card>
                <Card className="rewards-benefits-progress-layout RlPs" bgColor="var(--gcl)">
                  <div className="InFlx AlgnItm spcBtwn" style={{ zIndex: 10, padding: "30px 20px" }} dir="ltr">
                    {
                      tiersData.filter((tier, _) => tier.name !== "Ambassador").map((tier, index) => (
                        <BenefitsProgressTier 
                          key={index} 
                          tierName={tier.name}
                          isCurrent={tiersData.findIndex((tier, _) => tier.name === dataRewards.tier.name) === index}
                          locked={tiersData.findIndex((tier, _) => tier.name === dataRewards.tier.name) < index} 
                        />    
                      ))
                    }
                  </div>
                  <div className="StAbs rewards-benefits-progress-bar" />
                </Card>
              </div>
            }
            
            {
              showOverviewModal
              &&
              <RewardsOverviewModal 
                onClose={() => {
                  Event("VIEW_REWARDS", "CLOSE_OVERVIEW_MODAL", "CLICK_EVENT");
                  setShowOverviewModal(false);
                }}
              />
            }
    
            {
              showHowitworksModal
              &&
              <RewardsHowitworksModal
                services={storeServices}
                tiersData={dataRewards.tier.name === "Ambassador" ? hard_coded_tiers_data : hard_coded_tiers_data.filter((tier, _) => tier.name !== "Ambassador")}
                onClose={() => {
                  Event("VIEW_REWARDS", "CLOSE_HOW_IT_WORKS_MODAL", "CLICK_EVENT");
                  setShowHowitworksModal(false);
                }} 
              />
            }

            {
              dtStoreinfo?.show_fidelitytier_popup && showNewTierModal
              &&
              <RewardsNewTierModal
                newTierName={dataRewards.tier.name} 
                newTierStyles={renderTierStyle[dataRewards.tier.name]}
                onClose={() => {
                  Event("VIEW_REWARDS", "CLOSE_NEW_TIER_MODAL", "CLICK_EVENT");
                  UpdateShowRewardsModals(false);
                  setShowNewTierModal(false)
                }}
                validDate={dataRewards.expire_at}
              />
            }

          </div>
        </ContainerPage>
      }
      {
        (GettingRewards === "3" && GettingTiersData === "3")
        &&
        <div>failed to get rewards</div>
      }
    </>
  );
}

function mapState(state: any) {
  const { dtStoreinfo, UpdatingRewardsModals } = state.user;
  const { dataRewards, GettingRewards, GettingTiersData, tiersData, updatingAutoSpendpoints } = state.rewards;
  return { 
    dataRewards, 
    GettingRewards, 
    dtStoreinfo, 
    UpdatingRewardsModals,
    tiersData,
    GettingTiersData,
    updatingAutoSpendpoints 
  }
};

const actionCreators = {
  GetRewards: rewardsActions.GetRewards,
  SendAlert: alertActions.SendAlert,
  UpdateShowRewardsModals: userActions.UpdateShowRewardsModals,
  GetTiersData: rewardsActions.GetTiersData,
  UpdateAutoSpendPoints: rewardsActions.UpdateAutoSpendPoints
}

const connctedRewardsPage = connect(mapState, actionCreators)(RewardsPage);
export { connctedRewardsPage as RewardsPage };