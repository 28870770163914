import React, { useEffect, useRef, useState } from "react";
import ContainerPage from "../_Common/ContainerPage";
import { connect } from "react-redux";
import { FeatureLoked } from "../_Common/FeatureLoked";
import BtnLg from "../_Common/BtnLg";
import { Translate } from "../../../utils/lang/translate";
import Card from "../_Common/Card/Card";
import { teamsActions } from "../../../actions/teams.actions";
import GroupeForm from "../../_Common/GroupeForm";
import { Select } from "../_Common";
import { permissions } from "../../../constants/team.constants";
import { CloseSvg2, eye_hide_password_Icon, eye_show_password_Icon } from "../_Common/IconSvg";
import { Link, useParams } from "react-router-dom";
import { RequestStatus, TeamMemberData } from "../../../types";
import { alertActions } from "../../../actions";
import { Event } from "../_Common";


const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

interface IStoreMemberInfo{
  full_name?: string,
  username?: string,
  password?: string,
  email?: string,
  permissions?: string[],
  image: string
};

interface ICreateMemberError{
  full_name?: string,
  username?: string,
  password?: string,
  email?: string,
}

interface PermissionType {
  id: string;
  name: string;
  icons: JSX.Element[];
  color: string;
};

interface TeamMemberPageProps{
  dataProfile: any,
  UpdateTeamMember: (memberId: string, data: any, profile_picture_data: FormData | null) => void,
  GetTeamMemberData: (memberId: string) => void,
  SendAlert: (code: string, text: string, action: string) => void,
  GettingTeamMember: RequestStatus,
  teamMemberData: TeamMemberData,
  UpdatingTeamMember: RequestStatus,
  UploadingTeamMemberPic: RequestStatus,
  updateTeamMemberData: TeamMemberData,
  teamMemberPicture: string | null,
  ClearUpdateStore: () => void
};

const TeamMembrePage = ({
  dataProfile,
  UpdateTeamMember,
  GetTeamMemberData,
  GettingTeamMember,
  teamMemberData,
  SendAlert,
  UpdatingTeamMember,
  UploadingTeamMemberPic,
  updateTeamMemberData,
  teamMemberPicture,
  ClearUpdateStore
}: TeamMemberPageProps) => {

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { memberId } = useParams<{ memberId: string }>();

  const [storeMemberInfo, setStoreMemberInfo] = useState<IStoreMemberInfo>({
    email: "",
    full_name: "",
    password: "",
    username: "",
    permissions: [],
    image: ""
  });

  const [error, setError] = useState<ICreateMemberError>({});
  
  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    fileInputRef?.current?.click();
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    Event("EDIT_TEAM_MEMBER", "TOGGLE_PASSWORD", "CLICK_EVENT");
    setPasswordShown(prev => !prev);
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    Event("EDIT_TEAM_MEMBER", "UPLOAD_NEW_TEAM_MEMBER_IMAGE", "CLICK_EVENT");
    const file = (event.target.files ?? [])[0];
    if (file && file.type.substr(0, 5) === "image") {
        const reader = new FileReader();
        reader.onloadend = () => {
          setStoreMemberInfo(prev => ({ ...prev, image: reader.result as string }));
        };
        reader.readAsDataURL(file);
    } else {
      setStoreMemberInfo(prev => ({ ...prev, image: "" }));
    }
  };

  const handleSubmit = () => {
    Event("EDIT_TEAM_MEMBER", "SUBMIT_EDIT_TEAM_MEMBER", "CLICK_EVENT");
    let updatedData = storeMemberInfo;
    
    if(!storeMemberInfo.full_name || storeMemberInfo.full_name === "") {
      delete updatedData.full_name;
    }
    
    if(!storeMemberInfo.email || storeMemberInfo.email === "") {
      delete updatedData.email;
    }else{
      if(!validateEmail(storeMemberInfo.email)) setError(prev => ({ ...prev, email: Translate("TeamsPage", "email_err") }));
    }

    if(!storeMemberInfo.username || storeMemberInfo.username === "") {
      delete updatedData.username;
    }
    if(!storeMemberInfo.password || storeMemberInfo.password === "") {
      delete updatedData.password;
    }
    if(!storeMemberInfo.permissions || storeMemberInfo.permissions.length === 0){
      delete updatedData.permissions;
    }

    const file = (fileInputRef?.current?.files ?? [])[0];
  
    if(file){
      const formData = new FormData();
      formData.append("profile_picture", file);
      UpdateTeamMember(
        memberId, 
        {
          ...updatedData,
          permissions: storeMemberInfo.permissions?.map((permissionId, _) => ({ codename: permissionId }))
        }, 
        formData
      );

    }else{
      UpdateTeamMember(
        memberId,
        {
          ...updatedData,
          permissions: storeMemberInfo.permissions?.map((permissionId, _) => ({ codename: permissionId }))
        }, 
        null
      );
    }

  };

  useEffect(() => {
    GetTeamMemberData(memberId);
  }, [memberId]);

  useEffect(() => {
    
    if(GettingTeamMember === "2"){
      setStoreMemberInfo({
        email: teamMemberData.email,
        full_name: teamMemberData.full_name,
        image: teamMemberData.picture ?? "",
        password: "",
        username: teamMemberData.username,
        permissions: teamMemberData.permissions.map((item, _) => item.codename)
      });
    }

    if(GettingTeamMember === "3"){
      SendAlert("50", "Failed to get team member data", "");
    }

  }, [GettingTeamMember]);

  useEffect(() => {
    if(UpdatingTeamMember === "2"){
      ClearUpdateStore();
      setStoreMemberInfo({
        email: updateTeamMemberData.email,
        full_name: updateTeamMemberData.full_name,
        image: updateTeamMemberData.picture ?? "",
        password: "",
        username: updateTeamMemberData.username,
        permissions: updateTeamMemberData.permissions.map((item, _) => item.codename)
      });
      SendAlert("41", "Team member updated successfuly", "");
    }

    if(UpdatingTeamMember === "3"){
      SendAlert("50", "Failed to update team member data", "");
    }
  }, [UpdatingTeamMember]);


  useEffect(() => {

    if(UploadingTeamMemberPic === "2"){
      ClearUpdateStore();
      setStoreMemberInfo(prev => ({ ...prev, image: teamMemberPicture ?? "" }));
      SendAlert("41", "Profile picture uploaded successfuly", "");
    };

    if(UploadingTeamMemberPic === "3"){
      SendAlert("50", "Failed to upload profile picture", "");
    }

  }, [UploadingTeamMemberPic])

  return (
    <ContainerPage page_title={
      <div className="StDrc">
        <Link className="StOpcVal" to={"/settings/teams"}>
          {Translate("settings", "storeteam")}
        </Link>
        <div className="Inlflx StWdDst">
          {Translate("orders", "flech")}
        </div>
        <div className="Inlflx">
          {Translate("TeamsPage", "editstorestaff")}
        </div>
      </div>
    }>
      {
        dataProfile?.is_store
        ?
          <div className="FlWd InFlx Stclmnf" style={{ gap: "40px" }}>
            <div className="InFlx align-items-start" style={{ gap: "40px" }}>
              <Card>
                <Card.Header>{Translate("TeamsPage", "memberinfo")}</Card.Header>
                <div className="InFlx AlgnItm" style={{ gap: "30px", padding: "20px 0" }}>
                  <div className="StWdpic StBr60 HdOvrfl">
                    <img
                      className="StWdpic"
                      src={
                        storeMemberInfo.image !== "" && storeMemberInfo.image !== null ? 
                        storeMemberInfo.image : "/assets/img/default_user.png"
                      }
                      alt={"..."}
                    />
                  </div>
                  <div className="InFlx Stclmnf align-items-start" style={{ gap: "5px" }}>
                    <h4 className="DlMg add-team-member-modal-upload-text">{Translate("TeamsPage", "memberphoto")}</h4>
                    <input type="file" ref={fileInputRef} onChange={handleImageChange} style={{ display: "none" }}/>
                    <a href="" className="add-team-member-modal-upload-image" onClick={handleLinkClick}>{Translate("TeamsPage", "uploadimage")}</a>
                  </div>
                </div>
                <div className="InFlx Stclmnf" style={{ gap: "10px", minWidth: "375px" }}>
                  <GroupeForm
                    id={"add-member-full-name"}
                    name={"add-member-full-name"}
                    placeholder={Translate("auths", "fullname")}
                    text={Translate("auths", "fullname")}
                    type={"text"}
                    value={storeMemberInfo.full_name}
                    workfun={(e: React.ChangeEvent) => {
                      const { value } = e.target as HTMLInputElement;
                      setStoreMemberInfo(prev => ({ ...prev, full_name: value }));
                      if(value && value !== ""){
                        setError(prev => { 
                          delete prev.full_name; 
                          return prev; 
                        });
                      }
                    }}
                    disabled={GettingTeamMember === "1" || UpdatingTeamMember === "1"}
                    error={error.full_name}
                    stclass={error.full_name ? "borderError" : ""}
                  />
                  <GroupeForm
                    id={"add-member-user-name"}
                    name={"add-member-user-name"}
                    placeholder={Translate("auths", "username")}
                    text={Translate("auths", "username")}
                    type={"text"}
                    value={storeMemberInfo.username}
                    workfun={(e: React.ChangeEvent) => {
                      const { value } = e.target as HTMLInputElement;
                      setStoreMemberInfo(prev => ({ ...prev, username: value }));
                      if(value && value !== ""){
                        setError(prev => { 
                          delete prev.username; 
                          return prev 
                        });
                      }
                    }}
                    disabled={GettingTeamMember === "1" || UpdatingTeamMember === "1"}
                    error={error.username}
                    stclass={error.username ? "borderError" : ""}
                  />
                  <GroupeForm
                    id={"add-member-email"}
                    name={"add-member-email"}
                    placeholder={Translate("auths", "email")}
                    text={Translate("auths", "email")}
                    type={"email"}
                    value={storeMemberInfo.email}
                    workfun={(e: React.ChangeEvent) => {
                      const { value } = e.target as HTMLInputElement;
                      setStoreMemberInfo(prev => ({ ...prev, email: value }));
                      if(value && value !== ""){
                        setError(prev => { 
                          delete prev.email; 
                          return prev; 
                        });
                      }
                    }}
                    disabled={GettingTeamMember === "1" || UpdatingTeamMember === "1"}
                    error={error.email}
                    stclass={error.email ? "borderError" : ""}
                  />
                  <GroupeForm
                    id={"add-member-password"}
                    name={"add-member-password"}
                    placeholder={Translate("auths", "password")}
                    text={Translate("auths", "password")}
                    type={passwordShown ? "text" : "password"}
                    value={storeMemberInfo.password}
                    workfun={(e: React.ChangeEvent) => {
                      const { value } = e.target as HTMLInputElement;
                      setStoreMemberInfo(prev => ({ ...prev, password: value }));
                      if(value && value !== ""){
                        setError(prev => { 
                          delete prev.password; 
                          return prev; 
                        });
                      }
                    }}
                    disabled={GettingTeamMember === "1" || UpdatingTeamMember === "1"}
                    error={error.password}
                    stclass={error.password ? "borderError" : ""}
                    onClick={togglePassword}
                    icon={passwordShown ? eye_show_password_Icon : eye_hide_password_Icon}
                  />
                </div>
              </Card>
              <Card>
                <Card.Header>{Translate("TeamsPage", "permissions")}</Card.Header>
                <div className="InFlx Stclmnf" style={{ gap: "20px", paddingTop: "20px" }}>
                  <Select
                    label={Translate("TeamsPage", "userisassigned")}
                    placholder={Translate("TeamsPage", "permissions")}
                    search={false}
                    Options={permissions.filter((permission, _) => !storeMemberInfo.permissions?.includes(permission.id))}
                    fieldShow={"name"}
                    name="permissions"
                    loading={GettingTeamMember === "1" || UpdatingTeamMember === "1"}
                    value={null}
                    onChange={(option: PermissionType, _: string) => {
                      Event("EDIT_TEAM_MEMBER", `ADD_${option.name}_PERMISSION`, "CLICK_EVENT");
                      setStoreMemberInfo((prev) => ({
                        ...prev,
                        permissions: [ ...(prev.permissions ?? []), option.id ]
                      }))
                    }}
                    disabled={GettingTeamMember === "1" || UpdatingTeamMember === "1"}
                  />
                  <div className="FlWd InFlx Stclmnf align-items-start" style={{ gap: "8px" }}>
                    {
                      storeMemberInfo.permissions?.map((permissionId, index) => (
                        <div 
                          key={index}
                          className="FlWd InFlx AlgnItm" 
                          style={{ 
                            padding: "8px", 
                            justifyContent: "space-between",
                            borderRadius: "500px",
                            border: `1px solid ${permissions.find((permission, _) => permission.id === permissionId)?.color}` 
                          }}
                        >
                          <div className="InFlx AlgnItm" style={{ gap: "10px" }}>
                            <div 
                              className="InFlx AlgnItm" 
                              style={{ 
                                backgroundColor: `${permissions.find((permission, _) => permission.id === permissionId)?.color}`,
                                gap: "2px",
                                padding: "1px 3px",
                                borderRadius: "600px" 
                              }}
                            >
                              {permissions.find((permission, _) => permission.id === permissionId)?.icons.map((icon, _) => (icon))}
                            </div>
                            <p className="DlMg">{permissions.find((permission, _) => permission.id === permissionId)?.name}</p>
                          </div>
                          <div 
                            onClick={
                              () => {
                                Event("EDIT_TEAM_MEMBER", `REMOVE_${permissionId}_PERMISSION`, "CLICK_EVENT");
                                setStoreMemberInfo((prev) => ({ 
                                  ...prev, 
                                  permissions: prev.permissions?.filter((item, _) => item !== permissionId) 
                                }));
                              }
                            }
                            className="InFlx AlgnItm JstfCnt"
                          >
                            {CloseSvg2}
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </Card>
            </div>
            <div
              className="Inlflx AlgnItm JstfCnt CrsPoi"
              onClick={handleSubmit}
            >
              <BtnLg
                disabled={GettingTeamMember === "1" || UpdatingTeamMember === "1"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 7px"
                }} 
                text={Translate("orders", "save")}
              />
            </div>
          </div>
        :
          <FeatureLoked/>
      }
    </ContainerPage>
  );
}

function mapState(state: any) {
  const { dataProfile } = state.user;
  const { 
    GettingTeamMember,
    teamMemberData,
    UpdatingTeamMember,
    UploadingTeamMemberPic,
    updateTeamMemberData,
    teamMemberPicture
  } = state.teams;

  return { 
    dataProfile,
    GettingTeamMember,
    teamMemberData,
    UpdatingTeamMember,
    UploadingTeamMemberPic,
    updateTeamMemberData,
    teamMemberPicture
  };
};

const actionCreators = {
  GetTeamMemberData: teamsActions.GetTeamMemberData,
  SendAlert: alertActions.SendAlert,
  UpdateTeamMember: teamsActions.UpdateTeamMember,
  ClearUpdateStore: teamsActions.ClearUpdateStore
}

const connectedTeamsPage = connect(mapState, actionCreators)(TeamMembrePage);
export { connectedTeamsPage as TeamMembrePage };
