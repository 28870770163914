import { paymentConstants, productConstants } from "../constants";
import { listenerConstants } from "../constants/Listener.constants";
export function listener(state, action) {
  switch (action.type) {
    case listenerConstants.EXPORT_ORDER_REQUEST:
    case listenerConstants.EXPORT_FACTURE_REQUEST:
    case productConstants.EXPORT_RECEIVE_PRODUCT_REQUEST:
    case paymentConstants.NEW_EXPORT_FACTURES_REQUEST:
    case productConstants.EXPORT_EXIT_VOUCHER_REQUEST:
      return {
        Exporting: "0",
        ExportData: action.payload,
      };
    case listenerConstants.EXPORT_ORDER_SUCCESS:
    case listenerConstants.EXPORT_FACTURE_SUCCESS:
    case productConstants.EXPORT_RECEIVE_PRODUCT_SUCCESS:
    case paymentConstants.NEW_EXPORT_FACTURES_SUCCESS:
    case productConstants.EXPORT_EXIT_VOUCHER_SUCCESS:
      return {
        Exporting: "1",
        ExportData: action.payload,
        ExportWorkFunc: action.payload.ExportWorkFunc,
      };
    case listenerConstants.EXPORT_ORDER_FAILURE:
    case listenerConstants.EXPORT_FACTURE_FAILURE:
    case productConstants.EXPORT_RECEIVE_PRODUCT_FAILURE:
    case paymentConstants.NEW_EXPORT_FACTURES_FAILURE:
    case productConstants.EXPORT_EXIT_VOUCHER_FAILURE:
      return {
        Exporting: "2",
        ExportData: action.payload,
      };
    case "CLEAR_LISTENER":
      return {
        Exporting: "",
        ExportData: {},
      };
    default:
      return { ...state };
  }
}
