import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import TabShow from "../_Common/TabShow";
import { Translate } from "../../../utils/lang/translate";
import BtnLg from "../_Common/BtnLg";
import "./WelcomeModal.css";
import {
  emailSvg,
  facebookSvg,
  tiktokSvg,
  instagramSvg,
  linkedInSvg,
  ytSvg,
  phoneSvg,
} from "../_Common/IconSvg";

const items = [
  { label: Translate("WelcomeMessage", "StopDesk") },
  { label: Translate("WelcomeMessage", "Confirmation") },
  { label: Translate("WelcomeMessage", "homeDelivery") },
  { label: Translate("WelcomeMessage", "Packing") },
  { label: Translate("WelcomeMessage", "Storage") },
  { label: Translate("WelcomeMessage", "Marketing") },
];

function Griditem({ label }) {
  return (
    <li className="services__Item">
      <span>{label}</span>
    </li>
  );
}

function WelcomeModal(props) {

  const modalRef = useRef();

  const handleClickOutside = (e) => {
    if(modalRef.current && !modalRef.current?.contains(e.target)){
      props.onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, [])

  return (
    <TabShow
      NotCls
      noPadding={true}
      style={{
        minWidth: "50vw",
        borderRadius: "10px",
      }}
    >
      <div ref={modalRef} className="FlWd modal__content">
        <div className="FlWd modal__header">
          <img className="FlWd" src={"/assets/img/new_user.png"} alt="..." />
          <img
            style={{
              marginTop: "-75px",
              zIndex: 10,
            }}
            src={"/assets/img/maystrologowelcome.png"}
            alt="..."
          />
        </div>
        <p className="welcome__message">
          {Translate("WelcomeMessage", "primaryMessage").concat(" !")}
        </p>
        <p className="secondary__welcome__message">
          {Translate("WelcomeMessage", "secondarymessage")}
        </p>
        <ul className="services__grid">
          {items.map((item, index) => (
            <Griditem
              key={index}
              label={item.label}
            />
          ))}
        </ul>
        <div className="divider" />
        <div className="contact__us__container">
          <div className="contact__us__col">
            {Translate("WelcomeMessage", "contact_us")}
          </div>
          <div className="contact__us__col">
            <div className="contact__item">
              <span> {emailSvg} </span>
              <span>{props.info?.Email}</span>
            </div>
            <div className="contact__item">
              <span> {emailSvg} </span>
              <span>contact@maystro-delivery.com</span>
            </div>
            <div className="contact__item">
              <span> {phoneSvg} </span>
              <span>
                {" "}
                {Translate("WelcomeMessage", "customerSupport")}&#x200E; {props?.info?.["Phone number"]}
                {" "}
              </span>
            </div>
            <div className="contact__item">
              <span> {phoneSvg} </span>
              <span>
                {" "}
                {Translate("WelcomeMessage", "commercialTeam")}&#x200E; (+213) 770 27 54 57
                {" "}
              </span>
            </div>
            <div className="contact__item">
              <span> {phoneSvg} </span>
              <span>
                {" "}
                {Translate("WelcomeMessage", "commercialTeam")}&#x200E; (+213) 770 58 56 10
                {" "}
              </span>
            </div>
            <div className="contact__item">
              <span> {phoneSvg} </span>
              <span>
                {" "}
                {Translate("WelcomeMessage", "commercialTeam")}&#x200E; (+213) 770 60 86 50
                {" "}
              </span>
            </div>
            <div className="contact__item">
              <span> {phoneSvg} </span>
              <span>
                {" "}
                {Translate("WelcomeMessage", "commercialTeam")}&#x200E; (+213) 770 60 87 98
                {" "}
              </span>
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="contact__us__container">
          <div className="contact__us__col">
            {Translate("WelcomeMessage", "stayConnected")}
          </div>
          <div className="contact__us__col">
            <div className="social__media">
              <a href={props.info?.Facebook_link}>{facebookSvg}</a>
              <a href={props.info?.Instagram}>{instagramSvg}</a>
              <a href={props.info?.Tiktok}>{tiktokSvg}</a>
              <a href={props.info?.Youtube}>{ytSvg}</a>
              <a href={props.info?.LinkedIn}>{linkedInSvg}</a>
            </div>
          </div>
        </div>
        <div className="divider" />
      </div>

      <div
        className="SmMarS FlWd Inlflx AlgnItm JstfCnt CrsPoi"
        onClick={props.onClose}
      >
        <BtnLg
          style={{
            minHeight: "40px",
            minWidth: "10vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "20px",
            fontWeight: "700",
          }}
          text={Translate("WelcomeMessage", "getStarted")}
        />
      </div>
    </TabShow>
  );
}

WelcomeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  info: PropTypes.any,
};

export default WelcomeModal;
