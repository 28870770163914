import React from "react";
const DisabledAccountSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="158"
      height="160"
      fill="none"
      viewBox="0 0 158 160"
    >
      <g clipPath="url(#clip0_4114_12275)">
        <path
          fill="#008AFF"
          d="M0 80c0 43.906 35.37 79.5 79 79.5L85.87 80 79 .5C35.37.5 0 36.093 0 80z"
        ></path>
        <path
          fill="#0071D1"
          d="M79 .5v159c43.63 0 79-35.594 79-79.5S122.63.5 79 .5z"
        ></path>
        <path
          fill="#fff"
          d="M20.609 80c0 12.48 3.87 24.051 10.463 33.567L79 65.335l6.87-22.048L79 21.24C46.752 21.24 20.61 47.547 20.61 80z"
        ></path>
        <path
          fill="#E1E1E1"
          d="M79 21.24v44.095l33.356-33.566A57.869 57.869 0 0079 21.239z"
        ></path>
        <path
          fill="#fff"
          d="M45.645 128.231A57.868 57.868 0 0079 138.761l6.87-22.048L79 94.665 45.645 128.23z"
        ></path>
        <path
          fill="#E1E1E1"
          d="M126.928 46.433L79 94.665v44.096c32.248 0 58.391-26.308 58.391-58.76 0-12.482-3.87-24.052-10.463-33.568z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_4114_12275">
          <path
            fill="#fff"
            d="M0 0H158V159H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}
 
export default DisabledAccountSvg;