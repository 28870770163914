export const paymentConstants = {
    VIEW_PAYMENT_SUCCESS: 'VIEW_PAYMENT_SUCCESS',
    VIEW_PAYMENT_FAILURE: 'VIEW_PAYMENT_FAILURE',
    VIEW_PAYMENT_REQUEST: 'VIEW_PAYMENT_REQUEST',

    GET_FACTURE_SUCCESS: 'GET_FACTURE_SUCCESS',
    GET_FACTURE_FAILURE: 'GET_FACTURE_FAILURE',
    GET_FACTURE_REQUEST: 'GET_FACTURE_REQUEST',

    EXPORT_FACTURES_SUCCESS: 'EXPORT_FACTURES_SUCCESS',
    EXPORT_FACTURES_FAILURE: 'EXPORT_FACTURES_FAILURE',
    EXPORT_FACTURES_REQUEST: 'EXPORT_FACTURES_REQUEST',

    ADD_CCP_REQUEST:'ADD_CCP_ACCOUNT_REQUEST',
    ADD_CCP_SUCCESS:'ADD_CCP_ACCOUNT_SUCCESS',
    ADD_CCP_FAILURE:'ADD_CCP_ACCOUNT_FAILURE',

    GET_CCP_REQUEST:'GET_CCP_ACCOUNT_REQUEST',
    GET_CCP_SUCCESS:'GET_CCP_ACCOUNT_SUCCESS',
    GET_CCP_FAILURE:'GET_CCP_ACCOUNT_FAILURE',

    UPDATE_CCP_REQUEST:'UPDATE_CCP_ACCOUNT_REQUEST',
    UPDATE_CCP_SUCCESS:'UPDATE_CCP_ACCOUNT_SUCCESS',
    UPDATE_CCP_FAILURE:'UPDATE_CCP_ACCOUNT_FAILURE',

    NEW_EXPORT_FACTURES_REQUEST: "NEW_EXPORT_FACTURES_REQUEST",
    NEW_EXPORT_FACTURES_SUCCESS: "NEW_EXPORT_FACTURES_SUCCESS",
    NEW_EXPORT_FACTURES_FAILURE: "NEW_EXPORT_FACTURES_FAILURE"
};