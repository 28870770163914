import React, { useEffect, useRef, useState } from "react";
import ContainerPage from "../../_Common/ContainerPage";
import { Translate } from "../../../../utils/lang/translate";
import Card from "../../_Common/Card/Card";
import GroupeForm from "../../../_Common/GroupeForm";
import { Event } from "../../_Common";
import { InfoSvg2 } from "../../_Common/IconSvg";
import CustomLink from "../../_Common/CustomLink";
import { alertActions, userActions } from "../../../../actions";
import { connect } from "react-redux";
import "./PersonalInfo.css";

interface PersonalInfoProps {
  dataProfile: any;
  dtStoreifno: any;
  UpdatePictureUser: (formData: FormData) => void;
  UpdatingPic: "0" | "1" | "2";
  SendAlert: (code: string, text: string, action: string) => void;
  UpdateShowAboutUsModal: (value: boolean) => void;
}

const PersonalInfo = ({
  dataProfile,
  SendAlert,
  UpdatePictureUser,
  UpdatingPic,
  UpdateShowAboutUsModal
}: PersonalInfoProps) => {

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [profileImage, setProfileImage] = useState<string | null>(dataProfile.picture);

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    fileInputRef?.current?.click();
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    Event("PERSONAL_INFO_PAGE", "UPDATE_USER_PROFILE_PICTURE", "CLICK_EVENT")
    const file = (event.target.files ?? [])[0];
    if (file && file.type.substr(0, 5) === "image") {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result as string);
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("profile_picture", file);
      UpdatePictureUser(formData);
    } else {
      setProfileImage("");
    }
  };

  useEffect(() => {

    if(UpdatingPic === "1"){
      SendAlert("41", "Profile picture uploaded successfuly", "");
    }

    if(UpdatingPic === "2"){
      SendAlert("50", "Failed to upload profile picture", "");
    }

  }, [UpdatingPic]);

  return (
    <ContainerPage
      page_title={Translate("settings", "personalinfo")}
    >
      <Card>
        <div 
          className="InFlx Stclmnf" 
          style={{ 
            gap: "16px", 
            width: "calc(100% - 80px)", 
            margin: "0 auto",
            maxWidth: "1300px" 
          }}
        >
          <h1 
            style={{ 
              fontSize: "24px", 
              fontWeight: 700 
            }}
          >
            {Translate("settings", "userinfo")}
          </h1>
          <div className="InFlx AlgnItm">
            <div className="InFlx AlgnItm" style={{ gap: "30px", padding: "20px 0" }}>
              <div className="StWdpic StBr60 HdOvrfl">
                <img
                  className="StWdpic"
                  src={profileImage ?? "/assets/img/default_user.png"}
                  alt={"..."}
                />
              </div>
              <div className="InFlx Stclmnf align-items-start" style={{ gap: "5px" }}>
                <h4 className="DlMg add-team-member-modal-upload-text">{Translate("settings", "profileimage")}</h4>
                <input 
                  type="file" 
                  ref={fileInputRef} 
                  onChange={handleImageChange} 
                  style={{ display: "none" }}
                />
                <a 
                  href="" 
                  className="add-team-member-modal-upload-image" 
                  onClick={handleLinkClick}
                >
                  {Translate("TeamsPage", "uploadimage")}
                </a>
              </div>
            </div>
          </div>
          <div className="InFlx spcBtwn" style={{ flexWrap: "wrap" }}>
            <GroupeForm
              id={"personalinfo-full-name"}
              name={"personalinfo-full-name"}
              placeholder={dataProfile.full_name}
              text={Translate("auths", "fullname")}
              type={"text"}
              style={{ flexBasis: "450px" }}
              disabled={true}
            />
            <GroupeForm
              id={"personalinfo-phone"}
              name={"personalinfo-phone"}
              placeholder={dataProfile.phone}
              text={Translate("auths", "phone")}
              type={"text"}
              style={{ flexBasis: "450px" }}
              disabled={true}
            />
          </div>
        </div>
        <div className="divider" style={{ margin: "20px 0" }}/>
        <div 
          className="InFlx Stclmnf" 
          style={{ 
            gap: "16px", 
            width: "calc(100% - 80px)", 
            margin: "0 auto",
            maxWidth: "1300px" 
          }}
        >
          <h1 
            style={{ 
              fontSize: "24px", 
              fontWeight: 700 
            }}
          >
            {Translate("settings", "logininfo")}
          </h1>
          <div className="InFlx spcBtwn AlgnItm" style={{ flexWrap: "wrap", gap: "20px" }}>
            <GroupeForm
              id={"personalinfo-username"}
              name={"personalinfo-username"}
              placeholder={dataProfile.username}
              text={Translate("auths", "username")}
              type={"text"}
              style={{ flexBasis: "450px" }}
              disabled={true}
            />
            <GroupeForm
              id={"personalinfo-password"}
              name={"personalinfo-password"}
              value={"password"}
              text={Translate("auths", "password")}
              type={"password"}
              style={{ flexBasis: "450px" }}
              disabled={true}
            />
            <GroupeForm
              id={"personalinfo-email"}
              name={"personalinfo-email"}
              placeholder={dataProfile.email}
              text={Translate("auths", "email")}
              type={"text"}
              style={{ flexBasis: "450px" }}
              disabled={true}
            />
            <GroupeForm
              id={"personalinfo-auth-token"}
              name={"personalinfo-auth-token"}
              text={Translate("auths", "authenticationtoken")}
              type={"text"}
              workfun={() => {}}
              value={dataProfile.token}
              disabled={true}
              copyButton={() => Event("TEAMS", "COPY_STORE_AUTH_TOKEN", "CLICK_EVENT")}
              style={{ flexBasis: "450px" }}
            />
          </div>
        </div>
        <div className="divider" style={{ margin: "20px 0" }}/>
        <div 
          className="InFlx" 
          style={{ 
            justifyContent: "flex-start", 
            width: "calc(100% - 80px)", 
            margin: "0 auto", 
            padding: "25px 0", 
            gap: "5px",
            fontSize: "18px",
            fontWeight: 300,
            maxWidth: "1300px" 
          }}
        >
          <span>{InfoSvg2}</span>
          <span>{Translate("PersonalInfoPage", "ifyouwantedit")}</span>
          <CustomLink 
            onClick={() => UpdateShowAboutUsModal(true)} 
            text={Translate("footer", "contactus")} 
            fontSize={"18px"}
          />
          <span>{Translate("PersonalInfoPage", "toedit")}</span>
        </div>
      </Card>
    </ContainerPage>
  );
};

function mapState(state: any) {
  
  const { dataProfile, UpdatingPic } = state.user;
  
  return { 
    dataProfile,
    UpdatingPic 
  };
}

const actionCreators = {
  SendAlert: alertActions.SendAlert,
  UpdatePictureUser: userActions.UpdatePictureUser,
  UpdateShowAboutUsModal: userActions.UpdateShowAboutUsModal,
};

const connectedPersonalInfo = connect(mapState, actionCreators)(PersonalInfo);
 
export { connectedPersonalInfo as PersonalInfo };