import React, { useEffect, useRef } from "react";
import TabShow from "../_Common/TabShow";
import { CoinSvg, ColorfullGiftSvg, ColorfullPackageSvg, SmallCoinSvG, closeIcon } from "../_Common/IconSvg";
import BtnLg from "../_Common/BtnLg";
import CustomTable, { TableColumnProps } from "../_Common/CustomTable/CustomTable";
import { Translate } from "../../../utils/lang/translate";
import { TiersDataItem } from "../../../types";
import { RewardsService } from "./types";

interface RewardsHowitworksModalPorps {
  onClose: () => void,
  tiersData: TiersDataItem [],
  services: RewardsService[]
}

const RewardsHowitworksModal = ({ onClose, tiersData, services }: RewardsHowitworksModalPorps) => {
  
  const tableCols: TableColumnProps<TiersDataItem, keyof TiersDataItem>[] = [
    {
      key: "name",
      title: "",
      renderItem: (item) => (
        <div style={{ width: "100%", textAlign: "start" }}>
          {item?.name}
        </div>
      )
    },
    {
      key : "min_deliveries",
      title: Translate("RewardsPage", "qualification_per_delivered"),
      renderItem: (item) => (
        <span>
          {
            item && item?.name === "Ambassador"
            ? 
              `+${item?.min_deliveries}`
            :
              `${item?.min_deliveries}-${item?.max_deliveries}`
          }
        </span>
      )
    },
    {
      key: "ratio",
      title: Translate("RewardsPage", "points_per_order"),
      renderItem: (item) => (
        <div className="FlWd InFlx JstfCnt" style={{ gap: "5px" }}> 
          <div>{item?.ratio}</div> 
          <div style={{ marginTop: "1px" }}>{SmallCoinSvG}</div> 
        </div>
      )
    }
  ]; 

  const steps = [
    { title: Translate("RewardsPage", "steps_deliver_title"), icon: ColorfullPackageSvg, text: Translate("RewardsPage", "steps_deliver_text")},
    { title: Translate("RewardsPage", "steps_earn_title"), icon: CoinSvg, text: Translate("RewardsPage", "steps_earn_text")},
    { title: Translate("RewardsPage", "steps_redeem_title"), icon: ColorfullGiftSvg, text: Translate("RewardsPage", "steps_redeem_text")},
  ];



  const modalRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (e: MouseEvent) => {
    if(modalRef.current && !modalRef.current?.contains(e.target as Node)){
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, []);
  
  return (
    <TabShow
      darkBg
      noReturn
      noPadding={true}
      style={{
        minWidth: "50vw",
        borderRadius: "8px",
        }}
    >
      <div 
        ref={modalRef} 
        className="InFlx Stclmnf reward-modals" 
        style={{ padding: "32px", gap: "5px"}}
      >
        <div className="InFlx StFlxStr">
          <div
            onClick={onClose} 
            className="InFlx AlgnItm sb4R CrsPoi Gray" 
            style={{ padding: "8px" }}
          >
            {closeIcon}
          </div>
        </div>
        <div className="InFlx Stclmnf" style={{ gap: "32px" }}>
          {/* steps */}
          <div className="InFlx FlWd Stclmnf AlgnItm" style={{ gap: "16px" }}>
            <h3 className="FlWd DlMg rewards-overview-title">{Translate("RewardsPage", "about_program_title")}</h3>
            <div className="rewards-howitworks-modal-steps">
              {
                steps.map((step, index) => (
                  <>
                    <div key={index} className="InFlx Stclmnf AlgnItm" style={{ gap: "8px" }}>
                      <div style={{ height: "36px", width: "36px" }}>{step.icon}</div>
                      <div className="InFlx Stclmnf AlgnItm">
                        <h2 className="DlMg rewards-howitworks-modal-step-header">{step.title}</h2>
                        <p className="DlMg sTclPg rewards-spend-points-text">{step.text}</p>
                      </div>
                    </div>
                    { index !== steps.length -1 && <div className="inline-divider"/> }
                  </>
                ))
              }
            </div>
          </div>
          {/* how to earn */}
          <div className="InFlx Stclmnf align-items-start" style={{ gap: "20px" }}>
            <h3 className="DlMg rewards-overview-title">{Translate("RewardsPage", "howtoearn_title")}</h3>
            <p className="DlMg overview-expires-date" style={{ textAlign: "start" }}>{Translate("RewardsPage", "howtoearn_text")}</p>
            <CustomTable columns={tableCols} data={tiersData} loading={false} />
          </div>

          {/* Redeem points */}
          <div className="InFlx FlWd Stclmnf AlgnItm" style={{ gap: "16px" }}>
            <div className="FlWd InFlx Stclmnf align-items-start">
              <h3 className="DlMg rewards-overview-title">{Translate("RewardsPage", "redeempoints_title")}</h3>
              <p className="DlMg overview-expires-date">{Translate("RewardsPage", "redeempoints_text")}</p>
            </div>
            <div className="InFlx AlgnItm" style={{ gap: "22px" }}>
              {
                services.map((service, index) => (
                  <div key={index} className="InFlx Stclmnf" style={{ gap: "12px", padding: "0 10px" }}>
                    <div>{service.secondaryIcon}</div>
                    <h4 className="DlMg doughnut-chart-remaining-deliveries">{Translate("RewardsPage", `service${service.code}_name`)}</h4>
                  </div>
                ))
              }
            </div>
          </div>

          {/* points expiry */}

          <div className="FlWd InFlx Stclmnf align-items-start" style={{ gap: "16px" }}>
            <h3 className="DlMg rewards-overview-title"> {Translate("RewardsPage", "points_expiry_title")} </h3>
            <p className="DlMg overview-expires-date" style={{ textAlign: "start" }}>{Translate("RewardsPage", "points_expiry_text")}</p>
          </div>

          <div className="FlWd InFlx Stclmnf align-items-start" style={{ gap: "16px" }}>
            <h3 className="DlMg rewards-overview-title"> {Translate("RewardsPage", "monthly_reset_title")} </h3>
            <p className="DlMg overview-expires-date" style={{ textAlign: "start" }}>{Translate("RewardsPage", "monthly_reset_text")}</p>
          </div>

        </div>
        <div className="FlWd InFlx JstfCnt" style={{ padding: "20px 0" }}>
          <div onClick={onClose}>
            <BtnLg text={Translate("RewardsPage", "understood")} style={{ padding: "7px 21px" }} />
          </div>
        </div>
      </div>
    </TabShow>
  );
}
 
export default RewardsHowitworksModal;