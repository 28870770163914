import { exchange_status_list } from "../constants";
import {
  DelivredSvg,
  CreatedSvg,
  InHubSvg,
  WaitingSvg,
  CancledSvg,
  ToReturnSvg,
  TransitSvg
} from "../components/StorePages/_Common/IconSvg";

export const date_difference = (date1, date2) => {
  let dateFuture = new Date(date1);
  let pastDate = new Date(date2);

  let difference=dateFuture - pastDate;
  let seconds = Math.floor((dateFuture - pastDate) / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds=seconds-(minutes*60+hours*3600+days*24*3600)
  return { days, hours, minutes, seconds,difference };
};

export const getStatusSvg = (exchangeStatus) => {
  switch(exchangeStatus){
    case exchange_status_list.EXCHANGE_STATUS_ABORTED: 
      return {
        icon: CancledSvg,
        Styleicon: "ElmSvgCn",
      };
    case exchange_status_list.EXCHANGE_STATUS_COLLECTED_BY_AGENT: 
      return {
        icon: DelivredSvg,
        Styleicon: "ElmSvgWt",
      };
    case exchange_status_list.EXCHANGE_STATUS_COLLECTED_BY_STORE: 
      return {
        icon: DelivredSvg,
        Styleicon: "ElmSvgDl",
      };
    case exchange_status_list.EXCHANGE_STATUS_CREATED: 
      return {
        icon: CreatedSvg,
        Styleicon: "StBlFil",
      };
    case exchange_status_list.EXCHANGE_STATUS_IN_HUB: 
      return {
        icon: InHubSvg,
        Styleicon: "StBlFil",
      };
    case exchange_status_list.EXCHANGE_STATUS_IN_PROGRESS: 
      return {
        icon: WaitingSvg,
        Styleicon: "StBlFil",
      };
    case exchange_status_list.EXCHANGE_STATUS_IN_TRANSIT: 
      return {
        icon: TransitSvg,
        Styleicon: "ElmSvgWt",
      };
    case exchange_status_list.EXCHANGE_STATUS_READY_TO_BE_RETURNED: 
      return {
        icon: ToReturnSvg,
        Styleicon: "ElmSvgWt",
      };
    case exchange_status_list.EXCHANGE_STATUS_WAITING_TRANSIT: 
      return {
        icon: WaitingSvg,
        Styleicon: "ElmSvgWt",
      };
    case exchange_status_list.EXCHANGE_STATUS_NOT_RECEIVED_FROM_TRANSIT: 
      return {
        icon: WaitingSvg,
        Styleicon: "ElmSvgWt",
      };

    default:
      return null;
  }
}
