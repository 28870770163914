import { teamConstants } from '../constants/team.constants';
import { RequestService, FunctionService } from '../services';
import Cookies from 'js-cookie'
const token=Cookies.get('auth');


export const teamsActions = {
  GetTeamMembers,
  GetTeamData,
  AddTeamMember,
  uploadTeamMemberProfilePic,
  ToggleTeamMember,
  ClearAddDeleteStore,
  GetTeamMemberData,
  UpdateTeamMember,
  ClearUpdateStore
};


function GetTeamMembers() {
  return (dispatch: any) => {
    dispatch(FunctionService.request(teamConstants.GET_TEAM_MEMBERS_REQUEST));
    RequestService.GetRequest("delivery/team/store_members", {
      Authorization: `Token ${token}`
    })
    .then((res) => {
      dispatch(FunctionService.succes(res.data, teamConstants.GET_TEAM_MEMBERS_SUCCESS));
    })
    .catch((err) => {
      dispatch(FunctionService.failure([], teamConstants.GET_TEAM_MEMBERS_FAILURE));
    });
  }
};

function GetTeamData(filter: string) {
  return (dispatch: any) => {
    dispatch(FunctionService.request(teamConstants.GET_TEAM_DATA_REQUEST));
    RequestService.GetRequest("delivery/team/" + filter, {
      Authorization: `Token ${token}`
    })
    .then(res => {
      dispatch(FunctionService.succes(res.data, teamConstants.GET_TEAM_DATA_SUCCESS));
    })
    .catch(_ => {
      dispatch(FunctionService.failure({}, teamConstants.GET_TEAM_DATA_FAILURE));
    }); 
  } 
};

function AddTeamMember(data: any, profile_picture_data: FormData | null) {
  return (dispatch: any) => {
    dispatch(FunctionService.request(teamConstants.ADD_TEAM_MEMBER_REQUEST));
    RequestService.PostRequest("delivery/team/", data, {
      Authorization: `Token ${token}`
    })
    .then(res => {
      dispatch(FunctionService.succes(
        { 
          username: data.username, 
          password: data.password,
          full_name: data.full_name 
        }, 
        teamConstants.ADD_TEAM_MEMBER_SUCCESS
      ));

      if(profile_picture_data?.get("profile_picture")){
        uploadTeamMemberProfilePic(res.data.id, profile_picture_data)(dispatch);
      }
    })
    .catch(err => {
      dispatch(FunctionService.failure(err, teamConstants.ADD_TEAM_MEMBER_FAILURE));
    });
  }
};

function uploadTeamMemberProfilePic(userId: string, formData: FormData) {
  return (dispatch: any) => {
    dispatch(FunctionService.request(teamConstants.UPLOAD_TEAM_MEMBER_PICTURE_REQUEST));
    RequestService.PostRequest(`delivery/team/${userId}/upload_profile_picture/`, formData, {
      Authorization: `Token ${token}`
    })
    .then(res => {
      dispatch(FunctionService.succes(res.data.picture, teamConstants.UPLOAD_TEAM_MEMBER_PICTURE_SUCCESS));
    })
    .catch(_ => {
      dispatch(FunctionService.failure(null, teamConstants.UPLOAD_TEAM_MEMBER_PICTURE_FAILURE));
    });
  }
};

function ToggleTeamMember(member_id: string, value: boolean) {
  return (dispatch: any) => {
    dispatch(FunctionService.request(teamConstants.TOGGLE_TEAM_MEMBER_REQUEST));
    RequestService.PatchRequest(`delivery/team/${member_id}/`, 
    { is_enabled: value }, 
    {
      Authorization: `Token ${token}`
    })
    .then(res => {
      dispatch(FunctionService.succes(res.data, teamConstants.TOGGLE_TEAM_MEMBER_SUCCESS));
    })
    .catch(_ => {
      dispatch(FunctionService.failure({}, teamConstants.TOGGLE_TEAM_MEMBER_FAILURE))
    });
  }
};

function ClearAddDeleteStore() {
  return (dispatch: any) => {
    dispatch(FunctionService.request(teamConstants.CLEAR_ADD_DELETE_STORE));
  };
};

function ClearUpdateStore() {
  return (dispatch: any) => {
    dispatch(FunctionService.request(teamConstants.CLEAR_UPDATE_STORE));
  }
}

function GetTeamMemberData(memberId: string) {
  return (dispatch: any) => {
    dispatch(FunctionService.request(teamConstants.GET_TEAM_MEMBER_DATA_REQUEST));
    RequestService.GetRequest(`delivery/team/${memberId}`, {
      Authorization: `Token ${token}`
    })
    .then(res => {
      dispatch(FunctionService.succes(res.data, teamConstants.GET_TEAM_MEMBER_DATA_SUCCESS));
    })
    .catch(_ => {
      dispatch(FunctionService.failure({}, teamConstants.GET_TEAM_MEMBER_DATA_FAILURE));
    })
  }
};

function UpdateTeamMember(memberId: string, data: any, profile_picture_data: FormData | null){
  return (dispatch: any) => {
    dispatch(FunctionService.request(teamConstants.UPDATE_TEAM_MEMBER_REQUEST));
    RequestService.PatchRequest(`delivery/team/${memberId}/`, data, {
      Authorization: `Token ${token}`
    })
    .then(res => {
      dispatch(FunctionService.succes(res.data, teamConstants.UPDATE_TEAM_MEMBER_SUCCESS));
      if(profile_picture_data?.get("profile_picture")){
        uploadTeamMemberProfilePic(memberId, profile_picture_data)(dispatch);
      }
    })
    .catch(_ => {
      dispatch(FunctionService.failure({}, teamConstants.UPDATE_TEAM_MEMBER_FAILURE));
    });
  }
}; 