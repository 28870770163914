import React from "react";

import { 
  FriendTierSvg, 
  BestFriendTierSvg, 
  SoulmateSvg, 
  FamilyTierSvg,
  AmbassadorSvg  
} from "../components/StorePages/_Common/IconSvg";

import Member from "../svgs/Member";
import Friend from "../svgs/Friend";
import BestFriend from "../svgs/BestFriend";
import Family from "../svgs/Family";

export const rewardsConstants = {
  
  GET_REWARDS_REQUEST: 'GET_REWARDS_REQUEST',
  GET_REWARDS_SUCCESS: 'GET_REWARDS_SUCCESS',
  GET_REWARDS_FAILURE: 'GET_REWARDS_FAILURE',

  GET_ALL_TIERS_REQUEST: 'GET_ALL_TIERS_REQUEST',
  GET_ALL_TIERS_SUCCESS: 'GET_ALL_TIERS_SUCCESS',
  GET_ALL_TIERS_FAILURE: 'GET_ALL_TIERS_FAILURE',

  UPDATE_AUTO_SPEND_POINTS_REQUEST: 'UPDATE_AUTO_SPEND_POINTS_REQUEST',
  UPDATE_AUTO_SPEND_POINTS_SUCCESS: 'UPDATE_AUTO_SPEND_POINTS_SUCCESS',
  UPDATE_AUTO_SPEND_POINTS_FAILURE: 'UPDATE_AUTO_SPEND_POINTS_FAILURE'
};

export const autoSpendPoints = {
  RETURN_FEES: 2,
  PREMIUM_FEES: 1,
  BOTH: 3,
  NONE: 0
};

export const renderTierStyle = {
  "Member": { 
    svg: FriendTierSvg, 
    border: "overview-border-friend-theme", 
    text: "overview-friend-tier", 
    bgColor: "rewards-tier-card-friend-bg",
    progressColor: "linear-gradient(90deg, #008AFF 0%, #019C6E 100%)",
    bgColorClass: "friend-tier-bg", 
    boxShadow: "benefits-box-shadow-friend",
    carretColor: "#50A6EF",
    benefitsSvg: <Member width={100} height={60}/>,
  },
  "Friend": { 
    svg: BestFriendTierSvg, 
    border: "overview-border-bestfriend-theme", 
    text: "overview-bestfriend-tier", 
    bgColor: "rewards-tier-card-bestfriend-bg",
    progressColor: "linear-gradient(90deg, #019C6E 0%, #8FAB24 100%)",
    bgColorClass: "bestfriend-tier-bg", 
    boxShadow: "benefits-box-shadow-bestfriend",
    carretColor: "#7BC251",
    benefitsSvg: <Friend width={100} height={60}/>,
  },
  "Best Friend": { 
    svg: SoulmateSvg, 
    border: "overview-border-soulmate-theme", 
    text: "overview-soulmate-tier", 
    bgColor: "rewards-tier-card-soulmate-bg",
    progressColor: "linear-gradient(90deg, #8FAB24 0%, #F5A200 100%)",
    bgColorClass: "soulmate-tier-bg", 
    boxShadow: "benefits-box-shadow-soulmate",
    carretColor: "#F1C94B",
    benefitsSvg: <BestFriend width={100} height={60}/>,
  },
  "Family": { 
    svg: FamilyTierSvg, 
    border: "overview-border-family-theme", 
    text: "overview-family-tier", 
    bgColor: "rewards-tier-card-family-bg",
    progressColor: "linear-gradient(90deg, #F5A201 0%, #EE7200 100%)",
    bgColorClass: "family-tier-bg", 
    boxShadow: "benefits-box-shadow-family",
    carretColor: "#FFA03B",
    benefitsSvg: <Family width={100} height={60}/>,
  },
  "Ambassador": {
    svg: AmbassadorSvg,
    border: "overview-border-ambassador-theme",
    text: "overview-ambassador-tier",
    bgColor: "rewards-tier-card-ambassador-bg",
    progressColor: "linear-gradient(90deg, #FFA03B 0%, #B48811 29.43%, #F7EBCF 62.78%, #B48811 98.1%)",
    bgColorClass: "family-tier-bg",
    boxShadow: "benefits-box-shadow-family",
    carretColor: "#FFA03B",
    benefitsSvg: null
  }
};
