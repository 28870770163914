import React, { useMemo } from "react";
import ReactTooltip from "react-tooltip";

export default function GroupeBtn(props) {
  
  return (
    <div className="StBrdRdSc">
      {Array.isArray(props.data_btn) &&
        props.data_btn.map((elem, key) => {
          return (
            <div
              key={key}
              className={"Inlflx stBdp CrsPoi " + elem.type + `${elem.notif ? " RlPs" : ""}`}
              data-tip
              data-for={`${elem.tooltip}-tooltip-${key}`}
              onClick={elem.action}
            >
              {elem.svgbtn}
              {elem.tooltip && (
                <ReactTooltip id={`${elem.tooltip}-tooltip-${key}`}>
                  {elem.tooltip}
                </ReactTooltip>
              )}
              {
                elem.notif 
                && 
                <div className="StAbs" style={{ right: "-3px", top: "-2px" }}>
                  <div
                    className="InFlx AlgnItm JstfCnt"
                    style={{
                      backgroundColor: "#E9230F",
                      borderRadius: "500px",
                      height: "10px",
                      width: "10px",
                    }}
                  />
                </div>
              }
            </div>
          );
        })}
    </div>
  );
}
