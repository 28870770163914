import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormatDate, Translate } from "../../../utils/lang/translate";
import ContainerPage from "../_Common/ContainerPage";
import { Exchange, RequestStatus } from "../../../types";
import { alertActions, exchangeActions } from "../../../actions";
import { FeatureLoked } from "../_Common/FeatureLoked";
import { Link, RouterChildContext, useHistory, useParams } from "react-router-dom";
import IconButton from "../_Common/IconButton";
import { CallSvg, ExpressSvg, ManagentSvg, PersonSvg, PriceSvg, PrintSvg, deliveredProductsSvg, returnedProductsSvg, smallArrowSvg } from "../_Common/IconSvg";
import Loader from "../../_Common/Loader";
import Table from "../_Common/Table";
import ListDetailIcon from "../_Common/ListDetailIcon";
import Cookies from "js-cookie";
import { getStatusSvg } from "../../../utils/functions";
import StatusBadge from "../_Common/StatusBadge";
import { exchange_status_list } from "../../../constants";

const lang = Cookies.get("lang"); 

interface ExchangeDetailsPageProps {
  ExchangeGetting: RequestStatus,
  GetExchange: (id: string) => void,
  dataExchange: Exchange,
  dataProfile: any,
  SendAlert: (code: string, text: string, action: string) => void,
  GenerateExchangesBordureauStarter: (exchanges_ids: string[], all_created: boolean) => void,
  dtStoreinfo: any,
  postingbordereau: RequestStatus
}

const ExchangeDetailsPage = ({
  GetExchange,
  dataExchange,
  ExchangeGetting,
  dataProfile,
  SendAlert,
  GenerateExchangesBordureauStarter,
  dtStoreinfo,
  postingbordereau
}: ExchangeDetailsPageProps) => {

  const history = useHistory<RouterChildContext['router']['history']>();

  const { exchange_refund_id } = useParams<{ exchange_refund_id: string }>();

  const data_top_btns = 
    dataExchange?.status === exchange_status_list.EXCHANGE_STATUS_CREATED
    && dataExchange?.type === 2 
    ? 
      [
        <IconButton 
          clickHandler={() => GenerateExchangesBordureauStarter([dataExchange.id], false)}
          icon={PrintSvg}
          label={Translate("exchanges", "printslip")} 
        />
      ] 
    : 
      [];

  useEffect(() => {
    GetExchange(exchange_refund_id);
  }, [exchange_refund_id]);

  useEffect(() => {
    if(ExchangeGetting === "3"){
      SendAlert("50", Translate("exchanges", "failedtofetchexchanges"), "");
    }
  }, [ExchangeGetting]);

  return (
    <>
    {postingbordereau === "1" && <Loader />}
    {
      ExchangeGetting === "1"
        ?
          <Loader />
        :
          <ContainerPage
            page_title={
              <div className="StDrc">
                <Link className="StOpcVal" to={dataExchange?.type === 1 ? "/refunds" : "/exchanges"}>
                  { 
                    dataExchange?.type === 1 ?
                    Translate("refunds", "refunds")
                    : Translate("exchanges", "exchanges")
                  }
                </Link>
                <div className="Inlflx StWdDst">
                  {Translate("orders", "flech")}
                </div>
                <div className="Inlflx">
                  {
                    dataExchange?.type === 1 ?
                    Translate("refunds", "refunddetails")
                    : Translate("exchanges", "exchangedetail")
                  }
                </div>
                <div className="Inlflx StWdDst">#</div>
                <div className="Inlflx">{dataExchange?.display_id_str ?? "N/A"}</div>
                <div className="Inlflx sTmrGlf">
                  <StatusBadge
                    text={
                      dataExchange?.type === 1 ?
                      Translate("refunds", dataExchange?.status)
                      : Translate("exchanges", dataExchange?.status)
                    }
                    bgClass={`exchange_badge_${dataExchange?.status}`}
                    colorClass={`exchange_text_${dataExchange?.status}`}
                  />
                </div>
              </div>
            }
            data_top={data_top_btns}
          >
            {
              (dataProfile.is_store || dataProfile.view_order)
              &&
              <>
                {
                  (ExchangeGetting && ExchangeGetting !== "3")
                  ?
                    <>
                      <div className="FlWd InFlx responsFlx flxDrc">
                        <div className="Hlwd">
                          <Table
                            ntdir={true}
                            thead_elem={
                              dataExchange?.type === 1
                              ? [Translate("refunds", "refunddetails")]
                              : [Translate("exchanges", "exchangedetail")]
                            }
                            tbody_elem={[
                              [
                                <ListDetailIcon
                                  icon={returnedProductsSvg}
                                  title={Translate("exchanges", "returnedproducts")}
                                  details={
                                    dataExchange.returned_products.map((product, _) => {
                                      return `(${product.quantity}) ${product.logistical_description}`
                                    })
                                  }
                                />,
                              ],
                              [
                                <div className="FlWd InFlx responsFlx flxDrc StFlxWrp">
                                  <div className="">
                                    <ListDetailIcon
                                      icon={deliveredProductsSvg}
                                      title={Translate("exchanges", "deliveredproducts")}
                                      details={
                                        dataExchange.delivered_products.map((product, _) => {
                                          return `(${product.quantity}) ${product.logistical_description}`
                                        })
                                      }
                                    />
                                  </div>
                                  <div className="Mrg2 InFlx AlgnItm JstfCnt">
                                    <div 
                                      onClick={() => history.push(`/order/detail/${dataExchange.order_to_ship}?source=order`)}
                                      className="services__item__inactive CrsPoi"
                                    >
                                      <span> {Translate("exchanges", "order")} # {dataExchange.order_to_ship} </span>
                                      <span className="InFlx AlgnItm StWdDst services__item__icon"> {smallArrowSvg} </span>
                                    </div>
                                  </div>
                                </div>,
                              ],
                              [
                                <ListDetailIcon
                                  icon={PriceSvg}
                                  title={Translate("orders", "totalprice")}
                                  details={[
                                    lang === "ar"
                                    ?
                                      dataExchange.total_price + " " + dtStoreinfo.country.ar_currency
                                    :
                                      dataExchange.total_price + " " + dtStoreinfo.country.lt_currency
                                  ]}
                                />
                              ],
                              [
                                <div className="FlWd InFlx responsFlx flxDrc">
                                  <div className="Hlwd">
                                    <ListDetailIcon
                                      icon={PersonSvg}
                                      title={Translate("orders", "customername")}
                                      details={[dataExchange.customer_name]}
                                    />
                                  </div>
                                  <div className="Hlwd Mrg2">
                                    <ListDetailIcon
                                      icon={CallSvg}
                                      title={Translate("orders", "customerphonenumber")}
                                      details={[dataExchange.customer_phone]}
                                    />
                                  </div>
                                </div>,
                              ],
                              [
                                <ListDetailIcon
                                  icon={ExpressSvg}
                                  title={Translate("orders", "expressdelivery")}
                                  details={[dataExchange.express ? "Yes" : "No"]}
                                />,
                              ],
                            ]}
                          />
                        </div>
                        <div className="Hlwd Mrg2">
                          <Table
                            ntdir={true}
                            thead_elem={[Translate("orders", "delivrystatus")]}
                            tbody_elem={
                              dataExchange.history.map((historyItem, _) => {
                                const icons = getStatusSvg(historyItem.status);
                                return [
                                  <ListDetailIcon
                                    icon={icons?.icon}
                                    Styleicon={icons?.Styleicon} 
                                    title={Translate("exchanges", historyItem.status)}
                                    details={[
                                      FormatDate(historyItem.updated_at),
                                      <span
                                        className={`StAbs exchange_${historyItem.status} LeftSet StTpval2 WdSmP HidLstELm_0`}
                                        style={{ height: "100%" }}
                                      ></span>,
                                    ]}
                                  />
                                ]
                              }).reverse()
                            }
                          />
                          <div className="StMrtp">
                            {
                              dataExchange.agent
                              &&
                              <Table
                                ntdir={true}
                                thead_elem={[Translate("orders", "deliveryagent")]}
                                tbody_elem={[
                                  [
                                    <div className="FlWd InFlx responsFlx flxDrc">
                                      <div className="Hlwd">
                                        <ListDetailIcon
                                          icon={ManagentSvg}
                                          title={Translate("auths", "fullname")}
                                          details={[
                                            dataExchange.agent.first_name
                                            + " "
                                            + dataExchange.agent.last_name
                                          ]}
                                        />
                                      </div>
                                      <div className="Hlwd Mrg2">
                                        <ListDetailIcon
                                          icon={CallSvg}
                                          title={Translate("auths", "phone")}
                                          details={[
                                            dataExchange.agent.phone
                                          ]}
                                        />
                                      </div>
                                    </div>,
                                  ],
                                ]}
                              />
                            }
                          </div>
                        </div>
                      </div>
                    </>   
                  :
                    <div>
                      error loading exchange details!!!
                    </div>
                }
              </>
            }

            {
              (!dataProfile.is_store || !dataProfile.view_order)
              &&
              <FeatureLoked />
            }

          </ContainerPage>
      }
    </>
  );
}
 
function mapState(state: any) {
  const { dataExchange, ExchangeGetting } = state.exchanges;
  const { dataProfile, dtStoreinfo } = state.user;
  const { postingbordereau } = state.orders;
  return { dataExchange, ExchangeGetting, dataProfile, dtStoreinfo, postingbordereau }
};

const actionCreators = {
  GetExchange: exchangeActions.GetExchange,
  SendAlert: alertActions.SendAlert,
  GenerateExchangesBordureauStarter: exchangeActions.GenerateExchangesBordureauStarter
}

const connctedExchangeDetailsPage = connect(mapState, actionCreators)(ExchangeDetailsPage);
export { connctedExchangeDetailsPage as ExchangeDetailsPage };