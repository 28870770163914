import React, { useRef, useEffect, useState } from "react";
import TabShow from "../_Common/TabShow";
import { Translate } from "../../../utils/lang/translate";
import { PrintSvg, closeIcon } from "../_Common/IconSvg";
import BtnLg from "../_Common/BtnLg";
import CheckBoxForm from "../../_Common/CheckBoxForm";

interface ExchangePrintModalProps {
  onClose: () => void,
  onPrint: (neverShowAgain: boolean) => void
};

const ExchangePrintModal = ({
  onClose,
  onPrint
}: ExchangePrintModalProps) => {

  const modalRef = useRef<HTMLDivElement>(null);
  const [neverShow, setNeverShow] = useState(false);

  const handleClickOutside = (e: MouseEvent) => {
    if(modalRef.current && !modalRef.current?.contains(e.target as Node)){
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, []);

  const handlePrintButtonClick = () => {
    onPrint(neverShow);
    onClose();
  }


  return (
    <TabShow
      darkBg
      noReturn
      noPadding={true}
      style={{
        minWidth: "50vw",
        borderRadius: "8px",
      }}
    >
      <div 
        ref={modalRef} 
        className="InFlx Stclmnf" 
        style={{ padding: "32px", gap: "10px"}}
      >
        <div className="InFlx StFlxStr">
          <div
            onClick={onClose} 
            className="InFlx AlgnItm sb4R CrsPoi Gray" 
            style={{ padding: "8px" }}
          >
            {closeIcon}
          </div>
        </div>
        <div 
          className="FlWd InFlx Stclmnf align-items-start" 
          style={{ gap: "10px" }}
        >
          <h2 
            className="FlWd DlMg TxtSt" 
            style={{ fontSize: "32px" }}
          >
            {Translate("exchanges", "exchangemodaltitle")}
          </h2>
          <p 
            className="FlWd DlMg TxtSt" 
            style={{ wordWrap: "break-word", fontSize: "17px", fontWeight: 400 }}
          >
            { Translate("exchanges", "putslipinsidepackage") } 
          </p>
        </div>
        <img className="marginTopSmall StBrdRd Flwd" src="/assets/img/package.png" alt="..." />
        <div className="marginTopSmall FlWd InFlx AlgnItm spctwB">
          <CheckBoxForm
            id="show-exchange-slip-modal-checkbox"
            name="show-exchange-slip-modal-checkbox"
            labelClass="stfSize"
            text={Translate("exchanges", "dontshowthisagain")}
            workfun={() => setNeverShow(prev => !prev)}
            check={neverShow}
          />
          <div
            className="Inlflx AlgnItm JstfCnt CrsPoi"
            onClick={handlePrintButtonClick}
          >
            <BtnLg
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 7px"
              }} 
              icon={PrintSvg}
              text={Translate("exchanges", "printreturnedexchange")}
            />
          </div>
        </div>
      </div>
    </TabShow>
  );
}
 
export default ExchangePrintModal;