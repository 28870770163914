import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import ContainerPage from "../_Common/ContainerPage";
import { connect } from "react-redux";
import Card from "../_Common/Card/Card";
import {
  DashboardInTransitSvg,
  DashboardPostponedSvg,
  DashboardShippedSvg,
  MediumCoinSvg, 
  currentOrdersSvg, 
  deliveredOrdersSvg,
  doubleCheckSvg,
  grayChevronRightSvg
} from "../_Common/IconSvg";
import { ProductSvg } from "../SideBar/SideBarSvg";
import CustomTable, { TableColumnProps } from "../_Common/CustomTable/CustomTable";
import DeliveryRateChart from "./DeliveryRateChart";
import { RequestStatus, RewardsData, NotificationType, DashboardNotificationsData, DashboardStatsData, topProduct, performanceData, deliveryChartData, DashboardConfirmedOrders, TiersDataItem } from "../../../types";
import { rewardsActions } from "../../../actions/rewards.actions";
import { alertActions, dashboardActions } from "../../../actions";
import { renderTierStyle } from "../../../constants/rewards.constants";
import { notificationCardStyles } from "../../../constants/dashboard.constants";
import OrderDeliveryChart from "./OrderDeliveryChart";
import Cookies from "js-cookie";
import { RouterChildContext, useHistory } from "react-router-dom";
import DatePicker from "../../_Common/DatePicker";
import { StringParam, encodeQueryParams, useQueryParams } from "use-query-params";
import { stringify } from "query-string";
import { Translate, _FormatCurrency } from "../../../utils/lang/translate";
import ContentLoader from "react-content-loader";
import MainCardSlide from "./components/MainCardSlide";
import MainCardItemSkeletonLoader from "./components/MainCardItemSkeletonLoader";
import DashboardNotificationSkeletonLoader from "./components/DashboardNotificationSkeletonLoader";

const lang = Cookies.get("lang");



interface DashboardProps {
  dtStoreinfo: any,
  GettingRewards: RequestStatus,
  dataRewards: RewardsData,
  GetRewards: () => void,
  SendAlert: (code: string, text: string, action: string) => void,
  GetDashboardMainStats: () => void,
  GetDashboardNotifications: () => void,
  GettingMainStats: RequestStatus,
  GettingDashboardNotifs: RequestStatus,
  dashboardStats: DashboardStatsData,
  dashboardNotifications: DashboardNotificationsData,
  GetTopProducts: (filter: string) => void,
  GettingTopProducts: RequestStatus,
  topProductsData: topProduct[],
  GetPerformance: (filter: string) => void,
  GettingPerformance: RequestStatus,
  performanceData: performanceData,
  GetDeliveryPerDay: (filter: string) => void,
  GettingDeliveryPerDay: RequestStatus,
  deliveryPerDayData: deliveryChartData,
  GetConfirmedOrders: () => void,
  GettingConfirmedOrders: RequestStatus,
  confirmedOrdersData: DashboardConfirmedOrders,
  dataProfile: any,
};

type topProductData = { 
  index: number, 
  product_name: string, 
  sold_quantity: number, 
  num_orders: number 
};

const Dashboard = ({
  dtStoreinfo,
  GetRewards,
  GettingRewards,
  SendAlert,
  dataRewards,
  GetDashboardMainStats,
  GetDashboardNotifications,
  GettingDashboardNotifs,
  GettingMainStats,
  dashboardStats,
  dashboardNotifications,
  GetTopProducts,
  GettingTopProducts,
  topProductsData,
  GetPerformance,
  GettingPerformance,
  performanceData,
  deliveryPerDayData,
  GettingDeliveryPerDay,
  GetDeliveryPerDay,
  GettingConfirmedOrders,
  GetConfirmedOrders,
  confirmedOrdersData,
  dataProfile
}: DashboardProps) => {


  const hard_coded_tiers_data: TiersDataItem[] = [
    {
      id: "32a1ed64-4a80-4c27-b66b-b71936f22481",
      name: "Member",
      ratio: "1.00",
      min_deliveries: 0,
      max_deliveries: 199
    },
    {
      id: "2c7781bc-76e5-4bcc-b9e9-789088462452",
      name: "Friend",
      ratio: "1.25",
      min_deliveries: 200,
      max_deliveries: 999
    },
    {
      id: "4e57af48-5dfe-42dd-9704-c15fda88d505",
      name: "Best Friend",
      ratio: "1.50",
      min_deliveries: 1000,
      max_deliveries: 1999
    },
    {
      id: "f3952099-d500-4dc9-a054-4cc9fe543864",
      name: "Family",
      ratio: "1.75",
      min_deliveries: 2000,
      max_deliveries: 9999
    },
    {
      id: "39dfa2aa-b197-4255-aea7-883606f417ce",
      name: "Ambassador",
      ratio: "2.00",
      min_deliveries: 10000,
      max_deliveries: 100000
    }
  ];

  const ordersStatusSlides = [
    { 
      title: Translate("orders", "currentOrd"), 
      handler: () => history.push("/orders?status=32%2C31%2C22%2C15%2C9%2C8"), 
      text: Translate("dashboard", "gotoorders"), 
      icon: currentOrdersSvg, 
      count: dashboardStats?.current_orders?.count ?? 0
    },
    { 
      title: Translate("dashboard", "orders9"), 
      handler: () => history.push("/orders?status=9"), 
      text: Translate("dashboard", "gotoorders"), 
      icon: DashboardInTransitSvg, 
      count: dashboardStats?.current_orders?.stats.find(item => item.status === 9)?.total ?? 0
    },
    { 
      title: Translate("dashboard", "orders31"), 
      handler: () => history.push("/orders?status=31"), 
      text: Translate("dashboard", "gotoorders"), 
      icon: DashboardShippedSvg, 
      count: dashboardStats?.current_orders?.stats.find(item => item.status === 31)?.total ?? 0
    },
    { 
      title: Translate("dashboard", "orders42"), 
      handler: () => history.push("/orders?status=42"), 
      text: Translate("dashboard", "gotoorders"), 
      icon: DashboardPostponedSvg, 
      count: dashboardStats?.current_orders?.stats.find(item => item.status === 42)?.total ?? 0
    },
  ]
  
  const notifs = Object.entries(dashboardNotifications ?? {}).map((item, _) => ({
    type: item[0], 
    number: item[1] 
  })) as { type: NotificationType, number: number }[];

  const topProducts: topProductData[] = (topProductsData ?? []).map((item, index) => ({
    index: index + 1,
    product_name: item.product__logistical_description,
    num_orders: item.orders_count,
    sold_quantity: item.quantity
  }));

  const topProductsCols: TableColumnProps<topProductData, keyof topProductData>[] = [
    {
      key: "index",
      title: "",
      renderItem: (item) => (
        <h3 
          className={`
            DlMg rewards-info-text TxtCn 
            ${item?.index === 1 && " dashboard-top-selling-product"}
          `}
          style={{ minWidth: "30px" }}
        > 
          {item?.index} 
        </h3>
      ),
      renderLoading: () => <div className="dashboard-top-products-rank-skeleton"/>
    },
    {
      key: "product_name",
      title: Translate("product", "product"),
      renderItem: (item) => (
        <div className="InFlx Stclmnf align-items-start" style={{ maxWidth: "200px" }}>
          <h4 className="DlMg rewards-info-title">{item?.product_name}</h4>
          <h4 className="DlMg rewards-info-title StOpcVal">{item?.num_orders} {Translate("sidebar", "orders")}</h4>
        </div>
      ),
      renderLoading: () => (
        <div className="InFlx Stclmnf align-items-start" style={{ maxWidth: "fit-content", gap: "10px" }}>
          <div className="dashboard-top-products-product-name-skeleton"/>
          <div className="dashboard-top-products-orders-number-skeleton"/>
        </div>
      )
    },
    {
      key: "sold_quantity",
      title: Translate("dashboard", "soldquantity"),
      renderItem: (item) => <h3 className="DlMg rewards-info-text TxtCn"> {item?.sold_quantity} </h3>,
      renderLoading: () => <div className="dashboard-top-products-quantity-skeleton" style={{ margin: "0 auto" }}/>
    }
  ];

  const history = useHistory<RouterChildContext['router']['history']>();

  const [query, setQuery] = useQueryParams({
    top_products_start: StringParam,
    top_products_end: StringParam,
    delivery_rate_start: StringParam,
    delivery_rate_end: StringParam,
    delivered_orders_start: StringParam,
    delivered_orders_end: StringParam
  });

  const [slideIndex, setSlideIndex] = useState<number>(0);

  useEffect(() => {

    GetRewards();
    GetDashboardMainStats();
    GetDashboardNotifications();
    if(dtStoreinfo?.is_gold){
      GetConfirmedOrders();
    }
  }, []);

  useEffect(() => {
    
    const { top_products_start, top_products_end } = query;
    const today = new Date();
    
    if(!top_products_start && !top_products_end){
      setQuery((prev) => ({
        ...prev,
        top_products_start: new Date(today.getFullYear(), today.getMonth(), 2).toISOString().split("T")[0],
        top_products_end: new Date().toISOString().split("T")[0]
      }))  
    }
    
    const encodedTopProductsQuery = encodeQueryParams({
      start_date: StringParam,
      end_date: StringParam 
    }, { start_date: top_products_start, end_date: top_products_end });

    if(dtStoreinfo?.stock_managed){
      GetTopProducts(`${stringify(encodedTopProductsQuery)}`);
    }

  }, [query.top_products_start, query.top_products_end]);

  useEffect(() => {
    
    const { delivery_rate_start, delivery_rate_end } = query;

    const today = new Date();

    if(!delivery_rate_start && !delivery_rate_end){
      setQuery((prev) => ({
        ...prev,
        delivery_rate_start: new Date(today.getFullYear(), today.getMonth(), 2).toISOString().split("T")[0],
        delivery_rate_end: new Date().toISOString().split("T")[0]
      }));  
    }

    const encodedDeliveryRateQuery = encodeQueryParams({
      start_date: StringParam,
      end_date: StringParam
    }, { start_date: delivery_rate_start, end_date: delivery_rate_end });

    GetPerformance(`${stringify(encodedDeliveryRateQuery)}`);

  }, [query.delivery_rate_start, query.delivery_rate_end]);

  useEffect(() => {
    
    const { delivered_orders_start, delivered_orders_end } = query;

    const today = new Date();

    if(!delivered_orders_start && !delivered_orders_end){
      setQuery((prev) => ({
        ...prev,
        delivered_orders_start: new Date(today.getFullYear(), today.getMonth(), 2).toISOString().split("T")[0],
        delivered_orders_end: new Date().toISOString().split("T")[0]
      }));  
    }
    
    const encodedDeliveredOrdersQuery = encodeQueryParams({
      start_date: StringParam,
      end_date: StringParam
    }, { start_date: delivered_orders_start, end_date: delivered_orders_end });

    GetDeliveryPerDay(`${stringify(encodedDeliveredOrdersQuery)}`);

  }, [query.delivered_orders_start, query.delivered_orders_end]);  

  useEffect(() => {
    if(GettingRewards === "3"){
      SendAlert("50", "failed to fetch rewards info", "");
    }
    if(GettingDashboardNotifs === "3"){
      SendAlert("50", "failed to fetch dashboard notifications", "");
    }
    if(GettingMainStats === "3"){
      SendAlert("50", "failed to fetch dashboard statistics", "");
    }
    if(GettingTopProducts === "3"){
      SendAlert("50", "failed to get top products data", "");
    }
    if(GettingPerformance === "3"){
      SendAlert("50", "failed to get performance data", "");
    }
    if(GettingDeliveryPerDay === "3"){
      SendAlert("50", "failed to get delivery per day data!", "");
    }
  }, [
    GettingRewards, 
    GettingDashboardNotifs, 
    GetDashboardMainStats, 
    GettingPerformance, 
    GettingTopProducts, 
    GettingDeliveryPerDay
  ]);

  return (
    <ContainerPage
      page_title={Translate("dashboard", "welcomeback") + " " + (dtStoreinfo?.full_name ?? dtStoreinfo?.name)}
      special={false}
    >
      <div className="dashboard-layout">
        <div className="InFlx">
          {/* main card */}
          {
            GettingMainStats === "2"
            &&
            <Card padding={8}>
              <div className="FlWd dashboard-main-card-layout">
                <div 
                  className="InFlx Stclmnf align-items-start dashboard-main-card-item" 
                  style={{ gap: "6px", padding: "16px" }}
                  onClick={() => history.push("/payments")}
                >
                  <h3 className="DlMg dashboard-main-card-title">{Translate("dashboard", "dashboardpayablebalance")}</h3>
                  <h1 
                    className={`
                      DlMg dashboard-main-card-number 
                      ${dashboardStats.payable_balance.amount >= 0 ? "dashboard-payable-balance" : ""}
                    `}
                  >
                    <span dir="ltr">{dataProfile.view_payment ? _FormatCurrency(dashboardStats.payable_balance.amount) : "N/A"}</span> { lang === "ar" ? dtStoreinfo?.country.ar_currency : dtStoreinfo?.country.lt_currency }
                  </h1>
                  <h5 className="DlMg dashboard-main-card-info-text StOpcVal">
                    {
                      dataProfile?.view_payment
                      ? <> {Translate("dashboard", "lastwithdrawalon")} {dashboardStats.payable_balance.last_withdrawal} </>
                      : Translate("dashboard", "unavailableinformation")
                    }
                  </h5>
                </div>
                <div className="custom-divider"/>
                <div 
                  className="InFlx Stclmnf align-items-start dashboard-main-card-item" 
                  style={{ gap: "6px", padding: "16px" }}
                >
                  <h3 className="DlMg dashboard-main-card-title">{Translate("dashboard", "myincometoday")}</h3>
                  <h1 className="DlMg dashboard-main-card-number">{ dataProfile?.view_payment ? _FormatCurrency(dashboardStats.income_today.amount) : "N/A" } { lang === "ar" ? dtStoreinfo?.country.ar_currency : dtStoreinfo?.country.lt_currency }</h1>
                  <h5 className="DlMg dashboard-main-card-info-text StOpcVal InFlx AlgnItm" style={{ gap: "5px" }}>
                    {
                      dataProfile?.view_payment ?
                        <>
                          <span 
                            className={ dashboardStats.income_today.diff_from_last_time >= 0 ? "dashboard-payable-balance" : "" }
                          >
                            <span dir="ltr">{dashboardStats.income_today.diff_from_last_time >= 0 && "+"} {dashboardStats.income_today.diff_from_last_time}%</span>
                          </span> {Translate("dashboard", "fromlast")} {new Date().toLocaleDateString(lang, { weekday: "long" })} { lang === "ar" && Translate("dashboard", "past") }
                        </>
                      :
                        Translate("dashboard", "unavailableinformation")
                    }
                  </h5>
                </div>
                <div className="custom-divider"/>
                <div 
                  className="InFlx align-items-start dashboard-main-card-item spcBtwn" 
                  style={{ gap: "6px", padding: "16px" }}
                  onClick={() => {
                    history.push(
                      `/orders?began=${new Date().toLocaleDateString('zh-Hans-CN').replaceAll('/', '-')}&end=${new Date().toLocaleDateString('zh-Hans-CN').replaceAll('/', '-')}&status=41`
                    );
                  }}
                >
                  <div className="InFlx Stclmnf align-items-start" style={{ gap: "6px" }}>
                    <h3 className="DlMg dashboard-main-card-title">{Translate("dashboard", "deliveredtoday")}</h3>
                    <h1 className="DlMg dashboard-main-card-number">
                      {dashboardStats.delivered_today.count}
                    </h1>
                    <h5 className="DlMg dashboard-main-card-info-text StOpcVal InFlx AlgnItm" style={{ gap: "5px" }}>
                      <span 
                        className={ dashboardStats.delivered_today.diff_from_last_time >= 0 ? "dashboard-payable-balance" : "" }
                      >
                        <span dir="ltr">{dashboardStats.delivered_today.diff_from_last_time >= 0 && "+"} {dashboardStats.delivered_today.diff_from_last_time}</span>
                      </span> {Translate("dashboard", "fromlast")} {new Date().toLocaleDateString(lang, { weekday: "long" })} { lang === "ar" && Translate("dashboard", "past") }
                    </h5>
                  </div>
                  <div>{deliveredOrdersSvg}</div>
                </div>
                <div className="custom-divider"/>
                <div className="slider-container RlPs" style={{ overflow: "hidden" }}>
                  <div 
                    className="InFlx AlgnItm JstfCnt dashboard-main-card-item-arrow left-arrow" 
                    onClick={() => setSlideIndex(prev => prev === 0 ? ordersStatusSlides.length - 1 : prev - 1)}
                  > 
                    {grayChevronRightSvg} 
                  </div>
                  <MainCardSlide
                    key={slideIndex} 
                    handler={ordersStatusSlides[slideIndex].handler}  
                    icon={ordersStatusSlides[slideIndex].icon}
                    text={ordersStatusSlides[slideIndex].text}
                    count={ordersStatusSlides[slideIndex].count}
                    title={ordersStatusSlides[slideIndex].title}
                  />
                  <div 
                    className="InFlx AlgnItm JstfCnt dashboard-main-card-item-arrow right-arrow" 
                    onClick={() => setSlideIndex(prev => prev === ordersStatusSlides.length - 1 ? 0 : prev + 1)}
                  > 
                    {grayChevronRightSvg} 
                  </div>
                </div>
              </div>
            </Card>
          }
          {
            GettingMainStats === "1"
            &&
            <Card>
              <div className="FlWd dashboard-main-card-layout">
                {
                  [0, 1, 2, 3].map((_, index) => (
                    <>
                      <MainCardItemSkeletonLoader key={index}/>
                      { index !== 3 && <div className="custom-divider"/> }
                    </>
                  ))
                }
              </div>
            </Card>
          }
        </div>
        <div className="InFlx align-items-start" style={{ gap: "20px", flexWrap: "wrap" }}>
          <div className="InFlx Stclmnf" style={{ gap: "16px", flex: "1 1 400px", minWidth: "0" }}>
            {/* dashboard notifications */}
            <div className="dashboard-notifications-layout">
              {
                GettingDashboardNotifs === "2"
                &&
                notifs.map((notification, index) => (
                <div 
                  key={index}
                  onClick={() => history.push(notificationCardStyles[notification.type].redirectTo)}
                  style={{ padding: "8px", cursor: "pointer" }}
                  className={notificationCardStyles[notification.type].styleClass + " card-layout "}
                >
                  <div className="InFlx AlgnItm spcBtwn">
                    <div className="InFlx AlgnItm" style={{ flexGrow: 1, gap: "10px" }}>
                      <div 
                        className="sb4R InFlx AlgnItm JstfCnt" 
                        style={{ 
                          padding: "5px", 
                          backgroundColor: notificationCardStyles[notification.type].iconBackgroundColor,
                          minWidth: "20px" 
                        }}
                      >
                        {notificationCardStyles[notification.type].svgIcon}
                      </div>
                      <div className="InFlx AlgnItm" style={{ gap: "5px" }}>
                        <h3 className="DlMg rewards-info-text">
                          {notification.number}
                        </h3>
                        <p className="DlMg rewards-progress-text StOpcVal">
                          {notificationCardStyles[notification.type].text}
                        </p>
                      </div>
                    </div>
                    <div className="InFlx AlgnItm chevron-hover">
                      {notificationCardStyles[notification.type].chevronIcon}
                    </div>
                  </div>
                </div>
                ))
              }
              {
                GettingDashboardNotifs === "1"
                &&
                [0, 1, 3].map((_, index) => (
                  <DashboardNotificationSkeletonLoader key={index}/>
                ))
              }
            </div>
            {/* delivered orders chart */}
            <Card>
              <Card.Header>
                <div className="FlWd InFlx Stclmnf" style={{ gap: "4px" }}>
                  <div className="InFlx AlgnItm spcBtwn">
                    <h3 className="DlMg dashboard-card-title">{Translate("dashboard", "deliveredorders")}</h3>
                    {
                      GettingDeliveryPerDay === "2"
                      &&
                      <DatePicker
                        forceUp={false}
                        from={query.delivered_orders_start}
                        to={query.delivered_orders_end}
                        changeFrom={(from: string) => setQuery((prev) => ({ ...prev, delivered_orders_start: from }))}                      
                        changeTo={(to: string) => setQuery((prev) => ({ ...prev, delivered_orders_end: to }))}
                      />
                    }
                  </div>
                  <h1 className="DlMg dashboard-main-card-number">
                    {GettingDeliveryPerDay === "2" ? deliveryPerDayData.total_count : 0}
                  </h1>
                  {
                    (!query.delivered_orders_start && !query.delivered_orders_end)
                    &&
                    <p className="DlMg rewards-progress-text StOpcVal">
                      {Translate("dashboard", "lastmonth")}
                    </p>
                  }
                </div>
              </Card.Header>
              <div className="FlWd InFlx Stclmnf" style={{ marginTop: "20px" }}>
                {
                  GettingDeliveryPerDay === "2"
                  ?
                    <OrderDeliveryChart 
                      counts={deliveryPerDayData.stats.map((item, _) => item.count)} 
                      dates={deliveryPerDayData.stats.map((item, _) => item.date)}
                    />
                  :
                  <ContentLoader 
                    speed={2}
                    backgroundColor="var(--bdgclInp)"
                    foregroundColor="var(--defcl)"
                    width={"100%"}
                    height={"250px"}
                  > 
                    <rect x="0" y="0" rx="0" ry="0" style={{ width:"100%", height: "100%" }} />
                  </ContentLoader>
                }
              </div>
            </Card>
          </div>
          <div className="InFlx Stclmnf" style={{ gap: "16px", flex: "1 1 400px"}}>
            {/* rewards  */}
            <Card>
                <div className="rewards-card-info-layout" dir="ltr">
                  <div className="InFlx Stclmnf AlgnItm" style={{ gap: "4px", flexGrow: 1 }}>
                    { GettingRewards === "1" && <div className="rewards-card-loading-title" /> }
                    { GettingRewards === "2" && <h4 className="DlMg rewards-info-title">{Translate("RewardsPage", "your_current_tier")}</h4> }
                    <div 
                      className="InFlx AlgnItm" 
                      style={
                        GettingRewards === "2" ?
                          { gap: "12px", margin: "-10px 0" }
                        :
                          { gap: "5px" }
                      }
                    >
                      {
                        GettingRewards === "1"
                        &&
                        <>
                          <div className="rewards-card-loading-tier-svg"/>
                          <div className="rewards-card-loading-tier-name"/>
                        </>
                      }
                      {
                        GettingRewards === "2"
                        &&
                        <>
                          <div style={{ marginBottom: "-5px", scale: "80%" }}>
                            {renderTierStyle[dataRewards.tier.name].svg}
                          </div>
                          <h3 className={`DlMg rewards-info-text ${renderTierStyle[dataRewards.tier.name].text}`}>
                            {dataRewards.tier.name}
                          </h3>
                        </>
                      }
                    </div>
                  </div>
                  <div className="custom-divider"/>
                  <div className="InFlx Stclmnf AlgnItm" style={{ gap: "4px", flexGrow: 1 }}>
                    { GettingRewards === "1" && <div className="rewards-card-loading-title" /> }
                    { GettingRewards === "2" && <h4 className="DlMg rewards-info-title"> {Translate("orders", "your_points")} </h4> }
                    <div className="InFlx AlgnItm" style={{ gap: "5px" }}>
                      {
                        GettingRewards === "1"
                        &&
                        <>
                          <div className="rewards-card-loading-tier-svg"/>
                          <div className="rewards-card-loading-tier-name"/>
                        </>
                      }
                      {
                        GettingRewards === "2"
                        &&
                        <>
                          <div style={{ marginBottom: "-3px" }}>{MediumCoinSvg}</div>
                          <h3 className="DlMg rewards-info-text">
                            {dataRewards.num_points}
                          </h3>
                        </>
                      }
                    </div>
                  </div>
                </div>
                <div className="rewards-card-progress-layout" style={{ marginTop: "20px" }} dir="ltr">
                  <div className="InFlx Stclmnf align-items-end" style={{ gap: "8px" }}>
                    <div 
                      className="progress-bar-container" 
                      style={{ 
                        background: GettingRewards === "2" ? renderTierStyle[dataRewards.tier.name].progressColor : "gray" 
                      }}
                    >
                      <div 
                        className="progress-bar" 
                        style={{ 
                          width: 
                            GettingRewards === "2" 
                            ?
                              dataRewards.tier.name === "Ambassador"
                              ? 
                                "0%"
                              :
                                dataRewards.num_deliveries > dataRewards.tier.min_deliveries
                                ?
                                  (dataRewards.points_to_next_tier / (dataRewards.next_tier.min_deliveries - dataRewards.tier.min_deliveries) * 100).toString() + "%"
                                :
                                  "100%"
                            : 
                              "100%" 
                        }} 
                      />
                    </div>
                    {
                      GettingRewards === "1"
                      &&
                      <div className="rewards-card-progress-text-loader"/>
                    }
                    {
                      GettingRewards === "2"
                      &&
                      <p className="DlMg rewards-progress-text StOpcVal">
                        {
                          dataRewards.num_deliveries > hard_coded_tiers_data[hard_coded_tiers_data.length - 2].min_deliveries
                          ? 
                            Translate("RewardsPage", "morerewardsawaiting")
                          : 
                            dataRewards.num_deliveries < dataRewards.tier.min_deliveries
                            ?
                              <div className="deliveries-to-text">
                                <span>
                                  {dataRewards.tier.min_deliveries - dataRewards.num_deliveries} 
                                </span>
                                <span style={{ margin: "0 4px" }}>
                                  {Translate("RewardsPage", "remainingDeliveriesToKeep")}
                                </span> 
                                <span className={`${renderTierStyle[dataRewards.tier.name].text} stbold`}>
                                  {dataRewards.tier.name}
                                </span>
                              </div>
                            :
                              <div className="deliveries-to-text">
                                <span>
                                  {dataRewards.points_to_next_tier} 
                                </span>
                                <span style={{ margin: "0 4px" }}>
                                  {Translate("dashboard", "deliveriesto")}
                                </span> 
                                <span className={`${renderTierStyle[dataRewards.next_tier.name].text} stbold`}>
                                  {dataRewards.next_tier.name}
                                </span>
                              </div>
                        }
                      </p>
                    }
                  </div>
                </div>
            </Card>
            {/* confirmation */}
            {
              (GettingConfirmedOrders === "2" && confirmedOrdersData.count > 0)
              &&
              <Card padding={16} onClick={() => history.push("/confirmation")}>
                <div className="InFlx AlgnItm spcBtwn">
                  <div className="InFlx AlgnItm" style={{ gap: "12px" }}>
                    <div 
                      className="InFlx AlgnItm JstfCnt" 
                      style={{ height: "34px", width: "34px", borderRadius: "90px", backgroundColor: "#008AFF" }}
                    >
                      {doubleCheckSvg}
                    </div>
                    <div className="InFlx AlgnItm" style={{ gap: "10px" }}>
                      <h3 className="DlMg confirmed-orders-card-number">{confirmedOrdersData.count}</h3>
                      <p className="DlMg confirmed-orders-card-text">{Translate("confirmation", "orders40")}</p>
                    </div>
                  </div>
                  <div className="InFlx AlgnItm">
                    {grayChevronRightSvg}
                  </div>
                </div>
              </Card>
            }
            {/* top products card*/}
            {
              (dtStoreinfo?.stock_managed && GettingTopProducts !== "3")
              &&
              <Card>
                <Card.Header>
                  <div className="InFlx AlgnItm" style={{ gap: "5px" }}>
                    <div style={{ marginBottom: "-5px" }}>{ProductSvg}</div>
                    <h3 className="DlMg dashboard-card-title">{Translate("dashboard", "topsellingproducts")}</h3>
                  </div>
                  {
                    GettingTopProducts === "2"
                    &&
                    <DatePicker
                      forceUp={false}
                      from={query.top_products_start}
                      to={query.top_products_end}
                      changeFrom={(from: string) => setQuery((prev) => ({ ...prev, top_products_start: from }))}
                      changeTo={(to: string) => setQuery((prev) => ({ ...prev, top_products_end: to }))}
                    />
                  }
                </Card.Header>
                <div className="InFlx Stclmnf AlgnItm" style={{ marginTop: "20px" }}>
                  <CustomTable 
                    columns={topProductsCols} 
                    data={topProducts} 
                    loading={GettingTopProducts === "1"} 
                    emptyMessage={Translate("dashboard", "notopproddata")}
                  />
                </div>
              </Card>
            }

            {/* performance chart */}
            <Card>
              <Card.Header>
                <h3 className="DlMg dashboard-card-title">{Translate("dashboard", "performance")}</h3>
                {
                  GettingPerformance === "2"
                  &&
                  <DatePicker
                    forceUp={false}
                    from={query.delivery_rate_start}
                    to={query.delivery_rate_end}
                    changeFrom={(from: string) => setQuery((prev) => ({ ...prev, delivery_rate_start: from }))}
                    changeTo={(to: string) => setQuery((prev) => ({ ...prev, delivery_rate_end: to }))}
                  />
                }
              </Card.Header>
              <div className="InFlx Stclmnf AlgnItm" style={{ marginTop: "20px", gap: "20px" }}>
                {
                  GettingPerformance === "2"
                  ?
                    <DeliveryRateChart deliveryRate={performanceData.delivery_rate}/>
                  :
                    <ContentLoader 
                      speed={2}
                      backgroundColor="var(--bdgclInp)"
                      foregroundColor="var(--defcl)"
                      width={"100%"}
                      height={"200px"}
                    > 
                      <rect x="0" y="0" rx="0" ry="0" style={{ width:"100%", height: "100%" }} />
                    </ContentLoader>
                }
              </div>
            </Card>
          </div>
        </div>
      </div>
    </ContainerPage>
  );
}
 
function mapState(state: any) {
  const { dtStoreinfo, dataProfile } = state.user;
  const { dataRewards, GettingRewards } = state.rewards;
  const { 
    dashboardNotifications, 
    GettingDashboardNotifs, 
    GettingMainStats, 
    dashboardStats, 
    GettingTopProducts,
    topProductsData,
    GettingPerformance,
    performanceData,
    GettingDeliveryPerDay,
    deliveryPerDayData,
    GettingConfirmedOrders,
    confirmedOrdersData 
  } = state.dashboard;
  
  return {
    dtStoreinfo,
    dataRewards,
    GettingRewards,
    dashboardNotifications,
    GettingMainStats,
    GettingDashboardNotifs,
    dashboardStats,
    GettingTopProducts,
    topProductsData,
    performanceData,
    GettingPerformance,
    GettingDeliveryPerDay,
    deliveryPerDayData,
    GettingConfirmedOrders,
    confirmedOrdersData,
    dataProfile
  };
}

const actionCreators = {
  GetRewards: rewardsActions.GetRewards,
  SendAlert: alertActions.SendAlert,
  GetDashboardNotifications: dashboardActions.GetDashboardNotifications,
  GetDashboardMainStats: dashboardActions.GetDashboardMainStats,
  GetTopProducts: dashboardActions.GetTopProducts,
  GetPerformance: dashboardActions.GetPerformance,
  GetDeliveryPerDay :dashboardActions.GetDeliveryPerDay,
  GetConfirmedOrders: dashboardActions.GetConfirmedOrders
};

const connectedDashboard = connect(mapState, actionCreators)(Dashboard);

export { connectedDashboard as Dashboard };