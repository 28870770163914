import React, { useEffect, useRef } from "react";
import TabShow from "../_Common/TabShow";
import { CloseSvg2 } from "../_Common/IconSvg";
import GroupeBtn from "../_Common/GroupeBtn";
import { Translate } from "../../../utils/lang/translate";
import BtnLg from "../_Common/BtnLg";
import GroupeForm from "../../_Common/GroupeForm";
import AddedTeamMemberImage from "../../../utils/img/addedteammember.png";
import { Event } from "../_Common";
interface NewTeamMemberModalProps {
  data: any,
  onClose: () => void
}

const NewteamMemberModal = ({ data, onClose }: NewTeamMemberModalProps) => {

  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if(modalRef.current && !modalRef.current?.contains(e.target as Node)){
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('mouseup', handleClickOutside);
    return () => window.removeEventListener('mouseup', handleClickOutside);
  }, []);

  return (
    <TabShow
      darkBg
      noReturn
      noPadding={true}
      style={{
        borderRadius: "8px",
        backgroundColor: "var(--defcl)",
        minWidth: "fit-content"
      }}
    >
      <div 
        ref={modalRef} 
        className="RlPs InFlx Stclmnf AlgnItm" 
        style={{ 
          padding: "40px", 
          gap: "24px", 
          minWidth: "fit-content" 
        }}
      >
        <div style={{ position: "absolute", top: "20px", right: "20px", zIndex: 10 }}>
          <GroupeBtn 
            data_btn={[
              {
                svgbtn: CloseSvg2,
                type: "Gray",
                action: onClose,
              }
            ]}
          />
        </div>
        <img src={AddedTeamMemberImage} alt={"..."} />
        <div className="InFlx AlgnItm" style={{ gap: "3px" }}>
          <p className="DlMg">{data.full_name}</p>
          <p className="DlMg sTclPg">{Translate("TeamsPage", "memberwasadded")}</p>
        </div>
        <div className="InFlx Stclmnf FlWd" style={{ gap: "12px" }}>
          <GroupeForm
            id={"added-member-username"}
            name={"added-member-username"}
            text={Translate("auths", "username")}
            type={"text"}
            value={data.username}
            workfun={() => {}}
            disabled={true}
            copyButton={() => Event("TEAMS", "COPY_NEW_TEAM_MEMBER_USERNAME", "CLICK_EVENT")}
          />
          <GroupeForm
            id={"added-member-password"}
            name={"added-member-password"}
            text={Translate("auths", "password")}
            type={"text"}
            value={data.password}
            workfun={() => {}}
            disabled={true}
            copyButton={() => Event("TEAMS", "COPY_NEW_TEAM_MEMBER_PASSWORD", "CLICK_EVENT")}
          />
        </div>
        <div
          className="Inlflx AlgnItm JstfCnt CrsPoi"
          onClick={onClose}
        >
          <BtnLg
            disabled={false}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px 7px"
            }} 
            text={Translate("exchanges", "done")}
          />
        </div>
      </div>
    </TabShow>  
  );
}
 
export default NewteamMemberModal;