export const notifsConstants = {


    UPDATE_NOTIF_SUCCESS: 'UPDATE_NOTIF_SUCCESS',
    UPDATE_NOTIF_FAILURE: 'UPDATE_NOTIF_FAILURE',
    UPDATE_NOTIF_REQUEST: 'UPDATE_NOTIF_REQUEST',

    UPDATE_STATUS_NOTIF_SUCCESS: 'UPDATE_STATUS_NOTIF_SUCCESS',
    UPDATE_STATUS_NOTIF_FAILURE: 'UPDATE_STATUS_NOTIF_FAILURE',
    UPDATE_STATUS_NOTIF_REQUEST: 'UPDATE_STATUS_NOTIF_REQUEST',


    GET_NOTIF_SUCCESS: 'GET_NOTIF_SUCCESS',
    GET_NOTIF_FAILURE: 'GET_NOTIF_FAILURE',
    GET_NOTIF_REQUEST: 'GET_NOTIF_REQUEST',

};

export const MAX_TOTAL_PRICE_DZ = 100000;
export const MAX_TOTAL_PRICE_TN = 2000;