import React from "react";
function goback() {
  window.history.back();
}
export default function TabShow(props) {
  return (
    <div
      className="FxPs FlWd FlHg Zindx StlfNl StTpNl HidScrolStil"
      style={{ display: props.isHidden ? "none" : "block" }}
    >
      <div className="TbDp RlPs FlWd FlHg StSizLn">
        <div className={"TbCdp RlPs VrAlg " + (props.notCnter ? "" : "TxtCn")}>
          <div
            className="StGwdt BdgClN MrAot RlPs StBr6 StBxSh Zindx"
            style={props.style}
          >
            <div className={`${props.noPadding ? "" : "lytWdp2"}`} style={props.boxStyling}>
              {
                props.tab_title
                &&
                <div className="StdirLn StmpB">
                  <h3 className="DlMg">{props.tab_title}</h3>
                </div>
              }
              <div>{props.children}</div>
            </div>
          </div>
          {!props.NotCls && (
            <div
              className="StRgNl StTpNl FxPs FlWd FlHg StClrOp"
              style={
                props.darkBg ? { backgroundColor: "rgb(0,0,0, 0.3)" } : {}
              }
              onClick={
                props.noReturn ?
                  () => {}
                :
                  goback 
              }
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}
