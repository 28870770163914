import React from "react";
import ContainerPage from "../_Common/ContainerPage";
import { Translate } from "../../../utils/lang/translate";
import Card from "../_Common/Card/Card";
import MaystroToAyorImage from "../../../utils/img/maystro-to-ayor.png";
import { AyorInstagram, AyorTiktok, AyorWeb } from "../_Common/IconSvg";


const items = [
  { label: Translate("MaystroShopToAyor", "landingpagecreation") },
  { label: Translate("MaystroShopToAyor", "aipower") },
  { label: Translate("MaystroShopToAyor", "salesbooster") },
  { label: Translate("MaystroShopToAyor", "customdomains") },
  { label: Translate("MaystroShopToAyor", "apps") },
  { label: Translate("MaystroShopToAyor", "themes") },
];

const ayor_links = [
  { link: "https://ayor.ai", svgIcon: AyorWeb },
  { link: "https://www.instagram.com/ayor_ai/", svgIcon: AyorInstagram },
  { link: "https://www.tiktok.com/@ayor.ai", svgIcon: AyorTiktok }
]

interface GriditemProps {
  label: string
}

function Griditem({ label }: GriditemProps) {
  return (
    <li className="services__Item">
      <span>{label}</span>
    </li>
  );
}

const MaystroShopToAyor = () => {
  return (
    <ContainerPage 
      page_title={Translate("titles", "shop")}
    >
      <div className="FlWd InFlx Stclmnf" style={{ gap: "24px" }}>
        <Card 
          className="maystro-to-ayor-image-container"
          bgImageSrc={MaystroToAyorImage} 
          padding={0} 
          borderColor="white"
        />
        <Card padding={10}>
          <div className="FlWd InFlx Stclmnf AlgnItm" style={{ gap: "35px" }}>
            <p className="DlMg TxtCn stfSize" style={{ padding: "20px 0", maxWidth: "850px" }}>
              {Translate("MaystroShopToAyor", "descriptiontext")}
            </p>
          </div>
          <ul className="services__grid" style={{ maxWidth: "600px" }}>
            {items.map((item, index) => (
              <Griditem key={index} label={item.label} />
            ))}
          </ul>
          <div className="InFlx JstfCnt" style={{ padding: "20px 0", marginBottom: "20px" }}>
            <a 
              href="https://ayor.ai"
              target="_blank"
              className="DlMg stUndrln stfSize blueapp TxtCn"
            >
              {Translate("MaystroShopToAyor", "visittext")}
            </a>
          </div>
          <Card bgColor="var(--gcl)">
            <div className="InFlx AlgnItm spcBtwn">
              <h2 className="DlMg">{Translate("footer", "contactus")}</h2>
              <div className="InFlx AlgnItm" style={{ gap: "10px" }}>
                {
                  ayor_links.map((link, index) => (
                    <div
                      dir="ltr"
                      onClick={() => window.open(link.link, "_blank")?.focus()} 
                      key={index}
                      className="InFlx AlgnItm ClSidTh CrsPoi" 
                      style={{ padding: "6px 10px", gap: "10px", borderRadius: "999px" }}
                    >
                      <div 
                        style={{ 
                          height: "26px", 
                          width: "26px", 
                          backgroundColor: "white", 
                          borderRadius: "50%" 
                        }}
                        className="InFlx AlgnItm JstfCnt"
                      >
                        {link.svgIcon}
                      </div>
                      <span className="StSmlS">
                        {link.link}  
                      </span>
                    </div>
                  ))
                }
              </div>
            </div>
          </Card>
        </Card>
      </div>
    </ContainerPage>
  );
}
 
export default MaystroShopToAyor;