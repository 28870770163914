import { dashboardConstants } from '../constants'
import { RequestService, FunctionService } from '../services';
import Cookies from 'js-cookie'

const token=Cookies.get('auth')

export const dashboardActions = {
  GetDashboardMainStats,
  GetDashboardNotifications,
  GetTopProducts,
  GetPerformance,
  GetDeliveryPerDay,
  GetConfirmedOrders
};

function GetDashboardMainStats() {
  return (dispatch) => {
    dispatch(FunctionService.request(dashboardConstants.GET_DASHBOARD_MAIN_STATS_REQUEST));
    RequestService.GetRequest("delivery/dashboard/main/", {
      Authorization: "Token " + token,
    })
    .then(res => {
      dispatch(FunctionService.succes(res.data, dashboardConstants.GET_DASHBOARD_MAIN_STATS_SUCCESS))
    })
    .catch(err => dispatch(FunctionService.failure(err, dashboardConstants.GET_DASHBOARD_MAIN_STATS_FAILURE)));
  }
};

function GetDashboardNotifications() {
  return (dispatch) => {
    dispatch(FunctionService.request(dashboardConstants.GET_DASHBOARD_NOTIFICATIONS_REQUEST));
    RequestService.GetRequest("delivery/dashboard/alerts/", {
      Authorization: "Token " + token,
    })
    .then(res => dispatch(FunctionService.succes(res.data, dashboardConstants.GET_DASHBOARD_NOTIFICATIONS_SUCCESS)))
    .catch(err => dispatch(FunctionService.failure(err, dashboardConstants.GET_DASHBOARD_NOTIFICATIONS_FAILURE)));
  }
};

function GetTopProducts(filter) {
  const url  = filter ? "delivery/dashboard/top_products/?" + filter : "delivery/dashboard/top_products/";
  return (dispatch) => {
    dispatch(FunctionService.request(dashboardConstants.GET_TOP_PRODUCTS_REQUEST));
    RequestService.GetRequest(url, {
      Authorization: "Token " + token,
    })
    .then(res => dispatch(FunctionService.succes(res.data.top_products, dashboardConstants.GET_TOP_PRODUCTS_SUCCESS)))
    .catch(err => dispatch(FunctionService.failure(err, dashboardConstants.GET_TOP_PRODUCTS_FAILURE)));
  }
};

function GetPerformance(filter) {
  const url  = filter ? "delivery/dashboard/performance/?" + filter : "delivery/dashboard/performance/";
  return (dispatch) => {
    dispatch(FunctionService.request(dashboardConstants.GET_PERFORMANCE_REQUEST));
    RequestService.GetRequest(url + filter, {
      Authorization: "Token " + token,
    })
    .then(res => dispatch(FunctionService.succes(res.data, dashboardConstants.GET_PERFORMANCE_SUCCESS)))
    .catch(err => dispatch(FunctionService.failure(err, dashboardConstants.GET_PERFORMANCE_FAILURE)));
  }
};

function GetDeliveryPerDay(filter) {
  const url  = filter ? "delivery/dashboard/delivered_orders/?" + filter : "delivery/dashboard/delivered_orders/";
  return (dispatch) => {
    dispatch(FunctionService.request(dashboardConstants.GET_DELIVERED_ORDERS_PER_DAY_REQUEST));
    RequestService.GetRequest(url + filter, {
      Authorization: "Token " + token,
    })
    .then(res => dispatch(FunctionService.succes(res.data, dashboardConstants.GET_DELIVERED_ORDERS_PER_DAY_SUCCESS)))
    .catch(err => dispatch(FunctionService.failure(err, dashboardConstants.GET_DELIVERED_ORDERS_PER_DAY_FAILURE)));
  }
};

function GetConfirmedOrders() {
  return (dispatch) => {
    dispatch(FunctionService.request(dashboardConstants.GET_CONFIRMED_ORDERS_REQUEST));
    RequestService.GetRequest("delivery/dashboard/orders_confirmed/", {
      Authorization: "Token " + token,
    })
    .then(res => dispatch(FunctionService.succes(res.data, dashboardConstants.GET_CONFIRMED_ORDERS_SUCCESS)))
    .catch(err => dispatch(FunctionService.failure(err, dashboardConstants.GET_CONFIRMED_ORDERS_FAILURE)));
  }
}