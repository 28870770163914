import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  productActions,
  ordersActions,
  commonActions,
  alertActions,
} from "../../../actions";
import ContainerPage from "../_Common/ContainerPage";
import { FeatureLoked } from "../_Common/FeatureLoked";
import { Translate } from "../../../utils/lang/translate";
import GroupeForm from "../../_Common/GroupeForm";
import SelectForm from "../../_Common/SelectForm";
import SelectFormSearch from "../../_Common/SelectFormSearch";
import Button from "../../_Common/Button";
import Loader from "../../_Common/Loader";
import { Link } from "react-router-dom";
import { Event, Select } from "../_Common";
import { CloseSvg, SmallCoinSvG, TowCoinsSvg, deliveryTruck } from "../_Common/IconSvg";
import {
  checkPhoneNumberValidity,
  getParamsFromUrl,
  whichWilayaHasPermissionToStopDesk,
} from "../../../utils/helpers";
import Cookies from "js-cookie";
import {
  CommuneInterface,
  ErrorInterface,
  OrderProductInterface,
  ProductInterface,
  OrderSubmit,
  wilayaInterface,
  stopDeskWilayaInterface
} from "../../../types";
import CheckBoxForm from "../../_Common/CheckBoxForm";
import { MAX_TOTAL_PRICE_DZ, MAX_TOTAL_PRICE_TN } from "../../../constants/orders.constants";

const lang = Cookies.get("lang");

interface AllowedEditionsInterface {
  edit_products?: boolean;
  edit_customer_infos?: boolean;
  edit_delivery_infos?: boolean;
}

const handleAllowedEditions = (
  status: number,
  isPremium: boolean,
  allAllowed = false
): AllowedEditionsInterface => {
  if (allAllowed)
    return {
      edit_products: true,
      edit_customer_infos: true,
      edit_delivery_infos: true,
    };
  let allowed = {
    edit_products: false,
    edit_customer_infos: true,
    edit_delivery_infos: false,
  };
  if (isPremium) {
    if ([0, 8, 9, 10, 11, 12, 15, 22, 50].indexOf(status) > -1) {
      allowed.edit_products = true;
      if (status !== 8) allowed.edit_delivery_infos = true;
    }
  } else {
    if ([0, 4, 5, 6].indexOf(status) > -1) {
      allowed.edit_products = true;
      allowed.edit_delivery_infos = true;
    }
  }
  return allowed;
};



const INITIAL_PRODUCT: OrderProductInterface = {
  product_id: "",
  quantity: 1,
  logistical_description: "",
};

type SwicthOptionProps = {
  optionId: 1 | 2,
  onOptionChange: (option: 1 | 2) => void,
  currentOption: 1 | 2
};

const SwitchOptionButton = ({ optionId, onOptionChange, currentOption }: SwicthOptionProps) => {
  return (
    <div
      onClick={
        () => {
          optionId === 1 ?
            Event("ADD_ORDER", "USE_HOME_DELIVERY_OPTION_BUTTON", "CLICK_EVENT")
          :
            Event("ADD_ORDER", "USE_STOP_DESK_OPTION_BUTTON", "CLICK_EVENT")
          onOptionChange(optionId);
        }
      } 
      className="col-half InFlx AlgnItm JstfCnt responsive-text" 
      style={{ 
        borderRadius: "50px", 
        padding: "10px 0", 
        cursor: "pointer", 
        backgroundColor: `${currentOption === optionId ? "#008AFF": "transparent"}` 
      }}
    >
      {
        optionId === 1 ?
          Translate("orders", "home")
        :
          Translate("orders", "stpdsk")
      }
    </div>
  );
}

const AddOrderPage = ({
  data,
  OrdersList,
  dataAddOrder,
  dataDelivery,
  dataProfile,
  dataUpInfOrd,
  DataUpdateOrder,
  DataCommune,
  dataInfOrd,
  dtStoreinfo,
  DataSearch,
  history,
  match,
  ProductFinded,
  GetingDelivery,
  GetinunfOrd,
  Getcommunes,
  GetOrders,
  GetOrderDetail,
  GetAllProduct,
  OrderGeting,
  Searching,
  OrderAdded,
  UpdateUnfOrder,
  AddOrder,
  UpdateOrder,
  UpdinunfOrd,
  OrderUpdating,
  SendAlert,
  ClearStore,
  GetCountry,
  GetDelivery,
  Getwilayas,
  GetAllUnfOrders,
  GetingWilaya,
  dataWilaya,
  GetigCommune,
  OrderDetailGeting,
  OrderDetail,
  GetingSDWilayas,
  dataStopDeskWilaya,
  GetStopdeskWilayas
}: any) => {
  var deliveryHome = 1;
  const [orderId, setOrderId] = useState<string | null>(null);
  const [products, setProducts] = useState([INITIAL_PRODUCT]);
  const [returnProducts, setReturnProducts] = useState<OrderProductInterface[]>([]);
  const [customerName, setCustomerName] = useState("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [commune, setCommune] = useState<CommuneInterface | null>(null);
  const [wilaya, setWilaya] = useState<(number | string)[] | null>(null);
  const [stopDesk, setStopDesk] = useState<stopDeskWilayaInterface | null>(null);
  const [orderPrice, setOrderPrice] = useState(0);
  const [noteToDriver, setNoteToDriver] = useState("");
  const [selectedOption, setSelectedOption] = useState<any | null>(1);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState<string>("0");
  const [error, setError] = useState<ErrorInterface>({});
  const [isExpressDelivery, setIsExpressDelivery] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isNotFoundReturn, setIsNotFoundReturn] = useState(false);
  const [productList, setProductList] = useState<ProductInterface[]>([]);
  const [filterSource, setFilterSource] = useState("?page=1");
  const [pageTitle, setPageTitle] = useState(
    Translate("orders", "addneworder")
  );
  const [buttonTitle, setButtonTitle] = useState(
    Translate("sidebar", "addorder")
  );
  const [allowedEditions, setAllowedEditions] =
    useState<AllowedEditionsInterface>({});
  const [externalId, setExternalId] = useState("");
  const [isShopOrder, setIsShopOrder] = useState(false);
  const [externalWilaya, setExternalWilaya] = useState<string | null>(null);
  const [externalCommune, setExternalCommune] = useState<
    string | number | null
  >(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState<number | null>(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [hideInput, setHideInput] = useState(10);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [freeDelivery, setFreeDelivery] = useState(false);
  const [sufficientBalance, setSufficientBalance] = useState(true);
  const [pointsDelivery, setPointsDelivery] = useState(false);
  const [sufficientPoints, setSufficientPoints] = useState(true);
  const hasAddPermission = dataProfile.is_store || dataProfile.add_order;
  const hasEditPermission = dataProfile.is_store || dataProfile.change_order;
  const { idorder } = match.params;

  const source = useMemo(
    () => getParamsFromUrl("source"),
    [window.location.href]
  );

  useEffect(() => {
    document.title = "Maystro Delivrey - " + Translate("titles", "order");
    GetCountry();
    if (source === "order")
      // GetOrders("?pagination=false");
      GetOrderDetail("?display_id=" + idorder);
    if (source === "unfOrder") GetAllUnfOrders();
    return () => {
      if (UpdinunfOrd === "3") ClearStore("CLEAR_UNF_ORD");
      ClearStore("CLEAR_COMMON");
    };
  }, []);

  useEffect(() => {
    if (dtStoreinfo?.country?.id) Getwilayas(dtStoreinfo.country.id);
  }, [dtStoreinfo]);

  useEffect(() => {
    if (selectedOption === 1) {
      if(commune){
        GetDelivery(commune?.id, isExpressDelivery, selectedOption);
      }else{
        setDeliveryPrice(0);
        setOrderPrice(0);
      }
    }
    if(selectedOption === 2){
      if(stopDesk){
        GetDelivery(stopDesk.center_commune, false, selectedOption);
      }else{
        setDeliveryPrice(0);
        setOrderPrice(0);
      }
    }
  }, [commune, stopDesk, selectedOption]);

  useEffect(() => {
    if (ProductFinded === "2") {
      setProductList(data.list);
    }
  }, [ProductFinded]);

  useEffect(() => {
    if(GetingDelivery === "1"){
      setLoadingPrice(true);
    }else{
      if (GetingDelivery === "2" && dtStoreinfo?.country?.id === 1) {
        setDeliveryPrice(dataDelivery.delivery_price);
        if(freeDelivery && dataDelivery.min_total_price !== 0) {
          setFreeDelivery(false);
        }
        setSufficientBalance(dataDelivery.min_total_price === 0);
        setSufficientPoints(dataDelivery.current_points > dataDelivery.required_points);
        setOrderPrice(Number(totalPrice) - dataDelivery.delivery_price);
      } else if (GetingDelivery === "2" && dtStoreinfo?.country?.id === 2) {
        setDeliveryPrice(dataDelivery.delivery_price);
        if(freeDelivery && dataDelivery.min_total_price !== 0) {
          setFreeDelivery(false);
        }
        setSufficientBalance(dataDelivery.min_total_price === 0);
        setSufficientPoints(dataDelivery.current_points > dataDelivery.required_points);
        setOrderPrice(
          Number(
            (Number(totalPrice) * 1e12) / 1e12 - dataDelivery.delivery_price
          )
        );
      }
      setLoadingPrice(false);    
    }
  }, [GetingDelivery, dtStoreinfo]);

  useEffect(() => {
    if (
      (OrderAdded === "3" || UpdinunfOrd === "3" || OrderUpdating === "3") &&
      isSubmitting
    ) {
      document.getElementById("Container-root")?.scrollTo(0, 0);
      if (dataAddOrder?.response?.data?.detail)
        setAlertMessage(
          Translate("error", dataAddOrder?.response?.data?.detail)
        );
      if (dataUpInfOrd?.response?.data?.detail)
        setAlertMessage(
          Translate("error", dataUpInfOrd?.response?.data?.detail)
        );
      if (DataUpdateOrder?.response?.data?.detail)
        setAlertMessage(
          Translate("error", DataUpdateOrder?.response?.data?.detail)
        );
      if (dataAddOrder?.response?.data?.duplicated) {
        setAlertMessage(
          Translate("error", "errorMsgDuplicate1") +
            " " +
            "(" +
            customerName +
            "/" +
            customerPhoneNumber +
            ")" +
            " " +
            Translate("error", "errorMsgDuplicate2")
        );
      }
      setIsSubmitting(false);
      setSubmitting(false);
      setError({});
      if(dataAddOrder?.response?.data?.use_points){
        SendAlert("50", dataAddOrder?.response?.data?.use_points[0], ""); 
      }else{
        SendAlert("50", Translate("alert", "failaddorde"), "a02");
      }
    }
    if (OrderAdded === "2" && isSubmitting) {
      GetOrders("");
      SendAlert("41", Translate("alert", "ordadd"), "a01");
      history.push("/orders");
    }
    if (UpdinunfOrd === "2" && isSubmitting) {
      GetOrders("");
      SendAlert("41", Translate("alert", "ordadd"));
      history.push("/unfulfilled/order" + (filterSource ?? ""));
    }
    if (OrderUpdating === "2" && isSubmitting) {
      SendAlert("41", Translate("alert", "orupd"), "a01");
      if (source === "search") history.push("/search" + filterSource);
      else {
        if (filterSource === "?pagination=false") history.push("/orders");
        else history.push("/orders" + filterSource);
      }
    }
  }, [OrderAdded, UpdinunfOrd, OrderUpdating]);

  useEffect(() => {}, [OrderUpdating]);

  useEffect(() => {
    if (externalCommune && Array.isArray(DataCommune)) {
      const commune = DataCommune.find(
        (elem) => elem.id === externalCommune || elem.name === externalCommune
      );
      handleCommuneSelect(commune);
      setExternalCommune(null);
    }
  }, [externalCommune, DataCommune]);

  useEffect(() => {
    if (externalWilaya && GetingWilaya === "1" && Array.isArray(dataWilaya)) {
      const wilaya = dataWilaya.find((wilaya) => wilaya[1] === externalWilaya);
      handleWilayaSelect(wilaya);
      setExternalWilaya(null);
    }
  }, [externalWilaya, GetingWilaya]);

  useEffect(() => {
    if (idorder !== "new") {
      let orderData: any = null;
      let externalTitle, newFilterSource;
      let newCommune: number | string;
      if (source === "order") {
        if (OrderDetailGeting === "2") {
          orderData = OrderDetail.ressuc.list.results[0];
          if (!orderData) history.push("/orders");
          else {
            newFilterSource = OrderDetail.filter;
            newCommune = orderData.commune;
            externalTitle = (
              <div className="StDrc">
                <Link className="StOpcVal" to={"/orders" + newFilterSource}>
                  {Translate("titles", "order")}
                </Link>
                <div className="Inlflx StWdDst">
                  {Translate("orders", "flech")}
                </div>
                <div className="Inlflx">{Translate("orders", "editorder")}</div>
                <div className="Inlflx StWdDst">#</div>
                <div className="Inlflx">{orderData.display_id}</div>
              </div>
            );
          }
        }
      } else if (source === "unfOrder") {
        if (GetinunfOrd === "2") {
          orderData = dataInfOrd.ressuc.results.find(
            (order: any) => order.display_id.toString() === idorder
          );
          if (!orderData) history.push("/unfulfilled/order" + filterSource);
          else {
            newFilterSource = dataInfOrd.filter ?? filterSource;
            newCommune = orderData.commune;
            externalTitle = (
              <div className="StDrc">
                <Link
                  className="StOpcVal"
                  to={"/unfulfilled/order" + newFilterSource}
                >
                  {Translate("titles", "unfulfilledorder")}
                </Link>
                <div className="Inlflx StWdDst">
                  {Translate("orders", "flech")}
                </div>
                <div className="Inlflx">
                  {Translate("orders", "acceptorder")}
                </div>
                <div className="Inlflx StWdDst">#</div>
                <div className="Inlflx">{orderData.display_id}</div>
              </div>
            );
          }
        }
      } else if (source === "search") {
        if (Searching !== "1") {
          history.push("/search");
        } else {
          orderData = DataSearch.ressuc.results.find(
            (order: any) => order.display_id.toString() === idorder
          );
          newFilterSource = DataSearch.filter;
          newCommune = orderData.commune_name;
          externalTitle = (
            <div className="StDrc">
              <Link className="StOpcVal" to={"/search" + newFilterSource}>
                {Translate("titles", "search")}
              </Link>
              <div className="Inlflx StWdDst">
                {Translate("orders", "flech")}
              </div>
              <div className="Inlflx">{Translate("orders", "editorder")}</div>
              <div className="Inlflx StWdDst">#</div>
              <div className="Inlflx">{orderData.display_id}</div>
            </div>
          );
        }
      }
      if (orderData) {
        if(orderData.delivery_type !== 2){
          setExternalWilaya(orderData.wilaya)
          setNoteToDriver(orderData.note_to_driver);
          setIsExpressDelivery(orderData.express);
          setExternalCommune(newCommune!);
          setAddress(
            orderData.destination_text === "Please add the address here"
              ? ""
              : orderData.destination_text
          );
        }
        setOrderId(orderData.id);
        setExternalId(orderData.external_order_id);
        setProducts(orderData.products.filter((product: any, _: any) => !product.is_exchange));
        setReturnProducts(orderData.products.filter((product: any, _: any) => product.is_exchange));
        setCustomerName(orderData.customer_name);
        setCustomerPhoneNumber(orderData.customer_phone);
        setOrderPrice(Number(orderData.product_price));

        setDeliveryPrice(orderData.price ?? 0);
        setTotalPrice(
          orderData.total_price !== undefined && orderData.total_price !== null ?
            orderData.total_price
          :
            ((orderData.price ?? 0) + Number(orderData.product_price)).toFixed(2)
        );
        setSelectedOption(orderData.delivery_type ?? 1);
        setIsNotFound(false);
        setIsNotFoundReturn(false);
        setStatus(orderData.status);
        setPageTitle(externalTitle);
        setButtonTitle(Translate("orders", "save"));
        setFilterSource(newFilterSource);
        setAllowedEditions(
          handleAllowedEditions(
            orderData.status,
            dtStoreinfo.stock_managed,
            orderData.source === "Maystro_Shop" ? true : false
          )
        );
        setIsShopOrder(orderData.source === "Maystro_Shop");
        setPointsDelivery(orderData.use_points);
      }
    } else {
      // If it's a new order to be created, all edition are allowed of course
      setAllowedEditions(handleAllowedEditions(0, false, true));
      setWilaya(null);
      setStopDesk(null);
      setCommune(null);
      setOrderId(null);
      setExternalId("");
      setProducts([INITIAL_PRODUCT]);
      setCustomerName("");
      setCustomerPhoneNumber("");
      setAddress("");
      setOrderPrice(0);
      setNoteToDriver("");
      setDeliveryPrice(0);
      setTotalPrice("0");
      setIsExpressDelivery(false);
      setIsNotFound(false);
      setStatus(0);
      setPageTitle(Translate("orders", "addneworder"));
      setButtonTitle(Translate("sidebar", "addorder"));
      setFilterSource("?page=1");
    }
    Getwilayas(dtStoreinfo?.country?.id);
    GetStopdeskWilayas(dtStoreinfo?.country?.id);
    if (ProductFinded !== "2") {
      GetAllProduct();
    }
  }, [OrderGeting, GetinunfOrd, window.location.href, OrderDetailGeting]);

  useEffect(() => {
    if(
        GetingSDWilayas === "1"
        && OrderDetailGeting === "2"  
    ){
      const orderData = OrderDetail.ressuc.list.results[0];
      const desk = (dataStopDeskWilaya as stopDeskWilayaInterface[]).find((value) => value.name_lt === orderData.wilaya);
      if(desk) setStopDesk(prev => !prev ? desk : prev);
    }
  }, [GetingSDWilayas, OrderDetailGeting])

  useEffect(() => {
    if (wilaya && selectedOption === 1){ 
      Getcommunes("?wilaya=" + wilaya[0]);
      setDeliveryPrice(0);
      if(orderPrice < 0){
        setOrderPrice(0);
      }
    };
  }, [wilaya]);

  const handleChange = (e: React.ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    if (name === "express_delivery") {
      value === "true" ? 
        Event("ADD_ORDER", "ENABLE_EXPRESS_DELIVERY", "CLICK_EVENT") 
      : 
        Event("ADD_ORDER", "DISABLE_EXPRESS_DELIVERY", "CLICK_EVENT");
      
      if (wilaya && whichWilayaHasPermissionToStopDesk(wilaya[0])) {
        GetDelivery(commune?.id, value === "true", selectedOption);
      } else {
        GetDelivery(commune?.id, value === "true", deliveryHome);
      }
      setIsExpressDelivery(value === "true");
    }
    if (name === "orderprice" && dtStoreinfo?.country?.id === 1) {
      const prix = value === "" ? "0" : value.replace(/\D/g, "");
      setOrderPrice(Number(prix) - deliveryPrice);
      setTotalPrice(value === "" ? value : prix);
      if(GetingDelivery === "2" && Number(prix) < dataDelivery.min_total_price && !(pointsDelivery && sufficientPoints)){
        setError(prev => ({ ...prev, price: Translate("error", "code8") }));
      }else{
        if(selectedOption === 1 && Number(prix) > MAX_TOTAL_PRICE_DZ){
          setError(prev => ({ ...prev, price: `${Translate("error", "maxtotalpriceerror")} ${MAX_TOTAL_PRICE_DZ} ${lang === "ar" ? dtStoreinfo?.country?.ar_currency : dtStoreinfo?.country.lt_currency}` }));
        }else{
          setError({});
        }
      }
    } else if (name === "orderprice" && dtStoreinfo?.country?.id === 2) {
      const prix = value === "" ? "0" : value.replace(/[^0-9.]/g, "");
      setOrderPrice(Math.round((Number(prix) - deliveryPrice) * 1e12) / 1e12);
      setTotalPrice(value === "" ? value : prix);
      if(GetingDelivery === "2" && prix < dataDelivery.min_total_price && !(pointsDelivery && sufficientPoints)){
        setError(prev => ({ ...prev, price: Translate("error", "code8") }));
      }else{
        if(selectedOption === 1 && Number(prix) > MAX_TOTAL_PRICE_TN){
          setError(prev => ({ ...prev, price: `${Translate("error", "maxtotalpriceerror")} ${MAX_TOTAL_PRICE_TN} ${lang === "ar" ? dtStoreinfo?.country?.ar_currency : dtStoreinfo?.country.lt_currency}` }));
        }else{
          setError({});
        }
      }
    }
  };

  const handleChangeProduct = (e: React.ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    const tabn = name.split("_");
    const index = parseInt(tabn[1]);
    
    if(tabn[0].startsWith("return")){
      let updatedReturnedProducts = [...returnProducts];
      
      if (tabn[0] !== "returnproductname")
      updatedReturnedProducts[index - 1].quantity = parseInt(value);
      else {
        const theProduct = productList.find(
          (prod) => prod.logistical_description === value
        );
        if (theProduct) {
          updatedReturnedProducts[index - 1].product_id = theProduct.id;
          setIsNotFoundReturn(false);
        } else {
          updatedReturnedProducts[index - 1].product_id = "";
          dtStoreinfo.stock_managed ? setIsNotFoundReturn(true) : setIsNotFoundReturn(false);
        }
        updatedReturnedProducts[index - 1].logistical_description = value;
      }
      setReturnProducts(updatedReturnedProducts);
    }else{
      let updatesProducts = [...products];
  
      if (tabn[0] !== "productname")
        updatesProducts[index - 1].quantity = parseInt(value);
      else {
        const theProduct = productList.find(
          (prod) => prod.logistical_description === value
        );
        if (theProduct) {
          updatesProducts[index - 1].product_id = theProduct.id;
          setIsNotFound(false);
        } else {
          updatesProducts[index - 1].product_id = "";
          dtStoreinfo.stock_managed ? setIsNotFound(true) : setIsNotFound(false);
        }
        updatesProducts[index - 1].logistical_description = value;
      }
      setProducts(updatesProducts);
    };
    }


  const handleDescriptionWithoutProductId = (e: React.ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    let updatesProducts = [...products];
    const tabn = name.split("_");
    const index = parseInt(tabn[1]);

    updatesProducts[index - 1].logistical_description = value;
    updatesProducts[index - 1].product_id = "";
    setProducts(updatesProducts);
    setIsNotFound(false);
  };

  const handleCommuneSelect = (newCommune: CommuneInterface) => {
    if ((wilaya && whichWilayaHasPermissionToStopDesk(wilaya[0])) === false) {
      GetDelivery(newCommune?.id, isExpressDelivery, deliveryHome);
    }
    setCommune(newCommune);
  };

  const handleWilayaSelect = (newWilaya: wilayaInterface) => {
    if (newWilaya && (!wilaya || wilaya[0] !== newWilaya[0])) {
      if (!externalWilaya) setCommune(null);
      setWilaya(newWilaya);
    }
    if (!newWilaya) {
      if (!externalWilaya) setCommune(null);
      setWilaya(null);
    }
  };

  const handleStopDeskSelect = (stopDeskWilaya: stopDeskWilayaInterface) => {
    setStopDesk(stopDeskWilaya);
    GetDelivery(stopDeskWilaya.center_commune, false, selectedOption);
  };

  const AddFieldProduct = (toreturn?: boolean) => {
    Event("ADD_ORDER", "USE_ADD_PRODUCT_BUTTON", "CLICK_EVENT");
    if(toreturn){
      setReturnProducts([
        ...returnProducts,
        { product_id: "", quantity: 1, logistical_description: "", is_exchange: true }
      ])
    }else{
      setProducts([
        ...products,
        { product_id: "", quantity: 1, logistical_description: "" },
      ]);
    }
  };

  const RemoveFieldProduct = (index: number, toreturn?: boolean) => {
    Event("ADD_ORDER", "USE_REMOVE_PRODUCT_BUTTON", "CLICK_EVENT");
    if(toreturn){
      if (returnProducts.length > 1) {
        let updatedReturnedProducts = [...returnProducts];
        updatedReturnedProducts.splice(index, 1);
        setReturnProducts(updatedReturnedProducts);
      }
    }else {
      if (products.length > 1) {
        let updatedProducts = [...products];
        updatedProducts.splice(index, 1);
        setProducts(updatedProducts);
      }
    }
  };


  const handleSubmit = (e: React.FormEvent) => {
    setSubmitting(true);
    e.preventDefault();
    Event("ADD_EXCHANGE", "USE_SUBMIT_BUTTON", "CLICK_EVENT");
    let isError = false;
    let order: OrderSubmit = {},
      newError: ErrorInterface = {};
    
    if(
      products.some((element, index) => 
        products.some(
          (productElement, productIndex) => (
            productElement.logistical_description === element.logistical_description && 
            index !== productIndex
          )
        )
      )
      ||
      returnProducts.some((element, index) => 
        returnProducts.some(
          (productElement, productIndex) => (
            productElement.logistical_description === element.logistical_description && 
            index !== productIndex
          )
        )
      )
    ){
      setError({
        product: Translate("error", "duplicatedproduct"),
      });
      isError = true;
    }

    if(selectedOption === 1){
      if (wilaya && typeof wilaya[0] === "number") {
        order.wilaya = wilaya[0];
      } else {
        newError.wilaya = Translate("error", "eo1");
        isError = true;
      }
      if (commune?.id) {
        order.commune = commune.id;
      } else {
        newError.commune = Translate("error", "eo2");
        isError = true;
      }
      if (address) {
        order.destination_text = address;
      }else{
        order.destination_text = "";
      }
    }else{
      if (stopDesk && typeof stopDesk.code === "number") {
        order.wilaya = stopDesk.code;
      } else {
        isError = true;
      }
      if (stopDesk?.center_commune) {
        order.commune = stopDesk?.center_commune;
      } else {
        isError = true;
      }
      order.destination_text = "";
    }

    if (customerPhoneNumber) {
      order.customer_phone = customerPhoneNumber;
    } else {
      newError.phoneNumber = Translate("error", "eo5");
      isError = true;
    }
    if (customerName) {
      order.customer_name = customerName;
    } else {
      newError.name = Translate("error", "eo4");
      isError = true;
    }

    if (Number(totalPrice) >= 0 && totalPrice !== "" && !error.price){
      if(Number(totalPrice) >= (dataDelivery?.min_total_price ?? 0)){
        if(freeDelivery){
          source !== "unfOrder" ? order.total_price = 0 : order.product_price = 0;
        }else{
          source !== "unfOrder" ? order.total_price = Number(Number(totalPrice).toFixed(2)) : order.product_price = Number(Number(totalPrice).toFixed(2));
        }
      }else{
        if(!(pointsDelivery && sufficientPoints)){
          newError.price = Translate("error", "code8");
          isError = true;
        }else{
          source !== "unfOrder" ? order.total_price = Number(Number(totalPrice).toFixed(2)) : order.product_price = Number(Number(totalPrice).toFixed(2));
        }
      }
    } else {
      if(error.price){
        newError.price = error.price;
      }else{
        newError.price = Translate("error", "eo6");
      }
      isError = true;
    }
    if (externalId) {
      order.external_order_id = externalId;
    }
    if (deliveryPrice) {
      order.price = deliveryPrice;
    }
    if (selectedOption) {
      order.delivery_type = selectedOption;
    }

    if(pointsDelivery){
      order.use_points = true;
    }else{
      order.use_points = false;
    }

    if (isError) {
      Event(
        "ADD_ORDER",
        "ERROR_INFORMATION_ENTER",
        `[${Object.keys(newError).join(",")}]`
      );
      setError(newError);
      return;
    }

    if (!isNotFound && !isNotFoundReturn) {
      setIsSubmitting(true);
      order.source = 1;
      order.express = isExpressDelivery;
      if (status === 50) order.status = 11;
      order.note_to_driver = noteToDriver;
      order.products = [ ...products, ...returnProducts];

      if (hasEditPermission) {
        switch (source) {
          case "unfOrder":
            Event("UPDATE_ORDER", "USE_SUBMIT_BUTTON", "CLICK_EVENT");
            if (isShopOrder)
              Event(
                "UPDATE_ORDER",
                "CONFIRM_SHOP_UNFULFILLED_ORDER",
                "CLICK_EVENT"
              );
            order.status = 1;
            UpdateUnfOrder(order, orderId);
            break;
          case "search":
          case "order":
            Event("UPDATE_ORDER", "USE_SUBMIT_BUTTON", "CLICK_EVENT");
            UpdateOrder(order, orderId);
            break;
          default:
            break;
        }
      }
      if (hasAddPermission && !source) {
        Event("ADD_ORDER", "USE_SUBMIT_BUTTON", "CLICK_EVENT");
        AddOrder(order);
      }
    }
  };

  if (!hasAddPermission && !source) {
    return (
      <ContainerPage page_title={Translate("orders", "addneworder")}>
        <FeatureLoked />
      </ContainerPage>
    );
  }
  if (!hasEditPermission && source) {
    return (
      <ContainerPage page_title={Translate("orders", "editorder")}>
        <FeatureLoked />
      </ContainerPage>
    );
  }

  const isLoading =
    OrderAdded === "1" ||
    OrderUpdating === "1" ||
    UpdinunfOrd === "1" ||
    ProductFinded !== "2" ||
    OrderGeting === "1";

  return (
    <ContainerPage page_title={pageTitle}>
      {isLoading && <Loader />}
      <div className="ClSidTh StBrdTb StBgbrds widthAdd">
        <div className="FlWd stbold TxtCn StRedClr">{alertMessage}</div>
        <form method="post" onSubmit={handleSubmit} autoComplete="off">
          {allowedEditions.edit_products && (
            <ContainerPage
              subContainer
              page_title={Translate("orders", "orderinfo")}
              small_title={true}
            >
              {Array.isArray(products) &&
                products.map((product, key) => {
                  return (
                    <div key={key}>
                      <div className="FlWd InFlx responsFlx flxDrc">
                        <div className="Hlwd">
                          <SelectFormSearch
                            id={"productname_" + (key + 1)}
                            name={"productname_" + (key + 1)}
                            index={"logistical_description"}
                            option={
                              key === 0
                              ?
                                productList
                              :
                                productList.filter((element, _) => {
                                  if(products.some((productElement, _) => productElement.logistical_description === element.logistical_description)){
                                    return false;
                                  }else{
                                    return true;
                                  }
                                })
                            }
                            text={`${Translate("product", "productname")}`}
                            workfun={handleChangeProduct}
                            placeholder={
                              ProductFinded === "2"
                                ? Translate("product", "productname")
                                : Translate("alert", "load")
                            }
                            value={products[key].logistical_description}
                            error={
                              products.some((element, index) => (
                                index !== key &&
                                !(element.is_exchange || products[key].is_exchange) &&
                                element.logistical_description === products[key].logistical_description
                              ))
                              ? Translate("error", "duplicatedproduct")
                              :
                                submitting &&
                                products[key].logistical_description === ""
                                ? Translate("error", "eo3")
                                : ""
                            }
                            stclass={
                              (
                                submitting &&
                                products[key].logistical_description === "" ||
                                products.some((element, index) => (
                                  index !== key &&
                                  !(element.is_exchange || products[key].is_exchange) &&
                                  element.logistical_description === products[key].logistical_description
                                )) 
                              )
                                ? "borderError"
                                : ""
                            }
                          />
                        </div>
                        <div className="Hlwd Mrg2 RlPs">
                          <div className="Flwd InFlx flxDrc AlgnItm">
                            <GroupeForm
                              id={"quantity_" + (key + 1)}
                              name={"quantity_" + (key + 1)}
                              placeholder={Translate("orders", "quantity")}
                              text={Translate("orders", "quantity")}
                              type={"number"}
                              value={products[key].quantity}
                              min={1}
                              workfun={handleChangeProduct}
                              style={{ width: "80%" }}
                              stclass={
                                submitting && !products[key].quantity
                                  ? "borderError"
                                  : ""
                              }
                            />
                            {products.length > 1 ? (
                              <div
                                className="CrsPoi InFlx JstfCnt"
                                style={{
                                  alignItems: "flex-end",
                                  margin: "5% 0% 0% 2%",
                                }}
                                onClick={() => RemoveFieldProduct(key)}
                              >
                                {CloseSvg}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {/* <span>{error?.erproduct}</span> */}
              {isNotFound && (
                <div className="FlWd StdirLn">
                  <span className="CrsPoi">
                    {" " + Translate("product", "prdntfond")}
                  </span>
                  <Link to="/stock/products/add" className="CrsPoi StBle">
                    {" " + Translate("product", "addnewproduct")}
                  </Link>
                </div>
              )}
              <div className="FlWd StdirLn">
                <span className="StBle CrsPoi" onClick={() => AddFieldProduct()}>
                  +{" " + Translate("orders", "addanotherproduct")}
                </span>
              </div>
              {
                returnProducts.length > 0
                &&
                <hr className="FlWd marginTop1 marginBottom1"/>
              }
              {
                returnProducts.length > 0
                &&
                returnProducts.map((product, key) => {
                  return (
                    <div key={key}>
                      <div className="FlWd InFlx responsFlx flxDrc">
                        <div className="Hlwd">
                          <SelectFormSearch
                            id={"returnproductname_" + (key + 1)}
                            name={"returnproductname_" + (key + 1)}
                            index={"logistical_description"}
                            option={
                              key === 0
                              ?
                                productList
                              :
                                productList.filter((element, _) => {
                                  if(returnProducts.some((productElement, _) => productElement.logistical_description === element.logistical_description)){
                                    return false;
                                  }else{
                                    return true;
                                  }
                                })
                            }
                            text={`${Translate("product", "productname")} (${Translate("exchanges", "tobereturned")})`}
                            workfun={handleChangeProduct}
                            placeholder={
                              ProductFinded === "2"
                                ? Translate("product", "productname")
                                : Translate("alert", "load")
                            }
                            value={returnProducts[key].logistical_description}
                            error={
                              returnProducts.some((element, index) => (
                                index !== key &&
                                element.logistical_description === returnProducts[key].logistical_description
                              ))
                              ? Translate("error", "duplicatedproduct")
                              :
                                submitting &&
                                returnProducts[key].logistical_description === ""
                                ? Translate("error", "eo3")
                                : ""
                            }
                            stclass={
                              (
                                submitting &&
                                returnProducts[key].logistical_description === "" ||
                                returnProducts.some((element, index) => (
                                  index !== key &&
                                  element.logistical_description === returnProducts[key].logistical_description
                                )) 
                              )
                                ? "borderError"
                                : ""
                            }
                          />
                        </div>
                        <div className="Hlwd Mrg2 RlPs">
                          <div className="Flwd InFlx flxDrc AlgnItm">
                            <GroupeForm
                              id={"returnquantity_" + (key + 1)}
                              name={"returnquantity_" + (key + 1)}
                              placeholder={Translate("orders", "quantity")}
                              text={Translate("orders", "quantity")}
                              type={"number"}
                              value={returnProducts[key].quantity}
                              min={1}
                              workfun={handleChangeProduct}
                              style={{ width: "80%" }}
                              stclass={
                                submitting && !returnProducts[key].quantity
                                  ? "borderError"
                                  : ""
                              }
                            />
                            {returnProducts.length > 1 ? (
                              <div
                                className="CrsPoi InFlx JstfCnt"
                                style={{
                                  alignItems: "flex-end",
                                  margin: "5% 0% 0% 2%",
                                }}
                                onClick={() => RemoveFieldProduct(key, true)}
                              >
                                {CloseSvg}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              {isNotFoundReturn && (
                <div className="FlWd StdirLn">
                  <span className="CrsPoi">
                    {" " + Translate("product", "prdntfond")}
                  </span>
                  <Link to="/stock/products/add" className="CrsPoi StBle">
                    {" " + Translate("product", "addnewproduct")}
                  </Link>
                </div>
              )}
              {
                returnProducts.length > 0
                &&
                <div className="FlWd StdirLn">
                  <span className="StBle CrsPoi" onClick={() => AddFieldProduct(true)}>
                    +{" " + Translate("orders", "addanotherproducttoreturn")}
                  </span>
                </div>
              }
            </ContainerPage>
          )}
          <ContainerPage subContainer>
            <div className="Hlwd">
              <GroupeForm
                id={"external_id"}
                name={"external_id"}
                placeholder={Translate("orders", "External ID")}
                text={Translate("orders", "External ID (optionnal)")}
                type={"text"}
                value={externalId}
                workfun={(e: React.ChangeEvent) => {
                  const { value } = e.target as HTMLInputElement;
                  setExternalId(value);
                }}
              />
            </div>
          </ContainerPage>
          <ContainerPage
            subContainer
            page_title={Translate("orders", "clientinfo")}
            small_title={true}
          >
            <div className="FlWd InFlx responsFlx flxDrc">
              <div className="Hlwd">
                <GroupeForm
                  id={"customername"}
                  name={"customername"}
                  placeholder={Translate("orders", "customername")}
                  text={Translate("orders", "customername")}
                  type={"text"}
                  value={customerName}
                  workfun={(e: React.ChangeEvent) => {
                    const { value } = e.target as HTMLInputElement;
                    setCustomerName(value);
                  }}
                  error={error.name}
                  stclass={submitting && !customerName ? "borderError" : ""}
                />
              </div>
              <div className="Hlwd Mrg2">
                <GroupeForm
                  id={"customerphonenumber"}
                  name={"customerphonenumber"}
                  placeholder={Translate("orders", "customerphonenumber")}
                  text={Translate("orders", "customerphonenumber")}
                  type={"tel"}
                  pattern="^(((\+216)?[1-9]\d{7})|((0?|(00|\+)?213)[56789]\d{8}))$"
                  value={customerPhoneNumber}
                  workfun={(e: React.ChangeEvent) => {
                    const { value } = e.target as HTMLInputElement;
                    setCustomerPhoneNumber(value);
                    const limitPlusDZ = 13;
                    const limitPlusTN = 12;
                    const limitDZ = 10;
                    const StartNumberPhone = value.substring(0, 4);
                    const StartNumberPhone0 = value.substring(0, 1);

                    if (StartNumberPhone === "+213") {
                      setHideInput(limitPlusDZ);
                    }
                    if (StartNumberPhone === "+216") {
                      setHideInput(limitPlusTN);
                    }
                    if (StartNumberPhone0 === "0") {
                      setHideInput(limitDZ);
                    }
                  }}
                  maxLength={hideInput}
                  error={error.phoneNumber}
                  stclass={
                    submitting &&
                    !checkPhoneNumberValidity(customerPhoneNumber!)
                      ? "borderError"
                      : ""
                  }
                />
              </div>
            </div>
          </ContainerPage>
          
          <ContainerPage
            subContainer
            withOvrl={true}
            page_title={Translate("orders", "deliverydetails")}
            small_title={true}
          >

              <div className="FlWd InFlx responsFlx flxDrc" 
                style={{ height: "100%", marginBottom: "15px" }}
              >
                <div className="Hlwd">
                  
                  {/* stop-desk option toggle */}
                  
                  {
                    dtStoreinfo?.country?.id !== 2
                    &&
                    <>

                      <div className="FlWd InFlx FrInp" 
                        style={{ borderRadius: "50px", marginBottom: "15px" }}
                      >
                        <SwitchOptionButton 
                          optionId={1} 
                          currentOption={selectedOption}
                          onOptionChange={setSelectedOption} 
                        />
                        <SwitchOptionButton 
                          optionId={2} 
                          currentOption={selectedOption}
                          onOptionChange={setSelectedOption} 
                        />
                      </div>

                    </>
                  }
                  

                  { 
                    selectedOption === 2 && dtStoreinfo?.country?.id !== 2 ?
                      <div className="FlWd" style={{ marginBottom: "15px" }}>
                      
                        <Select
                          label={Translate("orders", "stpdsk")}
                          placholder={Translate("orders", "stpdsk")}
                          search={true}
                          Options={
                            dataStopDeskWilaya
                            ?
                              dataStopDeskWilaya
                            :
                              []
                          }
                          fieldShow={lang === "ar" ? "name_ar" : "name_lt"}
                          name="stopdesk"
                          loading={GetingSDWilayas === "0"}
                          value={stopDesk}
                          onChange={
                            allowedEditions.edit_delivery_infos
                            ?
                              handleStopDeskSelect
                            :
                              () => null
                          }
                          maxSize="200px"
                          readOnly={!allowedEditions.edit_delivery_infos}
                          disabled={true}
                          containerClass={
                            submitting && !stopDesk && selectedOption === 2 ? "borderError" : ""
                          }
                        />
                      
                      </div>
                    :
                      <>
                        <GroupeForm
                          id={"adresse"}
                          name={"adresse"}
                          placeholder={Translate("orders", "adresse")}
                          text={Translate("orders", "adresse")}
                          type={"text"}
                          value={address}
                          workfun={(e: React.ChangeEvent) => {
                            const { value } = e.target as HTMLInputElement;
                            setAddress(value);
                          }}
                          error={error.address}
                        />
                        <div
                          className="FlWd InFlx responsFlx flxDrc"
                          style={{ margin: "28px 0 28px 0" }}
                        >
                          <div className="Hlwd">
                            <Select
                              label={Translate("orders", "city")}
                              placholder={Translate("orders", "citySearch")}
                              search={true}
                              Options={dataWilaya}
                              fieldShow={1}
                              name="city"
                              loading={GetingWilaya === "0"}
                              value={wilaya}
                              onChange={
                                allowedEditions.edit_delivery_infos
                                  ? handleWilayaSelect
                                  : () => null
                              }
                              maxSize="200px"
                              readOnly={!allowedEditions.edit_delivery_infos}
                              disabled={true}
                              containerClass={
                                submitting && !wilaya && selectedOption === 1 ? "borderError" : ""
                              }
                            />
                          </div>
                          <div className="Hlwd Mrg2">
                            <Select
                              label={Translate("orders", "district")}
                              placholder={Translate("orders", "districtSearch")}
                              search={true}
                              Options={DataCommune}
                              fieldShow={"name"}
                              name="district"
                              loading={GetigCommune === "0"}
                              value={commune}
                              onChange={handleCommuneSelect}
                              maxSize="200px"
                              containerClass={
                                submitting && !commune && selectedOption === 1 ? "borderError" : ""
                              }
                            />
                          </div>
                        </div>
                      </> 
                  }
                </div>
                
                <div className="Hlwd Mrg2">
                  {
                    selectedOption === 1
                    &&
                    <GroupeForm
                      id={"notetodriver"}
                      name={"notetodriver"}
                      placeholder={Translate("orders", "notetodriver")}
                      text={Translate("orders", "notetodriver")}
                      type={"text"}
                      value={noteToDriver}
                      workfun={(e: React.ChangeEvent) => {
                        const { value } = e.target as HTMLInputElement;
                        setNoteToDriver(value);
                      }}
                      textarea={true}
                    />    
                  }
                  <div className={`InFlx Stclmnf FlWd ${sufficientBalance && !freeDelivery ? "" : "notAllowed"}`}>
                    <div className="InFlx AlgnItm">
                      <CheckBoxForm
                        id="free-delivery-checkbox"
                        name="free_delivery"
                        check={freeDelivery && sufficientBalance}
                        workfun={() => {
                          setFreeDelivery(prev => {
                            if(!prev) setPointsDelivery(false);
                            return !prev; 
                          });
                        }}
                        disabled={!sufficientBalance && freeDelivery}
                      />
                      <div className="InFlx AlgnItm">
                        <span style={{ marginTop: "3px" }} > {deliveryTruck} </span> 
                        <span className="StWdDst"> {Translate("orders","freedelivery")} </span>
                      </div>
                    </div>
                    { 
                      freeDelivery && sufficientBalance
                      &&
                      <p className="DlMg StSmlS FlWd text-gray">
                        {Translate("orders", "freedeliverysuccess")} 
                      </p>  
                    }
                    {
                      freeDelivery && !sufficientBalance
                      &&
                      <p className="DlMg StSmlS FlWd">
                        {Translate("orders", "freedeliveryinsufficient")} 
                      </p>
                    }
                  </div>

                  <div className={`InFlx Stclmnf FlWd ${sufficientPoints && !pointsDelivery ? "" : "notAllowed"}`}>
                    <div className="InFlx AlgnItm">
                      <CheckBoxForm
                        id="points-delivery-checkbox"
                        name="points_delivery"
                        check={pointsDelivery && sufficientPoints}
                        workfun={() => {
                          Event("ADD_ORDER", "USE_POINTS_CHECKBOX", "CLICK_EVENT");
                          setPointsDelivery(prev => {
                            if(!prev) setFreeDelivery(false);
                            return !prev;
                          });
                        }}
                        disabled={!sufficientPoints && pointsDelivery}
                      />
                      <div className="InFlx AlgnItm">
                        <span style={{ marginTop: "3px" }} >{TowCoinsSvg} </span> 
                        <span className="StWdDst">{Translate("orders", "ponitsdelivery")}</span>
                      </div>
                    </div>
                  </div>
                  {
                    pointsDelivery
                    &&
                    <div className="InFlx" style={{ gap: "8px" }}>
                      <div className="InFlx FrInp AlgnItm" style={{ padding: "2px 8px", borderRadius: "90px", gap: "6px" }}>
                        <span>{Translate("orders", "cost")}: </span> <span className="InFlx AlgnItm">{SmallCoinSvG}</span> <span>{(dataDelivery?.required_points ?? 0)}</span>
                      </div>
                      <div className="InFlx FrInp AlgnItm" style={{ padding: "2px 8px", borderRadius: "90px", gap: "6px" }}>
                        <span>{Translate("orders", "your_points")}: </span> <span className="InFlx AlgnItm">{SmallCoinSvG}</span> <span>{(dataDelivery?.current_points ?? 0)}</span>
                      </div>
                    </div>
                  }
                  {
                    pointsDelivery && !sufficientPoints
                    &&
                    <p className="DlMg StSmlS FlWd" style={{ marginTop: "5px" }}>
                      {Translate("orders", "insufficient_points")} 
                    </p>
                  }
                </div>
              </div>
    
            <div
              className="FlWd InFlx responsFlx flxDrc"
              style={{ height: "100%" }}
            >
              <div className="Hlwd">
                <GroupeForm
                  id={"orderprice"}
                  name={"orderprice"}
                  placeholder={Translate("orders", "totalprice")}
                  text={Translate("orders", "totalprice")}
                  type={"text"}
                  value={totalPrice}
                  workfun={handleChange}
                  error={error.price}
                  stclass={(submitting && !totalPrice || error.price) ? "borderError" : ""}
                />
              </div>
              <div className="Hlwd Mrg2">
              {
                wilaya && wilaya[0] === 16 && selectedOption === 1
                &&
                <SelectForm
                  id={"expressdelivery"}
                  name={"express_delivery"}
                  option={[
                    { text: Translate("orders", "no"), val: false },
                    { text: Translate("orders", "yes"), val: true },
                  ]}
                  value={isExpressDelivery}
                  text={Translate("orders", "expressdelivery")}
                  workfun={handleChange}
                />
              }
              </div>
            </div>
          </ContainerPage>
          <span
            className="DsBlc FlWd"
            style={{
              height: "1px",
              backgroundColor: "var(--fntClr)",
              opacity: ".2",
            }}
          ></span>
          <div className="FlWd TxtCn StRedClr">
            {commune &&
              !commune.id &&
              commune.name &&
              Translate("error", "Order out of our service")}
          </div>
          <div className="InFlx StMarMx stbold StFlxStr">
            <div className="InFlx Stclmnf">
              <span className="stfSize"> {Translate("orders", "customerwillpay")} </span>
              <span className="stfSize"> {Translate("orders", "wewillget")} </span>
              <span className="lgfSize">{Translate("orders", "youwillget")}</span>
            </div>
            <div className="InFlx Stclmnf min-width align-items-end">
              {
                loadingPrice
                ?
                <span className="Mrg2 stfSize">
                  {Translate("alert", "load")}
                </span>
                :
                <span className="Mrg2 stfSize">
                  {
                    lang == "ar"
                    ?
                      dtStoreinfo?.country?.id === 2
                      ?
                        freeDelivery && sufficientBalance
                        ?
                          "0" + "   " + dtStoreinfo.country.ar_currency
                          :
                          Number(totalPrice).toFixed(2) + "   " + dtStoreinfo.country.ar_currency  
                        :
                        freeDelivery && sufficientBalance
                        ?
                          "0" + "   " + dtStoreinfo.country.ar_currency
                          :
                          Number(totalPrice) + "   " + dtStoreinfo.country.ar_currency  
                    : 
                      dtStoreinfo?.country?.id === 2
                      ?
                      freeDelivery && sufficientBalance
                        ?
                          "0" + "   " + dtStoreinfo.country.lt_currency
                        :
                          Number(totalPrice).toFixed(2) + "   " + dtStoreinfo.country.lt_currency
                      :
                      freeDelivery && sufficientBalance
                        ?
                          "0" + "   " + dtStoreinfo.country.lt_currency
                        :
                          Number(totalPrice) + "   " + dtStoreinfo.country.lt_currency
                  }
                </span>
              }
              {
                loadingPrice
                ?
                <span className="Mrg2 stfSize">
                  {Translate("alert", "load")}
                </span>
                :
                <span className="Mrg2 stfSize">
                  {
                    pointsDelivery && sufficientPoints
                    ?
                      <div className="InFlx AlgnItm" style={{ gap: "8px" }}>
                        <span style={{ marginTop: "2px" }}>{SmallCoinSvG}</span> 
                        <span>{(dataDelivery?.required_points ?? 0)}</span>
                      </div>
                    :
                      lang == "ar"
                      ?
                        deliveryPrice + "   " + dtStoreinfo.country.ar_currency
                      : 
                        deliveryPrice + "   " + dtStoreinfo.country.lt_currency
                  }
                </span>
              }
              <span className={`
                Mrg2 lgfSize ${
                  orderPrice >= 0 && !freeDelivery 
                  ?
                    "Bgstatus_01"
                  :
                    freeDelivery && sufficientBalance
                    ? 
                      "Bgstatus_50" 
                    : 
                      orderPrice < 0
                      ?
                        pointsDelivery && sufficientPoints
                        ?
                          "Bgstatus_01"
                        :
                          "Bgstatus_50"
                      :
                        "Bgstatus_01"
                }
              `}>
                {
                  lang == "ar"
                  ?
                    dtStoreinfo?.country?.id === 2
                    ?
                      freeDelivery && sufficientBalance
                      ?
                        (-deliveryPrice) + "   " + dtStoreinfo.country.ar_currency
                      :
                        pointsDelivery && sufficientPoints
                        ?
                          Number(totalPrice).toFixed(2) + "   " + dtStoreinfo.country.ar_currency
                        :
                          orderPrice.toFixed(2) + "   " + dtStoreinfo.country.ar_currency
                    :
                      freeDelivery && sufficientBalance
                      ?
                        (-deliveryPrice) + "   " + dtStoreinfo.country.ar_currency
                      :
                        pointsDelivery && sufficientPoints
                          ?
                            Number(totalPrice) + "   " + dtStoreinfo.country.ar_currency
                          :
                            orderPrice.toFixed(2) + "   " + dtStoreinfo.country.ar_currency
                  :
                    dtStoreinfo?.country?.id === 2
                    ?
                      freeDelivery && sufficientBalance
                      ?
                        (-deliveryPrice) + "   " + dtStoreinfo.country.lt_currency
                      :
                        pointsDelivery && sufficientPoints
                        ?
                          Number(totalPrice).toFixed(2) + "   " + dtStoreinfo.country.lt_currency
                        :
                          orderPrice.toFixed(2) + "   " + dtStoreinfo.country.lt_currency
                    :
                      freeDelivery && sufficientBalance
                      ?
                        (-deliveryPrice) + "   " + dtStoreinfo.country.lt_currency
                      :
                        pointsDelivery && sufficientPoints
                        ?
                          Number(totalPrice) + "   " + dtStoreinfo.country.lt_currency
                        :
                          orderPrice + "   " + dtStoreinfo.country.lt_currency 
                }
              </span>
            </div> 
          </div>

          <div className="MrAot StMarMx responseDiv" style={{ width: "25%" }}>
            {
              loadingPrice
              ?
              <Button gray disabled BtnText={Translate("alert", "load")}/>
              :
                Number(totalPrice) < 0
                ?
                  <Button danger disabled BtnText={Translate("error", "eo6")} />
                :
                  <Button BtnText={buttonTitle} />
            }
            
          </div>
        </form>
      </div>
    </ContainerPage>
  );
};
function mapState(state: any) {
  const {
    GetingWilaya,
    dataWilaya,
    dataStopDeskWilaya,
    GetingSDWilayas,
    GetigCommune,
    DataCommune,
    GetingDelivery,
    dataDelivery,
    dataCountry,
    GetingPays,
  } = state.common;
  const { ProductFinded, data } = state.product;
  const {
    OrderAdded,
    dataAddOrder,
    OrderGeting,
    OrdersList,
    OrderUpdating,
    DataUpdateOrder,
    OrderDetailGeting,
    OrderDetail,
  } = state.orders;
  const { GetinunfOrd, dataInfOrd, UpdinunfOrd, dataUpInfOrd } =
    state.unfOrders;
  const { DataSearch, Searching } = state.search;
  const { dataProfile, dtStoreinfo } = state.user;
  return {
    GetingWilaya,
    GetingSDWilayas,
    dataStopDeskWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    data,
    ProductFinded,
    OrderAdded,
    dataAddOrder,
    GetingDelivery,
    dataDelivery,
    OrderGeting,
    OrdersList,
    OrderUpdating,
    DataUpdateOrder,
    GetinunfOrd,
    dataInfOrd,
    UpdinunfOrd,
    dataUpInfOrd,
    DataSearch,
    Searching,
    dataProfile,
    dtStoreinfo,
    dataCountry,
    GetingPays,
    OrderDetailGeting,
    OrderDetail,
  };
}

const actionCreators = {
  Getwilayas: commonActions.Getwilayas,
  Getcommunes: commonActions.Getcommunes,
  GetAllProduct: productActions.GetAllProduct,
  GetDelivery: commonActions.GetDelivery,
  AddOrder: ordersActions.AddOrder,
  UpdateOrder: ordersActions.UpdateOrder,
  GetOrders: ordersActions.GetOrders,
  GetAllUnfOrders: ordersActions.GetAllUnfOrders,
  UpdateUnfOrder: ordersActions.UpdateUnfOrder,
  SendAlert: alertActions.SendAlert,
  ClearStore: commonActions.ClearStore,
  GetCountry: commonActions.GetCountry,
  GetOrderDetail: ordersActions.GetOrderDetail,
  GetStopdeskWilayas: commonActions.GetStopDeskWilayas
};

const connectedAddOrderPage = connect(mapState, actionCreators)(AddOrderPage);
export { connectedAddOrderPage as AddOrderPage };
