import React, { useRef, useEffect } from "react";

import { 
  Chart, 
  ChartOptions, 
  ChartData, 
  Plugin
} from 'chart.js';

import { Doughnut } from 'react-chartjs-2';
import { AnyObject } from "chart.js/types/basic";
import Cookies from "js-cookie";
import { Translate } from "../../../utils/lang/translate";


interface DeliveryRateChartProps {
  deliveryRate: number,
}

const DeliveryRateChart = ({ deliveryRate }: DeliveryRateChartProps) => {
  const options: ChartOptions<"doughnut"> = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false
      },
    },
    circumference: 180,
    rotation: -90,
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    cutout: '80%',
  };

  const data: ChartData<"doughnut"> = {
    labels: [
      'Canceled',
      'Delivered'
    ],
    datasets: [{
      data: [deliveryRate, 100 - deliveryRate],
      backgroundColor: ['#36D17E', '#3C4352'],
      borderWidth: 0,
      borderRadius: 2,
      spacing: 6,
    }]
  };

  const textCenter: Plugin<"doughnut", AnyObject> = {
    id: "doughnut-center-text",
    beforeDatasetDraw: function (chart, _, __) {
      const { ctx, data } = chart;
      const theme = Cookies.get("theme");
      ctx.save();
      ctx.font = "bold 32px Nunito Sans";
      ctx.fillStyle = theme === "0" ? "#000" : "#fff";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        deliveryRate.toString() + "%", 
        chart.getDatasetMeta(0).data[0].x, 
        chart.getDatasetMeta(0).data[0].y - 40 
      ); 
      ctx.restore();
      ctx.save();
      ctx.font = "18px Nunito Sans";
      ctx.fillStyle = "#adb1b8";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        Translate("dashboard", "cancelrate"), 
        chart.getDatasetMeta(0).data[0].x, 
        chart.getDatasetMeta(0).data[0].y - 10 
      );
    }
  };

  const doughnutRef = useRef<Chart<"doughnut">>();

  useEffect(() => {
    const cb = () => {
      doughnutRef.current?.update()
    }
    window.addEventListener('themeChanged', cb)
    return () => window.removeEventListener('themeChanged', cb)
  }, [doughnutRef.current]);

  return (
    <div className="dashboard-delivery-rate-chart-wrapper">
      <Doughnut ref={doughnutRef} data={data} options={options} plugins={[textCenter]} />
    </div>
  );
}
 
export default DeliveryRateChart;