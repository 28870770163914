import {
    alertedOrderSvg, 
    blueRightChevronSvg, 
    exchangeToBePrintedSvg, 
    greenRighChevronSvg, 
    orangeRightChevronSvg, 
    productOutofStockSvg, 
    readyToBeReturnedOrderSvg, 
    unfullfiledOrderSvg 
} from "../components/StorePages/_Common/IconSvg";
import { Translate } from "../utils/lang/translate";

export const dashboardConstants = {
  GET_DASHBOARD_NOTIFICATIONS_REQUEST: "GET_DASHBOARD_NOTIFICATIONS_REQUEST",
  GET_DASHBOARD_NOTIFICATIONS_SUCCESS: "GET_DASHBOARD_NOTIFICATIONS_SUCCESS",
  GET_DASHBOARD_NOTIFICATIONS_FAILURE: "GET_DASHBOARD_NOTIFICATIONS_FAILURE",
  
  GET_DASHBOARD_MAIN_STATS_REQUEST: "GET_DASHBOARD_MAIN_STATS_REQUEST",
  GET_DASHBOARD_MAIN_STATS_SUCCESS: "GET_DASHBOARD_MAIN_STATS_SUCCESS",
  GET_DASHBOARD_MAIN_STATS_FAILURE: "GET_DASHBOARD_MAIN_STATS_FAILURE",

  GET_TOP_PRODUCTS_REQUEST: "GET_TOP_PRODUCTS_REQUEST",
  GET_TOP_PRODUCTS_SUCCESS: "GET_TOP_PRODUCTS_SUCCESS",
  GET_TOP_PRODUCTS_FAILURE: "GET_TOP_PRODUCTS_FAILURE",

  GET_PERFORMANCE_REQUEST: "GET_PERFORMANCE_REQUEST",
  GET_PERFORMANCE_SUCCESS: "GET_PERFORMANCE_SUCCESS",
  GET_PERFORMANCE_FAILURE: "GET_PERFORMANCE_FAILURE",

  GET_DELIVERED_ORDERS_PER_DAY_REQUEST: "GET_DELIVERED_ORDERS_PER_DAY_REQUEST",
  GET_DELIVERED_ORDERS_PER_DAY_SUCCESS: "GET_DELIVERED_ORDERS_PER_DAY_SUCCESS",
  GET_DELIVERED_ORDERS_PER_DAY_FAILURE: "GET_DELIVERED_ORDERS_PER_DAY_FAILURE",

  GET_CONFIRMED_ORDERS_REQUEST: "GET_CONFIRMED_ORDERS_REQUEST",
  GET_CONFIRMED_ORDERS_SUCCESS: "GET_CONFIRMED_ORDERS_SUCCESS",
  GET_CONFIRMED_ORDERS_FAILURE: "GET_CONFIRMED_ORDERS_FAILURE"

};

export const notificationCardStyles = {
    "unfulfilled_orders_count": {
      styleClass: "dashboard-unfulfilled-orders-notification",
      svgIcon: unfullfiledOrderSvg,
      iconBackgroundColor: "#069572",
      chevronIcon: greenRighChevronSvg,
      text: Translate("dashboard", "unfullfilledorders"),
      redirectTo: "/unfulfilled/order"
    },
    "orders_ready_to_be_returned_count": {
      styleClass: "dashboard-primary-notification",
      svgIcon: readyToBeReturnedOrderSvg,
      iconBackgroundColor: "#1D97FE",
      chevronIcon: blueRightChevronSvg,
      text: Translate("dashboard", "readytobereturned"),
      redirectTo: "/orders?status=51"
    },
    "orders_out_of_stock_count": {
      styleClass: "dashboard-primary-notification",
      svgIcon: productOutofStockSvg,
      iconBackgroundColor: "#1D97FE",
      chevronIcon: blueRightChevronSvg,
      text: Translate("dashboard", "outofstockorders"),
      redirectTo: "/orders?status=12"
    },
    "unprinted_exchanges_count": {
      styleClass: "dashboard-primary-notification",
      svgIcon: exchangeToBePrintedSvg,
      iconBackgroundColor: "#1D97FE",
      chevronIcon: blueRightChevronSvg,
      text: Translate("dashboard", "unprintedexchanges"),
      redirectTo: "/exchanges"
    },
    "orders_alerted_count": {
      styleClass: "dashboard-alerted-orders-notification",
      svgIcon: alertedOrderSvg,
      iconBackgroundColor: "#E77800",
      chevronIcon: orangeRightChevronSvg,
      text: Translate("dashboard", "alertedorders"),
      redirectTo: "/orders?status=32"
    },
  };