import { rewardsConstants } from "../constants/rewards.constants";
import { RequestService, FunctionService } from "../services";

import Cookies from "js-cookie";
const token = Cookies.get("auth");

export const rewardsActions = {
  GetRewards,
  GetTiersData,
  UpdateAutoSpendPoints
};

function GetRewards(){
  return (dispatch) => {
    dispatch(FunctionService.request(rewardsConstants.GET_REWARDS_REQUEST));
    RequestService.GetRequest("fidelity/rewards/", {
      Authorization: "Token " + token,
    })
    .then((res) => {
      dispatch(FunctionService.succes(res.data, rewardsConstants.GET_REWARDS_SUCCESS));
    })
    .catch((err) => {
      dispatch(FunctionService.succes(err, rewardsConstants.GET_REWARDS_FAILURE));
    });
  }
};

function GetTiersData(){
  return (dispatch) => {
    dispatch(FunctionService.request(rewardsConstants.GET_ALL_TIERS_REQUEST));
    RequestService.GetRequest("fidelity/tiers/", {
      Authorization: "Token " + token,
    })
    .then((res) => {
      dispatch(FunctionService.succes(res.data.results, rewardsConstants.GET_ALL_TIERS_SUCCESS));
    })
    .catch((err) => {
      dispatch(FunctionService.failure(err, rewardsConstants.GET_ALL_TIERS_FAILURE));
    });
  }
};


function UpdateAutoSpendPoints(value) {
  return (dispatch) => {
    dispatch(FunctionService.request(rewardsConstants.UPDATE_AUTO_SPEND_POINTS_REQUEST));
    RequestService.PatchRequest("fidelity/rewards/", {
      auto_spend_points: value
    },{
      Authorization: "Token " + token,
    })
    .then((res) => {
      dispatch(FunctionService.succes(res.data, rewardsConstants.UPDATE_AUTO_SPEND_POINTS_SUCCESS));
    })
    .catch((err) => {
      dispatch(FunctionService.failure(err, rewardsConstants.UPDATE_AUTO_SPEND_POINTS_FAILURE));
    })
  }
};