import React from "react";

interface CustomLinkProps {
  text: string,
  fontSize?: number | string,
  color?: string,
  onClick: () => void  
}

const CustomLink = ({
  text,
  fontSize,
  color,
  onClick
}: CustomLinkProps) => {
  return (
    <div
      onClick={onClick} 
      className="CrsPoi"
      style={{ 
        fontSize: fontSize ? fontSize : "20px", 
        color: color ? color: "#008AFF",
        fontWeight: 700,
        textDecoration: "underline"         
      }}
    >
      {text}
    </div>
  );
}
 
export default CustomLink;