import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { commonActions, userActions } from "../../../actions";
import GroupeForm from "../../_Common/GroupeForm";
import SelectForm from "../../_Common/SelectForm";
import HeadLog from "../_Common/HeadLog";
import ContainerLog from "../_Common/ContainerLog";
import Button from "../../_Common/Button";
import { Translate } from "../../../utils/lang/translate";
import Loader from "../../_Common/Loader";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import { Event, initGAPg } from "../../StorePages/_Common";
import SelectFormvWilaya from "../../_Common/SelectWiForm";
import SelectFormvCommune from "../../_Common/SelectFormComm";

type StoreFormData = {
  name: string;
  address: string;
  country_id: string;
  wilaya: string;
  commune: string;
  country: string;
  stock_managed: boolean;
  submited: boolean;
  errors: any;
};

type Props = {
  user: {
    full_name: string;
    phone: string;
    user_name: string;
    email: string;
    password: string;
    confirmed_password: string;
  };
  history: {
    push: (path: string) => void;
  };
  checkingstorename: string;
  storenamedata: {
    exists: boolean;
  };
  Getwilayas: (countryId: string) => void;
  Getcommunes: (query: string) => void;
  GetCountry: () => void;
  CheckStoreNameRegister: (name: string) => void;
  Register: (data: Record<string, any>) => void;
  Sended: string;
  registered: string;
  dataCountry: Record<string, any>[];
  dataWilaya: Record<string, any>[];
  DataCommune: Record<string, any>[];
};
interface ErrorInterface {
  full_name?: string;
  phone?: any;
  user_name?: string;
  email?: any;
  password?: string;
  confirmed_password?: string;
  terms_use?: any;
  privacy_police?: any;
}

const AddStore = ({
  user,
  history,
  checkingstorename,
  storenamedata,
  Getwilayas,
  Getcommunes,
  GetCountry,
  CheckStoreNameRegister,
  Register,
  Sended,
  registered,
  dataCountry,
  dataWilaya,
  DataCommune,
}: any) => {
  const [formData, setFormData] = useState<StoreFormData>({
    name: "",
    address: "adresse",
    country_id: "",
    wilaya: "",
    commune: "",
    country: "",
    stock_managed: true,
    submited: false,
    errors: {},
  });

  useEffect(() => {
    document.title = "Maystro Delivrey - " + Translate("titles", "createstore");
    if (!user && !Cookies.get("temp-user-infos")) {
      history.push("/register");
    }
    if (user?.phone?.substr(0, 4) === "+213") {
      Getwilayas("1");
    } else if (user?.phone?.substr(0, 4) === "+216") {
      Getwilayas("2");
    }

    initGAPg();
    GetCountry();

    if (user?.phone?.substr(0, 4) === "+213") {
      setFormData((prev) => ({ ...prev, country_id: "1" }));
    } else if (user?.phone?.substr(0, 4) === "+216") {
      setFormData((prev) => ({ ...prev, country_id: "2" }));
    }
  }, [history, user, Getwilayas, GetCountry]);

  useEffect(() => {
    if (formData.wilaya) {
      Getcommunes(`?wilaya=${formData.wilaya}`);
    }
  }, [formData.wilaya, Getcommunes]);

  useEffect(() => {
    if (user) {
      const {
        full_name,
        phone,
        user_name,
        email,
        password,
        confirmed_password,
      } = user;
      const { name, address, stock_managed, country_id, wilaya, commune } =
        formData;
      if (
        (storenamedata?.exists === undefined ||
          storenamedata?.exists === false) &&
        checkingstorename === "1"
      ) {
        Register({
          full_name,
          phone,
          user_name,
          email,
          password,
          confirmed_password,
          name,
          address,
          stock_managed,
          country_id,
          wilaya,
          commune,
        });
      }
    }
  }, [user, storenamedata, checkingstorename, Register]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeV2 = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({ ...formData, country_id: e.target.value });
  };

  const handleChangeV3 = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({ ...formData, wilaya: e.target.value });
  };

  const handleChangeV4 = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({ ...formData, commune: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { full_name, phone, user_name, email, password, confirmed_password } =
      user;
    const { name, address, stock_managed, country_id, wilaya, commune } =
      formData;

    Event("REGISTER", "SUBMIT_STORE_INFOS", "CLICK_EVENT");
    let valid = true,
      err: ErrorInterface = {};
    ["name", "address", "stock_managed"].forEach((el) => {
      if (!formData[el as keyof StoreFormData]) {
        err[el as keyof ErrorInterface] = Translate("error", "reqfield");
        valid = false;
      }
    });
    setFormData({ ...formData, errors: err });
    if (!valid) {
      Event(
        "REGISTER",
        "SUBMIT_STORE_INFOS_ERRORS",
        `[${Object.keys(err).join(",")}]`
      );
      return;
    }
    CheckStoreNameRegister(name);
  };

  const goToJoinStore = (e: React.MouseEvent<HTMLAnchorElement>) => {
    Event("REGISTER", "USE_JOIN_STORE_LINK", "CLICK_EVENT");
  };

  if (Sended !== "0" && Sended !== "1") {
    // return <Redirect to="/register"/>
  }
  if (registered === "2") {
    return <Redirect to="/register" />;
  }
  if (registered === "1") {
    return <Redirect to="/succes/register" />;
  }
  return (
    <ContainerLog>
      <HeadLog
        titleHead={Translate("auths", "store")}
        descHead={Translate("auths", "storetext")}
      />
      <form action="post" onSubmit={handleSubmit} className="StMarMx">
        <GroupeForm
          id={"store_name"}
          name={"name"}
          placeholder={Translate("auths", "storename")}
          text={Translate("auths", "storename")}
          type={"text"}
          value={formData.name}
          workfun={handleChange}
        />
        {storenamedata?.exists === true && (
          <p className="StRedClr TxtLeft DlMg DlPg">
            {" "}
            {Translate("auths", "storenameValidation")}
          </p>
        )}
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "100%", marginRight: "10px" }}>
            <SelectFormvWilaya
              id={"store_location"}
              name={"address"}
              text={Translate("auths", "storelocation")}
              options={dataWilaya}
              valuee={formData.wilaya}
              onChange={handleChangeV3}
            />
          </div>
          <div style={{ width: "100%", marginTop: "23px" }}>
            <SelectFormvCommune
              text={""}
              options={DataCommune}
              valuee={formData.commune}
              onChange={handleChangeV4}
            />
          </div>
        </div>
        <SelectForm
          id={"stock_management"}
          name={"stock_managed"}
          option={[
            { text: Translate("auths", "managedby"), val: true },
            { text: Translate("auths", "managedbyme"), val: false },
          ]}
          text={Translate("auths", "stockmanagement")}
          workfun={handleChange}
        />
        <div className="SmtGrp MrAot StMarMx">
          <Button BtnText={Translate("auths", "finish")} />
        </div>
      </form>
      {(registered === "3" || checkingstorename === "0") && (
        <Loader styl={{ borderRadius: "24px" }} />
      )}
    </ContainerLog>
  );
};

function mapState(state: any) {
  const { Sended, user, data, registered } = state.registration;
  const { checkingstorename, storenamedata } = state.user;
  const {
    dataCountry,
    GetingPays,
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
  } = state.common;
  return {
    Sended,
    user,
    data,
    registered,
    dataCountry,
    GetingPays,
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    checkingstorename,
    storenamedata,
  };
}

const actionCreators = {
  Register: userActions.Register,
  CheckStoreNameRegister: userActions.CheckStoreNameRegister,
  GetCountry: commonActions.GetCountry,
  Getwilayas: commonActions.Getwilayas,
  Getcommunes: commonActions.Getcommunes,
};

const connectedLoginPage = connect(mapState, actionCreators)(AddStore);
export { connectedLoginPage as AddStore };
