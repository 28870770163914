import React, { Component } from "react";
import { connect } from "react-redux";
import { paymentActions, commonActions, alertActions } from "../../../actions";
import { officeConstants } from "../../../constants";
import ElementFacture from "./utilsPayment/ElementFacture";
import { Translate, FormatCurrency, FormatDate } from "../../../utils/lang/translate";
import { PrintSvg, ExportSvg } from "../_Common/IconSvg";
import Loader from "../../_Common/Loader";
import ContainerPage from "../_Common/ContainerPage";
import TableLoader from "../_Common/TableLoader";
import { Redirect, Link } from "react-router-dom";
import Cookies from "js-cookie";
import { Event, initGAPg } from "../_Common";
import IconButton from "../_Common/IconButton";

class FacturePayment extends Component {
  constructor(props) {
    super(props);
    
    const {
      match: { params },
    } = this.props;

    this.DateInvoice = new Date();
    if (this.props.GetingFacture !== "1") {
      if (params.action === "all") {
        this.payment_all = true;
        this.props.GetFacture("");
      } else {
        if (this.props.ViewPay === "1") {
          let payment = this.props.ViewPaydata.payment.slice(0).reverse();
          let elempay = payment[parseInt(params.action) - 1000];
          this.DateInvoice = elempay.payment_date;
          this.props.GetFacture("&payment_id=" + elempay.id);
        } else if (window.ViewPay === "1") {
          let payment = window.ViewPaydata.payment.slice(0).reverse();
          let elempay = payment[parseInt(params.action) - 1000];
          this.DateInvoice = elempay.payment_date;
          this.props.GetFacture("&payment_id=" + elempay.id);
        } else {
          this.props.history.push("/payments");
        }
      }
    }
    this.lang = Cookies.get("lang");
    this.printDocument = this.printDocument.bind(this);
  }
  componentDidMount() {
    document.title = "Maystro Delivery - " + Translate("titles", "payements");
    initGAPg();
    this.props.GetInfoMaystro(this.props?.dtStoreinfo?.country?.id);
  }

  componentWillUnmount() {
    this.props.ClearStore("CLEAR_FACTURE");
  }
  printDocument() {
    Event("PAYEMENT_BILL", "USE_VIEW_PRINT_BUTTON", "CLICK_EVENT");

    let windwopdf = window.open("/print/facture/generatall");
    windwopdf.porpsdata = this.props;
    windwopdf.dateinvoice = this.DateInvoice;
  }
  render() {

    const {
      match: { params },
    } = this.props;

    let elempay;

    if (this.props.ViewPay === "1") {
      let payment = this.props.ViewPaydata.payment.slice(0).reverse();
      elempay = payment[parseInt(params.action) - 1000];
    } else if (window.ViewPay === "1") {
      let payment = window.ViewPaydata.payment.slice(0).reverse();
      elempay = payment[parseInt(params.action) - 1000];
    }

    const { gtStoreInf, dtStoreinfo, GetingFacture, Facturedata, ExportPayment } = this.props;
    
    let phone = ".....",
      store_name = "......";
    let dataFact = "";
    if (gtStoreInf === "1") {
      phone = dtStoreinfo.phone;
      store_name = dtStoreinfo.name;
    }
    if (GetingFacture === "1" && Facturedata) {
      if (this.props.location.search === "?print=true") {
        window.porpsdata = this.props;
        window.dateinvoice = this.DateInvoice;
        return <Redirect to="/print/facture/generatall" />;
      }
      let facture_delivered_product = Facturedata.facture_delivered_product.map((elem, key) => {
        return [key, elem.description, elem.amount + ".00"];
      });
      let facture_echange = Facturedata.facture_echange.map((elem, key) => {
        return [key, elem.description, elem.amount + ".00"];
      });
      let facture_extra_service = Facturedata.facture_extra_service.map((elem, key) => {
        return [key, elem.description, elem.amount + ".00"];
      });

      dataFact = (
        <div>
          {this.props?.dtStoreinfo?.country?.id === 1 ? (
            <div>
              <ElementFacture title={Translate("payement", "facturedeliveredproduct")} nameTtl={Translate("payement", "tfacturedeliveredproduct") + " "} ValTtl={FormatCurrency.format(Facturedata.total_facture_delivered_product)} rows={facture_delivered_product} />
              <ElementFacture title={Translate("payement", "factureechange")} nameTtl={Translate("payement", "tfactureechange") + " "} ValTtl={FormatCurrency.format(Facturedata.total_facture_echange)} rows={facture_echange} />
              <ElementFacture title={Translate("payement", "factureextraservice")} nameTtl={Translate("payement", "tfactureextraservice") + " "} ValTtl={FormatCurrency.format(Facturedata.total_facture_extra_service)} rows={facture_extra_service} />

              <div className="PsinLan">
                <div className="StPdsml flxDrc Inlflx">
                  <span className="StOpcVal StDrcIn" style={{ fontSize: "1.2em" }}>
                    {Translate("payement", "total")}
                  </span>
                  {/* <strong style={{fontSize:"1.3em",margin:"0 4px"}}>{FormatCurrency.format((Facturedata.total_facture_delivered_product+Facturedata.total_facture_echange+Facturedata.total_facture_extra_service+Facturedata.total_facture_retoure))}</strong> */}
                  <strong style={{ fontSize: "1.3em", margin: "0 4px" }}>{FormatCurrency.format(Facturedata.total_facture_delivered_product + Facturedata.total_facture_echange + Facturedata.total_facture_extra_service)}</strong>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <ElementFacture title={Translate("payement", "facturedeliveredproduct")} nameTtl={Translate("payement", "tfacturedeliveredproduct") + " "} ValTtl={Facturedata.total_facture_delivered_product + "  " + "TND"} rows={facture_delivered_product} />
              <ElementFacture title={Translate("payement", "factureechange")} nameTtl={Translate("payement", "tfactureechange") + " "} ValTtl={Facturedata.total_facture_echange + "  " + "TND"} rows={facture_echange} />
              <ElementFacture title={Translate("payement", "factureextraservice")} nameTtl={Translate("payement", "tfactureextraservice") + " "} ValTtl={Facturedata.total_facture_extra_service + "  " + "TND"} rows={facture_extra_service} />

              <div className="PsinLan">
                <div className="StPdsml flxDrc Inlflx">
                  <span className="StOpcVal StDrcIn" style={{ fontSize: "1.2em" }}>
                    {Translate("payement", "total")}
                  </span>
                  {/* <strong style={{fontSize:"1.3em",margin:"0 4px"}}>{FormatCurrency.format((Facturedata.total_facture_delivered_product+Facturedata.total_facture_echange+Facturedata.total_facture_extra_service+Facturedata.total_facture_retoure))}</strong> */}
                  <strong style={{ fontSize: "1.3em", margin: "0 4px" }}>{Facturedata.total_facture_delivered_product + Facturedata.total_facture_echange + Facturedata.total_facture_extra_service + "  " + "TND"}</strong>
                </div>
              </div>
            </div>
          )}{" "}
        </div>
      );
    }
    return (
      <ContainerPage
        page_title={
          <div className="StDrc">
            <Link className="StOpcVal" to="/payments">
              {Translate("titles", "payements")}
            </Link>
            <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
            <div className="Inlflx">{Translate("payement", "invoicedetail")}</div>
            <div className="Inlflx StWdDst">#</div>
            <div className="Inlflx">{Translate("payement", this.props.match.params.action)}</div>
          </div>
        }
        data_top={
          <IconButton 
            label={Translate("titles", "export")} 
            icon={ExportSvg}
            clickHandler={() => {
              Event("PAYMENT_BILL", "PAYMENT_BILL_EXCEL_EXPORT", "CLICK_EVENT");
              if(params.action === "all"){
                ExportPayment([], true, "xlsx", this.lang);
              }else{
                ExportPayment([elempay.id], false, "xlsx", this.lang);
              }
            }}
          />
        }
      >
        {this.props.location.search !== "?print=true" ? (
          <div className="ClSidTh StBrdTb StBgbrds lytWdp1">
            <h3 className="DlMg StdirLn">{Translate("payement", "invoicedetail")}</h3>
            <div className="InFlx flxDrc d-large" style={{ width: "80%", margin: "10px auto 30px auto" }}>
              <div className="StdirLn" style={{ width: "45%" }}>
                <div className="SmMarS1">
                  <span>{Translate("payement", "invoicefrom")}</span>
                </div>
                <div>
                  <h4 className="DlMg">{officeConstants.COMPANY_NAME}</h4>
                </div>
                <div className="StOpcVal">
                  <p className="DlMg">
                    <span className="DsBlc">{this.props?.dataMaystro?.address}</span>

                    <span className="DsBlc">{this.props?.dataMaystro?.Email}</span>
                    <span className="DsBlc">{this.props?.dataMaystro?.["Phone number"]}</span>
                  </p>
                </div>
              </div>
              <div className="StdirLn" style={{ width: "35%" }}>
                <div className="SmMarS1">
                  <span>{Translate("payement", "invoiceto")}</span>
                </div>
                <div>
                  <h4 className="DlMg">{store_name}</h4>
                </div>
                <div className="StOpcVal">
                  <p className="DlMg">{phone}</p>
                </div>
              </div>
              <div className="StdirLn" style={{ width: "20%" }}>
                <div className="SmMarS1">
                  <span>{Translate("payement", "invoicedate")}</span>
                </div>
                <div className="StOpcVal">
                  <p className="DlMg StDrc">{FormatDate(this.DateInvoice)}</p>
                </div>
              </div>
            </div>

            <div className="d-sm">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                  margin: "10px auto 30px auto",
                }}
              >
                <div className="StdirLn" style={{ width: "70%" }}>
                  <div className="SmMarS1">
                    <span>{Translate("payement", "invoicefrom")}</span>
                  </div>
                  <div>
                    <h4 className="DlMg">{officeConstants.COMPANY_NAME}</h4>
                  </div>
                  <div className="StOpcVal">
                    <p className="DlMg">
                      <span className="DsBlc">{this.props?.dataMaystro?.address}</span>

                      <span className="DsBlc">{this.props?.dataMaystro?.Email}</span>
                      <span className="DsBlc">{this.props?.dataMaystro?.["Phone number"]}</span>
                    </p>
                  </div>
                </div>
                <div className="StdirLn" style={{ width: "70%" }}>
                  <div className="SmMarS1">
                    <span>{Translate("payement", "invoiceto")}</span>
                  </div>
                  <div>
                    <h4 className="DlMg">{store_name}</h4>
                  </div>
                  <div className="StOpcVal">
                    <p className="DlMg">{phone}</p>
                  </div>
                </div>
                <div className="StdirLn" style={{ width: "70%" }}>
                  <div className="SmMarS1">
                    <span>{Translate("payement", "invoicedate")}</span>
                  </div>
                  <div className="StOpcVal">
                    <p className="DlMg StDrc">{FormatDate(this.DateInvoice)}</p>
                  </div>
                </div>
              </div>
            </div>

            {GetingFacture === "2" && (
              <div className="PsinLan" style={{ display: "flex", justifyContent: "center" }}>
                <div onClick={this.printDocument} className="buttonPrint">
                  <div style={{ marginTop: "5px", marginRight: "5px" }}>{PrintSvg}</div>
                  <span>Print</span>
                </div>
              </div>
            )}
            {GetingFacture !== "1" ? <TableLoader /> : dataFact}
            {GetingFacture === "1" && (
              <div className="PsinLan" style={{ display: "flex", justifyContent: "center" }}>
                <div onClick={this.printDocument} className="buttonPrint">
                  <div style={{ marginTop: "5px", marginRight: "5px" }}>{PrintSvg}</div>
                  <span>Print</span>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="ClSidTh StBrdTb StBgbrds lytWdp1">
            <Loader />
          </div>
        )}
      </ContainerPage>
    );
  }
}
function mapState(state) {
  const { gtStoreInf, dtStoreinfo } = state.user;
  const { GetingFacture, Facturedata } = state.facturepayment;
  const { ViewPay, ViewPaydata } = state.Viewpayment;
  const { GetingInfomaystro, dataMaystro } = state.common;
  return {
    gtStoreInf,
    dtStoreinfo,
    GetingFacture,
    Facturedata,
    ViewPay,
    ViewPaydata,
    GetingInfomaystro,
    dataMaystro
  };
}

const actionCreators = {
  GetFacture: paymentActions.GetFacture,
  ClearStore: commonActions.ClearStore,
  GetInfoMaystro: commonActions.GetInfoMaystro,
  ExportPayment: paymentActions.ExportPayment,
  SendAlert: alertActions.SendAlert
};

const connectedFacturePayment = connect(mapState, actionCreators)(FacturePayment);
export { connectedFacturePayment as FacturePayment };
