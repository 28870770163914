import { listenerConstants, paymentConstants } from '../constants';
import { RequestService,FunctionService } from '../services';
import Cookies from 'js-cookie'
const token=Cookies.get('auth')
const store_id=Cookies.get('c_store')
export const paymentActions = {
    ViewPayment,
    GetFacture,
    ExportFactures,
    AddCCPAcount,
    GetCCPAccount,
    UpdateCCPAccount,
		ExportPayment
};
function ViewPayment(){
    return dispatch => {
        dispatch(FunctionService.request(paymentConstants.VIEW_PAYMENT_REQUEST))
        RequestService.GetRequest("stores/view_payment/",{"Authorization":"Token "+token})
        .then(res=>{
            dispatch(FunctionService.succes(res.data,paymentConstants.VIEW_PAYMENT_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err,paymentConstants.VIEW_PAYMENT_FAILURE))
        })
    }
}

function GetFacture(idpaym){
    return dispatch => {
        dispatch(FunctionService.request(paymentConstants.GET_FACTURE_REQUEST))
        RequestService.GetRequest("shared/facture/?id="+store_id+idpaym,{"Authorization":"Token "+token})
        .then(res=>{
            dispatch(FunctionService.succes(res.data,paymentConstants.GET_FACTURE_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err,paymentConstants.GET_FACTURE_FAILURE))
        })
    }
}
function ExportFactures(fileEx,ExportWorkFunc){
    return dispatch => {
        dispatch(FunctionService.request(listenerConstants.EXPORT_FACTURE_REQUEST))
        RequestService.GetRequest("shared/facture/",{"Authorization":"Token "+token})
        .then(res=>{
            dispatch(FunctionService.succes({data:res.data,fileType:fileEx,ExportWorkFunc},listenerConstants.EXPORT_FACTURE_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err,listenerConstants.EXPORT_FACTURE_FAILURE))
        })
    }
}

/* payment method */

function AddCCPAcount(compteccp){
    return dispatch => {
        dispatch(FunctionService.request(paymentConstants.ADD_CCP_REQUEST))
        RequestService.PostRequest("stores/ccp_account/",compteccp,{"Authorization":"Token "+token})
        .then(res=>{
            dispatch(FunctionService.succes(res.data,paymentConstants.ADD_CCP_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err,paymentConstants.ADD_CCP_FAILURE))
        })
    }
}

function GetCCPAccount(){
    return dispatch => {
        dispatch(FunctionService.request(paymentConstants.GET_CCP_REQUEST))
        RequestService.GetRequest("stores/ccp_account/",{"Authorization":"Token "+token})
        .then(res=>{
            dispatch(FunctionService.succes(res.data,paymentConstants.GET_CCP_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err,paymentConstants.GET_CCP_FAILURE))
        })
    }
}

function UpdateCCPAccount(ccpaccount,editccp){
    return dispatch => {
        dispatch(FunctionService.request(paymentConstants.UPDATE_CCP_REQUEST))
        RequestService.PatchRequest("stores/ccp_account/"+editccp+"/",ccpaccount,{"Authorization":"Token "+token})
        .then(res=>{
            dispatch(FunctionService.succes(res.data,paymentConstants.UPDATE_CCP_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err,paymentConstants.UPDATE_CCP_FAILURE))
        })
    }
}

function ExportPayment(data, export_all, ext, lang) {
	return (dispatch) => {
		dispatch(FunctionService.request(paymentConstants.NEW_EXPORT_FACTURES_REQUEST));
		RequestService.PostRequest(
			`shared/facture/export/?lang=${lang}&type=${ext}${export_all ? "&all=true" : ""}`,
			{ payments: data },
			{ Authorization: "Token " + token },
			{ responseType: "blob", isExportFromServer: true }
		)
		.then((res) => {
			dispatch(FunctionService.succes({ file: res.data, fileType: ext }, paymentConstants.NEW_EXPORT_FACTURES_SUCCESS));
		})
		.catch((err) => {
			dispatch(FunctionService.failure(err, paymentConstants.NEW_EXPORT_FACTURES_FAILURE))
		})
	}
}