import { exchangeConstants, userConstants } from "../constants";
import { RequestService, FunctionService } from "../services";

import Cookies from "js-cookie";
const token = Cookies.get("auth");
export const exchangeActions = {
  AddExchange,
  GetExchanges,
  AddRefund,
  GenerateExchangesBordureauStarter,
  GetExchange,
  GetUnprintedExchangesCount,
  UpdateShowExchangePopups,
  ShowExchangeGuideModal
};
function AddRefund(order, isPremium) {
  return (dispatch) => {
    dispatch(FunctionService.request(exchangeConstants.ADD_REFUND_REQUEST));
    const url = isPremium ? "exchange_refund/" : "exchange_refund_starter/";
    RequestService.PostRequest("stores/" + url, order, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res, exchangeConstants.ADD_REFUND_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, exchangeConstants.ADD_REFUND_FAILURE)
        );
      });
  };
}

function GetExchanges(filter) {
  return (dispatch) => {
    dispatch(FunctionService.request(exchangeConstants.GET_EXCHANGE_REQUEST));
    RequestService.GetRequest("delivery/exchanges/" + filter, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            exchangeConstants.GET_EXCHANGE_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            exchangeConstants.GET_EXCHANGE_FAILURE
          )
        );
      });
  };
}


function GetExchange(id) {
  return (dispatch) => {
    dispatch(FunctionService.request(exchangeConstants.GET_EXCHANGE_BY_ID_REQUEST));
    RequestService.GetRequest("delivery/exchanges/" + id + "/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            exchangeConstants.GET_EXCHANGE_BY_ID_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            exchangeConstants.GET_EXCHANGE_BY_ID_FAILURE
          )
        );
      });
  };
}

function AddExchange(order, isPremium) {
  return (dispatch) => {
    const url = isPremium ? "exchange_refund/" : "exchange_refund_starter/";
    dispatch(FunctionService.request(exchangeConstants.ADD_EXCHANGE_REQUEST));
    RequestService.PostRequest("stores/" + url, order, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(FunctionService.succes(res, exchangeConstants.ADD_EXCHANGE_SUCCESS));
        if(!isPremium) {
          dispatch(FunctionService.succes(true, exchangeConstants.UPDATE_SHOW_EXCHANGE_GUIDE_MODAL));
        };
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, exchangeConstants.ADD_EXCHANGE_FAILURE)
        );
      });
  };
}

function GenerateExchangesBordureauStarter(exchanges_ids, all_created) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.POST_BORDEREAU_REQUEST));
    RequestService.PostRequest(
      all_created ?
        "delivery/exchanges/print_exchange_slip/?all=true"
      :
        "delivery/exchanges/print_exchange_slip/" 
      ,
      { exchanges_ids: exchanges_ids },
      {
        Authorization: "Token " + token,
      },
      { responseType: "blob", isExportFromServer: true }
    )
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        dispatch(FunctionService.succes(file, userConstants.POST_BORDEREAU_SUCCESS));
        dispatch(FunctionService.succes({ all: all_created, exchanges_ids }, exchangeConstants.UPDATE_PRINTED_EXCHANGE_SLIPS));
      })
      .catch((err) => {
        dispatch(FunctionService.failure(err, userConstants.POST_BORDEREAU_FAILURE));
      });
  };
}

function GetUnprintedExchangesCount (){
  return (dispatch) => {
    dispatch(FunctionService.request(exchangeConstants.GET_UNPRINTED_EXCHANGES_COUNT_REQUEST));
    RequestService.GetRequest("delivery/exchanges/count_unprinted_exchanges/",
      {
        Authorization: "Token " + token,
      }
    )
      .then((res) => {
        dispatch(
          FunctionService.succes(
            { count: res.data.unprinted_exchanges_count },
            exchangeConstants.GET_UNPRINTED_EXCHANGES_COUNT_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, exchangeConstants.GET_UNPRINTED_EXCHANGES_COUNT_FAILURE)
        );
      });
  };
};

function UpdateShowExchangePopups (value){
  return (dispatch) => {
    dispatch(FunctionService.request(exchangeConstants.UPDATE_SHOW_EXCHANGE_POPUPS_REQUEST));
    RequestService.PostRequest("shared/store/update_exchange_popups/", 
      { show_exchange_popups: value },
      { Authorization: "Token " + token }  
    )
      .then((res) => {
        dispatch(FunctionService.succes({}, exchangeConstants.UPDATE_SHOW_EXCHANGE_POPUPS_SUCCESS));
        dispatch(FunctionService.succes(res.data.show_exchange_popups, userConstants.UPDATE_SHOW_EXCHANGE_MODALS));
      })
      .catch((err) => {
        FunctionService.failure(err, exchangeConstants.UPDATE_SHOW_EXCHANGE_POPUPS_FAILURE);
      });
  }
};

function ShowExchangeGuideModal(value){
  return (dispatch) => {
    dispatch(FunctionService.succes(value, exchangeConstants.UPDATE_SHOW_EXCHANGE_GUIDE_MODAL));
  };
}