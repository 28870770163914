import { exchangeConstants } from '../constants';

export function exchanges(state , action) {
  switch (action.type) {
    case exchangeConstants.ADD_EXCHANGE_REQUEST:
      return {
        ...state,
        ExchangeAdded:"1",
        dataAddExchange: action.payload
      };
    case exchangeConstants.ADD_EXCHANGE_SUCCESS:

      const prevCount = state.dataCountExchanges.count;
      
      return {
        ...state,
        ExchangeAdded:"2",
        dataAddExchange: action.payload,
        dataCountExchanges: { count: prevCount + 1 }
      };
    case exchangeConstants.ADD_EXCHANGE_FAILURE:
      return {
        ...state,
        ExchangeAdded:"3",
        dataAddExchange: action.payload
      };
    case exchangeConstants.ADD_REFUND_REQUEST:
      return {
        ...state,
        RefundAdded:"1",
        dataAddRefund: action.payload
      };
    case exchangeConstants.ADD_REFUND_SUCCESS:
      return {
        ...state,
        RefundAdded:"2",
        dataAddRefund: action.payload
      };
    case exchangeConstants.ADD_REFUND_FAILURE:
      return {
        RefundAdded:"3",
        dataAddRefund: action.payload
      };
    case exchangeConstants.GET_EXCHANGE_REQUEST:
      return {
        ...state,
        GettingExchanges: "1",
        dataExchanges: null
      };
    case exchangeConstants.GET_EXCHANGE_SUCCESS:
      return {
        ...state,
        GettingExchanges: "2",
        dataExchanges: action.payload
      };
    case exchangeConstants.GET_EXCHANGE_FAILURE:
      return {
        ...state,
        GettingExchanges: "3",
        dataExchanges: action.payload
      };
      
    case exchangeConstants.GET_EXCHANGE_BY_ID_REQUEST:
      return {
        ...state,
        ExchangeGetting: "1",
        dataExchange: action.payload,
      };
    
    case exchangeConstants.GET_EXCHANGE_BY_ID_SUCCESS:
      return {
        ...state,
        ExchangeGetting: "2",
        dataExchange: action.payload,
      };
    
    case exchangeConstants.GET_EXCHANGE_BY_ID_FAILURE:
      return {
        ...state,
        ExchangeGetting: "3",
        dataExchange: action.payload,
      };

    case exchangeConstants.GET_UNPRINTED_EXCHANGES_COUNT_REQUEST:
      return {
        ...state,
        GettingUnprintedExchangesCount: "1",
        dataCountExchanges: action.payload
      };
    
    case exchangeConstants.GET_UNPRINTED_EXCHANGES_COUNT_SUCCESS:
      return {
        ...state,
        GettingUnprintedExchangesCount: "2",
        dataCountExchanges: action.payload
      };

    case exchangeConstants.GET_UNPRINTED_EXCHANGES_COUNT_FAILURE:
      return {
        ...state,
        GettingUnprintedExchangesCount: "3",
        dataCountExchanges: action.payload
      };
    
    case exchangeConstants.UPDATE_SHOW_EXCHANGE_POPUPS_REQUEST:
      return {
        ...state,
        UpdatingExchangePopups: "1"
      };
    
    case exchangeConstants.UPDATE_SHOW_EXCHANGE_POPUPS_SUCCESS:
      return {
        ...state,
        UpdatingExchangePopups: "2"
      };
    
    case exchangeConstants.UPDATE_SHOW_EXCHANGE_POPUPS_FAILURE:
      return {
        ...state,
        UpdatingExchangePopups: "3"
      };

    case exchangeConstants.UPDATE_PRINTED_EXCHANGE_SLIPS:
      let new_exchanges;
      const { all, exchanges_ids } = action.payload;
      const { dataExchanges } = state;
      let new_unprinted_exchanges_count = state.dataCountExchanges.count;

      if(all){
        new_exchanges = dataExchanges.results.map((exchangeItem, _) => {
          if(exchangeItem.times_printed === 0) new_unprinted_exchanges_count = new_unprinted_exchanges_count - 1;
          return { ...exchangeItem, times_printed: exchangeItem.times_printed + 1 }
        });
      }else{
        new_exchanges = dataExchanges.results.map((exchangeItem, _) => {
          if(exchanges_ids.includes(exchangeItem.id)){
            if(exchangeItem.times_printed === 0) new_unprinted_exchanges_count = new_unprinted_exchanges_count - 1;
            return { ...exchangeItem, times_printed: exchangeItem.times_printed + 1 }
          }else {
            return exchangeItem;
          }
        });
      }

      return {
        ...state,
        dataExchanges: { ...dataExchanges, results: new_exchanges },
        dataCountExchanges: { count: new_unprinted_exchanges_count }
      }
    
    case exchangeConstants.UPDATE_SHOW_EXCHANGE_GUIDE_MODAL:
      return {
        ...state,
        showExchangeGuideModal: action.payload,
      };

    default:
        return {...state}
    }
}
