import React from "react";

interface IconButtonProps {
  clickHandler: () => void,
  icon: JSX.Element,
  label?: string,
  className?: string
}

const IconButton = ({
  clickHandler,
  icon,
  label,
  className
}: IconButtonProps) => {
  return (
    <button
      onClick={clickHandler}
      className={`InFlx AlgnItm sb4R ClSidTh borderClr CrsPoi ${className ?? ""}`}
      style={{
        padding: "4px 10px",
        gap: "10px"
      }}
    >
      <span style={{ marginTop: "5px" }}> {icon} </span>
      {
        (label && label !== "")
        &&
        <span 
          className="StpdVlrglt Fntcl" 
          style={{ fontSize: "16px", fontFamily: "Nunito Sans" }}
        > 
          {label} 
        </span>
      }
    </button>
  );
}
 
export default IconButton;