import React from "react";
import { Link } from "react-router-dom";
import { Translate } from "../../../utils/lang/translate";
export default function ElementSide(props) {
  return (
    <Link to={props.to}>
      <div
        className="FlWd StHgt RlPs SmMarS"
        onClick={() => {
          if (props.clickEvent) props.clickEvent();
        }}
      >
        {!props.reduce && (
          <span
            className={
              props.active
                ? "StAbs FlHg BdgBlcl StLanNlin BrdLft StTpNl WdSmP StTrns sidebar-active-item-decoration"
                : ""
            }
          ></span>
        )}
        <div
          className={
            props.active
              ? "BdgBlcl RglWdM FlHg StBrdRdS StWht"
              : "RglWdM FlHg StBrdRdS StTrns"
          }
        >
          <div
            className={
              "InFlx AlgnItm flxDrc FlHg FlWd sidebar-item-content " +
              (props.reduce ? "JstfCnt" : "")
            }
          >
            <div className="InFlx RlPs StWht">
              {props.icon}
              {props.notif && (
                <div className="StAbs" style={{ right: "-10px", top: "-9px" }}>
                  {props.temp_orders_count === 0 ? (
                    <div />
                  ) : typeof props.temp_orders_count === "number" ? (
                    <div
                      className="InFlx AlgnItm JstfCnt"
                      style={{
                        backgroundColor: "red",
                        borderRadius: "500px",
                        height: "19px",
                        width: "19px",
                        fontSize: "10px",
                      }}
                    >
                      {props.temp_orders_count > 9
                        ? "+9"
                        : props.temp_orders_count}
                    </div>
                  ) : (
                    <div className="lds-dual-ring"></div>
                  )}
                </div>
              )}
            </div>

            {!props.reduce && (
              <div className="FlHg InvStMrtg InFlx FlWd AlgnItm sidebar-active-item-decoration">
                <div className="TbDp RlPs FlHg">
                  <div className="TbCdp RlPs TxtCn VrAlg">
                    <span className="StSmlS">{props.text}</span>
                  </div>
                </div>
                {props.new && (
                  <div className="marInStart InFlx">
                    <div
                      style={{
                        marginTop: "3px",
                        backgroundColor: "#2BC194",
                        padding: "2px 6px",
                        borderRadius: "500px",
                        fontSize: "12px"
                      }}
                    >
                      {Translate("shop", "new")}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}
