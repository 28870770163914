import React, { useEffect, useRef, useState } from "react";
import TabShow from "../_Common/TabShow";
import { CloseSvg2 } from "../_Common/IconSvg";
import { Translate } from "../../../utils/lang/translate";
import GroupeBtn from "../_Common/GroupeBtn";
import { Event } from "../_Common";
import OokadoImage from "../../../utils/img/OokadoImage.png";
import BtnLg from "../_Common/BtnLg";
import CheckBoxForm from "../../_Common/CheckBoxForm";

interface OokadoModalProps {
  onClose: (neverShow: boolean) => void,
}

const OokadoModal = ({ onClose }: OokadoModalProps) => {


  const modalRef = useRef<HTMLDivElement>(null);

  const [neverShow, setNeverShow] = useState(false);

  const handleClickOutside = (e: MouseEvent) => {
    if(modalRef.current && !modalRef.current?.contains(e.target as Node)){
      onClose(neverShow);
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => document.removeEventListener('mouseup', handleClickOutside);
  }, []);

  return (
    <TabShow
      darkBg
      noReturn
      noPadding={true}
      style={{
        minWidth: "50vw",
        borderRadius: "8px",
        overflow: "hidden"
      }}
    >
      <div ref={modalRef} className="InFlx Stclmnf">
        <div className="ookado_image_container" style={{ backgroundImage: `url(${OokadoImage})`}}>
          <div style={{ position: "absolute", top: "20px", right: "20px", zIndex: 10 }}>
            <GroupeBtn 
              data_btn={[
                {
                  svgbtn: CloseSvg2,
                  type: "Gray",
                  action: () => {
                    Event("OOKADO_MODAL", "CLOSE_OOKADO_MODAL", "CLICK_EVENT");
                    onClose(neverShow);
                  },
                }
              ]}
            />
          </div>
        </div>
        <div className="InFlx AlgnItm spctwB" style={{ padding: "10px 15px" }}>
          <div className="InFlx">
            <CheckBoxForm
              id="show-exchange-guide-modal-checkbox"
              name="show-exchange-guide-modal-checkbox"
              labelClass="stfSize"
              text={Translate("exchanges", "dontshowthisagain")}
              workfun={() => {
                setNeverShow(prev => !prev);
                Event("OOKADO_MODAL", "TOGGLE_NEVERSHOW_CHECKBOX", "CLICK_EVENT");
              }}
              check={neverShow}
            />
          </div>
          <div
            className="Inlflx AlgnItm JstfCnt CrsPoi"
            onClick={() => {
              onClose(neverShow);
              Event("OOKADO_MODAL", "CLOSE_OOKADO_MODAL", "CLICK_EVENT");
            }}
          >
            <BtnLg
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 7px"
              }}
              text={Translate("product", "close")} 
            />
          </div>
        </div>
      </div>
    </TabShow>
  );
}
 
export default OokadoModal;