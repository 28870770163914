import { rewardsConstants } from "../constants/rewards.constants";

export function rewards(state, action) {
  switch(action.type){
    case rewardsConstants.GET_REWARDS_REQUEST:
      return {
        ...state,
        GettingRewards: "1",
      };
    
    case rewardsConstants.GET_REWARDS_SUCCESS:
      return {
        ...state,
        GettingRewards: "2",
        dataRewards: action.payload
      };
    
    case rewardsConstants.GET_REWARDS_FAILURE:
      return {
        ...state,
        GettingRewards: "3",
        dataRewards: action.payload,
      };
    
    case rewardsConstants.GET_ALL_TIERS_REQUEST:
      return {
        ...state,
        GettingTiersData: "1"
      };
    
    case rewardsConstants.GET_ALL_TIERS_SUCCESS:
      return {
        ...state,
        GettingTiersData: "2",
        tiersData: action.payload,
      };
    
    case rewardsConstants.GET_ALL_TIERS_FAILURE:
      return {
        ...state,
        GettingTiersData: "3",
        tiersData: action.payload,
      };

    case rewardsConstants.UPDATE_AUTO_SPEND_POINTS_REQUEST:
      return {
        ...state,
        updatingAutoSpendpoints: "1",
      };

    case rewardsConstants.UPDATE_AUTO_SPEND_POINTS_SUCCESS:
      return {
        ...state,
        updatingAutoSpendpoints: "2",
        dataRewards: action.payload
      };

    case rewardsConstants.UPDATE_AUTO_SPEND_POINTS_FAILURE:
      return {
        ...state,
        updatingAutoSpendpoints: "3",
      };

    default:
      return { ...state }
  }
}