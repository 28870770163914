import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import ContainerPage from "../_Common/ContainerPage";
import { Translate } from "../../../utils/lang/translate";
import StatusElement from "../_Common/StatusElement";
import Table from "../_Common/Table";
import BtnLg from "../_Common/BtnLg";
import GroupeBtn from "../_Common/GroupeBtn";
import TimelineItem from "../_Common/TimelineItem";
import IconButton from "../_Common/IconButton";
import {
  DeletSvg,
  EditSvg,
  CheckSvg,
  FlechDwnSvg,
  PersonSvg,
  CallSvg,
  LocationSvg,
  CloseSvg,
  RefreshSvg,
  PrintSvg,
  OutSvg,
  FilterSvg2
} from "../_Common/IconSvg";
import InlinedElement from "../_Common/InlinedElement";
import {
  pickupsActions,
  alertActions,
  commonActions,
  productActions,
} from "../../../actions";
import TableLoader from "../_Common/TableLoader";
import Loader from "../../_Common/Loader";
import TabShow from "../_Common/TabShow";
import CheckBoxForm from "../../_Common/CheckBoxForm";
import { EmptyScreen } from "../_Common/EmptyScreen";
import PaginationBar from "../_Common/PaginationBar";
import { FeatureLoked } from "../_Common/FeatureLoked";
import PropTypes from "prop-types";
import { addUrlProps, UrlQueryParamTypes, Serialize } from "react-url-query";
import { initGAPg, Event } from "../_Common";
import { PICKUPS_STATUS_LIST, PICKUPS_TABLE_HEADER } from "../../constants";
const DotSeparatedQueryParamType = {
  encode: (val) => Serialize.encodeArray(val, ","),
  decode: (val) => Serialize.decodeArray(val, ","),
};
const urlPropsQueryConfig = {
  status: { type: DotSeparatedQueryParamType, queryParam: "status" },
  page: { type: UrlQueryParamTypes.number },
  display_id: { type: UrlQueryParamTypes.string },
};

class PickupsPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showNewFeaturePopup: false,
      pickups:
        this.props.PickupGetting === "2"
          ? this.props.PickupsList.ressuc.results
          : [],
      nextpage:
        this.props.PickupGetting === "2"
          ? this.props.PickupsList.ressuc.next
          : null,
      prevpage:
        this.props.PickupGetting === "2"
          ? this.props.PickupsList.ressuc.previous
          : null,
      count:
        this.props.PickupGetting === "2"
          ? this.props.PickupsList.ressuc.count
          : 0,
      statusList: PICKUPS_STATUS_LIST,
      alertdelet: null,
      premmision:
        this.props.dataProfile.is_store || this.props.dataProfile.view_pickup,
      premisionedit:
        this.props.dataProfile.is_store || this.props.dataProfile.change_pickup,
      
      allSelect: null,
      selectedPickup: null,
    };
    this.printRequested = false;
    this.crntfilter =
      this.props.PickupGetting === "2" ? this.props.PickupsList.filter : "";
    this.displayid = null;
    this.refrs = {
      Status_Container: React.createRef(),
    };
    this.evnfunc = {
      Status_Container: (e) => {
        if (
          this.refrs.Status_Container &&
          !this.refrs.Status_Container.current.contains(e.target)
        ) {
          this.ControlFilter("Status_Container");
        }
      },
    };
    this.resFilter = false;
    this.CallProps = false;
    this.firstTime = true;
    this.Gotodetail = this.Gotodetail.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.PrintPickup = this.PrintPickup.bind(this);
    this.CancelPickup = this.CancelPickup.bind(this);
    this.FilterRef = React.createRef();
    this.ShowFilter = this.ShowFilter.bind(this);
    this.handleClickOutsideFilter = this.handleClickOutsideFilter.bind(this);
    this.setOptionChnage = this.setOptionChnage.bind(this);
    this.SetDisplayId = this.SetDisplayId.bind(this);
    this.CallPropsS = this.CallPropsS.bind(this);
  }
  static defaultProps = {
    status: [],
    products: [],
    page: 1,
  };
  static propTypes = {
    status: PropTypes.array,
    page: PropTypes.number,
    display_id: PropTypes.string,
    /********************** */
    onChangePage: PropTypes.func,
    onChangeStatus: PropTypes.func,
    onChangeDisplay_id: PropTypes.func,
  };
  componentWillReceiveProps(nextProps) {
    if (this.state.premmision) {
      if (!this.props.PickupGetting === "0" || this.props.PickupGetting === "0")
        this.exctuteFilterUrl(this.getFilterToState(), true);
      const {
        PickupGetting,
        PickupsList,
        PickupUpdateStatus,
      } = nextProps;
      if (
        this.props.PickupGetting === "1" &&
        PickupGetting === "2" &&
        PickupsList &&
        PickupsList.ressuc &&
        PickupsList.ressuc.results
      ) {
        document.getElementById("Container-root").scrollTo(0, 0);
        this.setState({
          pickups: PickupsList.ressuc.results,
          nextpage: PickupsList.ressuc.next,
          prevpage: PickupsList.ressuc.previous,
          count: PickupsList.ressuc.count || PickupsList.ressuc.results.length,
        });
      }
      if (PickupGetting === "3") {
        if (
          PickupsList &&
          PickupsList.data &&
          PickupsList.data.detail === "Page invalide."
        ) {
          this.props.onChangePage(undefined);
        }
      }
      if (this.props.PickupUpdateStatus === "0" && PickupUpdateStatus === "1") {
        this.props.SendAlert(
          "41",
          Translate("alert", "successpickupupdated"),
          ""
        );
        this.exctuteFilterUrl(this.getFilterToState(), true);
      }
      if (this.props.PickupUpdateStatus === "0" && PickupUpdateStatus === "2") {
        this.props.SendAlert("50", Translate("alert", "failpickupupdated"), "");
      }
      if (
        nextProps.page !== this.props.page ||
        this.resFilter ||
        this.CallProps
      ) {
        this.resFilter = false;
        this.CallProps = false;
        this.exctuteFilterUrl(this.getFilterToState(nextProps));
      }
    }
  }
  componentDidMount() {
    document.title = "Maystro Delivery - " + Translate("titles", "pickups");
    initGAPg();
    if (this.state.premmision) {
      let nextFilter = this.getFilterToState();

      if (this.props.PickupGetting !== "2") {
        this.exctuteFilterUrl(nextFilter, true);
      } else {
        this.exctuteFilterUrl(nextFilter);
      }
      if (this.props.ProductFinded !== "2") {
        this.props.GetAllProduct();
      }
    }
  }
  PrintPickup(pickupid) {
    let target = this.state.pickups.find((el) => el.display_id === pickupid);
    if (target) {
      // this.printRequested = true;
      this.setState({
        selectedPickup: target,
        printRequested: true,
      });
      this.props.PrintBordereauPickup(pickupid);
      // this.props.GetPickupOrders(target.id);
    }
  }
  CancelPickup(id) {
    let idremove = this.state.alertdelet;
    if (idremove) {
      this.props.UpdatePickupStatus(
        {
          status: 50,
          store: this.props.dtStoreinfo.id,
        },
        idremove
      );
      this.setState({
        alertdelet: null,
      });
    } else {
      this.setState({
        alertdelet: id,
      });
    }
  }

  showOpt(name) {
    let act = this.state[name];
    this.setState({
      [name]: !act,
    });
  }
  Gotodetail(id) {
    Event("PICKUPS", "GOTO_PICKUP_DETAILS", "CLICK_EVENT_FROM_LIST");
    this.props.history.push("/pickups/detail/" + id + "?source=pickups");
  }
  getFilterToState(props) {
    let Filter = props ? props.location.search : this.props.location.search;
    return Filter;
  }
  exctuteFilterUrl(nextFilter, force) {
    let prevFilter = this.crntfilter;
    if (prevFilter !== nextFilter || force) {
      this.crntfilter = nextFilter;
      this.props.GetPickups(nextFilter);
    }
  }
  ChangeDisId(e) {
    const { value } = e.target;
    this.displayid = value;
  }
  ShowFilter() {
    Event("PICKUPS", "CLICK_FILTER", "CLICK_EVENT");
    let clsList = document.getElementById("Filter_container");
    if (clsList.classList.value.indexOf("hidElem2") !== -1) {
      clsList.classList.remove("hidElem2");
      document.addEventListener("mousedown", this.handleClickOutsideFilter);
    } else {
      clsList.classList.add("hidElem2");
      document.removeEventListener("mousedown", this.handleClickOutsideFilter);
    }
  }
  handleClickOutsideFilter(event) {
    if (
      this.FilterRef &&
      this.FilterRef.current &&
      !this.FilterRef.current.contains(event.target)
    ) {
      this.ShowFilter();
    }
  }
  SetDisplayId(e) {
    e.preventDefault();
    Event("PICKUPS", "FILTER_BY_ID", "CLICK_EVENT");
    this.CallProps = true;
    if (this.props.display_id) {
      this.props.onChangeDisplay_id(undefined);
    } else {
      this.props.onChangeDisplay_id(this.displayid);
    }
  }
  ControlFilter(idTraget) {
    let clsList = document.getElementById(idTraget);
    if (clsList.classList.value.indexOf("hidElem3") !== -1) {
      clsList.classList.remove("hidElem3");
      clsList.style.maxHeight = "400px";
      document.addEventListener("mousedown", this.evnfunc[idTraget]);
    } else {
      clsList.style.maxHeight = "0px";
      clsList.classList.add("hidElem3");
      document.removeEventListener("mousedown", this.evnfunc[idTraget]);
    }
  }
  /***  manage query */
  manageQueryStatus(statu) {
    const { status, onChangeStatus } = this.props;
    let TmpTab = status;
    let TmpTab2 = [...status, statu];
    if (TmpTab.indexOf(statu) !== -1) {
      TmpTab2 = TmpTab2.filter((elem) => elem !== statu);
    }
    this.CallProps = true;
    onChangeStatus(TmpTab2);
  }
  setOptionChnage(name, value) {
    if (name === "wilaya") {
      const { wilaya, onChangeWilaya } = this.props;
      let TmpTab = wilaya;
      let TmpTab2 = [...wilaya, value];
      if (TmpTab.indexOf(value) !== -1) {
        TmpTab2 = TmpTab2.filter((elem) => elem !== value);
      }
      this.CallProps = true;
      onChangeWilaya(TmpTab2);
    }
  }
  /****** end query */
  GotoAddPage() {
    Event("PICKUPS", "GOTO_ADD_PICKUP_BUTTON", "CLICK_EVENT_FROM_LIST");
    this.props.history.push("/pickups/add");
  }
  RefreshPickups() {
    Event("PICKUPS", "REFRSH_PICKUPS_BUTTON", "CLICK_EVENT");
    this.exctuteFilterUrl(this.getFilterToState(), true);
  }

  CallPropsS() {
    this.CallProps = true;
  }
  resetFilter() {
    Event("PICKUPS", "RESET_FILTERS_BUTTON", "CLICK_EVENT");
    const { onChangeStatus, onChangeDisplay_id } = this.props;
    onChangeDisplay_id(undefined);
    onChangeStatus([]);
    this.resFilter = true;
  }

  render() {
    if (this.state.premmision) {
      const { PickupGetting, PickupUpdateStatus, page } = this.props;
      const { dtStoreinfo } = this.props;
      var bodytab = [];
      if (
        this.props.PickupOrdersGetting === "2" &&
        this.props.PickupOrders &&
        this.printRequested
      ) {
        this.printRequested = false;
        const { dtStoreinfo } = this.props;
        let pickupwindow = window.open("/print/facture/bonrm");
        pickupwindow.pickups = true;
        pickupwindow.store = dtStoreinfo;
        pickupwindow.pickup = this.state.selectedPickup;
        pickupwindow.orders = this.props.PickupOrders.ressuc;
      }
      if (PickupGetting === "2") {
        bodytab = this.state.pickups.map((elem, index) => {
          const {
            id,
            display_id,
            created_at,
            affected_to_agent_at,
            shipped_by_agent_at,
            recived_by_maystro_at,
            aborted_at,
            status,
            agent,
            pickup_address,
          } = elem;
          const PRINT_ACTION = {
            type: "BDgInpc",
            action: () => this.PrintPickup(display_id),
            svgbtn: PrintSvg,
            tooltip: Translate("tooltips", "printpickupslip"),
          };
          const CANCEL_ACTION = {
            type: "BDgInpc",
            action: () => this.CancelPickup(id),
            svgbtn: DeletSvg,
            tooltip: Translate("profile", "cancel"),
          };
          const EDIT_ACTION = {
            type: "BDgInpc",
            action: () => this.props.history.push("/pickups/add/" + id),
            svgbtn: EditSvg,
            tooltip: Translate("pickups", "editpickup"),
          };
          let btns = "",
            act_btns = [];
          if(this.state.premmision && status < 50){
            act_btns.push(PRINT_ACTION);
          }  
          if (this.state.premisionedit && status < 6) {
            act_btns.push(EDIT_ACTION);
            act_btns.push(CANCEL_ACTION);
          }
          btns = <GroupeBtn data_btn={act_btns} />;
          // let timeline=[{status:"11",date:created_at},{status:"22",date:affected_to_agent_at},{status:"42",date:shipped_by_agent_at},{status:"41",date:recived_by_maystro_at}];
          let timeline = [
            { status: "11", date: created_at },
            { status: "22", date: affected_to_agent_at },
            { status: "42", date: shipped_by_agent_at },
            {
              status: status === 40 ? "40" : "41",
              date: recived_by_maystro_at,
            },
            { status: status === 50 ? "50" : "51", date: aborted_at },
          ].filter((el) => el.date !== undefined && el.date !== null);
          let Timeline = <TimelineItem key={index} timeline={timeline} />;
          let ProviderInfos = (
            <React.Fragment>
              <InlinedElement
                first={PersonSvg}
                secnd={<span>{dtStoreinfo.name}</span>}
              />
              <InlinedElement
                first={CallSvg}
                secnd={<span>{dtStoreinfo.phone}</span>}
              />
              <InlinedElement
                first={LocationSvg}
                secnd={<span>{pickup_address}</span>}
              />
            </React.Fragment>
          );
          let DriverInfos = agent ? (
            <React.Fragment>
              {Translate("pickups", "assignedtoagent")}
              <div>
                <span>{agent.first_name}</span>
                <span>{agent.last_name}</span>
              </div>
            </React.Fragment>
          ) : (
            Translate("pickups", "notassigned")
          );

          let blockindex = [5];

          return [
            display_id,
            <div style={{ maxWidth: "200px" }}>
              <StatusElement category="statuspickup" classes="timeline">
                {status}
              </StatusElement>
            </div>,
            Timeline,
            ProviderInfos,
            DriverInfos,
            btns,
            { type: "forindex", blocked: blockindex, idaction: display_id },
          ];
        });
      }

      return this.props.postingbordereauPickup === "1" ? (
        <Loader />
      ) : (
        <ContainerPage page_title={Translate("titles", "pickups")}>
          {this.state.showNewFeaturePopup && (
            <TabShow NotCls style={{ minWidth: "80vw" }}>
              <div
                className="FlWd Inlflx AlgnItm StDrc spcBtwn"
                style={{ marginBottom: "3rem" }}
              >
                <div className="StdirLn">
                  <h3 className="DlMg">
                    {Translate("titles", "newfeaturetitle")}
                  </h3>
                  <h5 className="DlMg">
                    {Translate("titles", "picukpprocess")}
                  </h5>
                </div>

                <div
                  className="CrsPoi"
                  onClick={() => this.setState({ showNewFeaturePopup: false })}
                >
                  {CloseSvg}
                </div>
              </div>
              <div className="SmMarS FlWd Inlflx AlgnItm JstfCnt">
                <iframe
                  style={{ width: "100%", minHeight: "50vh", border: "none" }}
                  src="https://storage.googleapis.com/stores_video/Statuts%20MD%20-%20Video.mp4"
                  title={Translate("titles", "newfeaturetitle")}
                />
              </div>
              <div
                className="SmMarS FlWd Inlflx AlgnItm JstfCnt CrsPoi"
                onClick={() => this.setState({ showNewFeaturePopup: false })}
              >
                <BtnLg
                  style={{
                    minHeight: "40px",
                    minWidth: "10vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  text={Translate("titles", "done")}
                />
              </div>
            </TabShow>
          )}
          {PickupGetting === "2" &&
          this.state.count === 0 &&
          !(
            this.props.location.search &&
            (this.props.location.search.replace(
              "?page=" + this.props.page,
              ""
            ) ||
              this.props.location.search.replace(
                "&page=" + this.props.page,
                ""
              )) !== ""
          ) ? (
            <EmptyScreen
              titleGlb={Translate("pickups", "nopickup")}
              TextShow={Translate("pickups", "youhavntcreat")}
              TxtBtn={Translate("titles", "learnhow")}
              FuncCall={() => this.setState({ showNewFeaturePopup: true })}
            />
          ) : (
            <div>
              <div
                className="InFlx flxDrc StmpB3 StPs zindX2 BdgGc"
                style={{ height: "48px", padding: "7px 0", top: "-20px" }}
              >
                {
                  <div
                    role="filter-data"
                    className="FlHg InFlx StwdMaxC flxDrc"
                  >
                    <div
                      className="FlHg StMrtg2 InFlx AlgnItm"
                      ref={this.FilterRef}
                    >
                      <IconButton 
                        clickHandler={this.ShowFilter} 
                        icon={FilterSvg2} 
                        className="BdgBlcl"
                      />
                      <div
                        id="Filter_container"
                        className="StAbs Zindxsm StLanNlin StBxSh1 StwdMaxC stTranEs StTpval3 HdOvrfl ClSidTh StPdsml StBgbrds hidElem2"
                        style={{ width: "350px" }}
                      >
                        <div id="Sub_Container_filter">
                          <div style={{ marginTop: "8px" }}>
                            <div className="RlPs">
                              <InlinedElement
                                secnd={
                                  <strong>
                                    {Translate("orders", "filter")}
                                  </strong>
                                }
                                leftElm={
                                  <div
                                    onClick={this.ShowFilter}
                                    className="InFlx CrsPoi IcnSizSm"
                                  >
                                    {CloseSvg}
                                  </div>
                                }
                                style={{ width: "100%", padding: "0px" }}
                              />
                            </div>
                            {this.props.location.search &&
                            (this.props.location.search.replace(
                              "?page=" + this.props.page,
                              ""
                            ) !== "" ||
                              this.props.location.search.replace(
                                "&page=" + this.props.page,
                                ""
                              )) !== "" ? (
                              <span
                                className="CrsPoi StBle DsBlc StdirLn"
                                onClick={this.resetFilter}
                              >
                                {Translate("orders", "resetfilter")}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            ref={this.refrs.Status_Container}
                            className="BdgClN StBrdRdS StPdsml SmMarS1"
                          >
                            <div
                              className="StHeivl RlPs CrsPoi"
                              onClick={() =>
                                this.ControlFilter("Status_Container")
                              }
                            >
                              <InlinedElement
                                secnd={
                                  <strong>
                                    {Translate("pickups", "pickupstatus")}
                                  </strong>
                                }
                                leftElm={
                                  <div className="InFlx">{FlechDwnSvg}</div>
                                }
                                style={{ width: "100%", padding: "0px" }}
                              />
                            </div>
                            <div
                              id="Status_Container"
                              className="hidElem3 stTranEs"
                            >
                              <div>
                                {this.state.statusList.map((elem, key) => {
                                  return (
                                    <CheckBoxForm
                                      key={key}
                                      id={"S" + elem}
                                      name={"S" + elem}
                                      text={Translate("statuspickup", elem)}
                                      workfun={() =>
                                        this.manageQueryStatus(elem)
                                      }
                                      check={
                                        Array.isArray(this.props.status) &&
                                        this.props.status.indexOf(elem) !== -1
                                      }
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={this.SetDisplayId}>
                      <div className="FlHg StBrdRdS ClSidTh">
                        <InlinedElement
                          style={{ padding: "0" }}
                          secnd={
                            <div>
                              <input
                                type="text"
                                autoComplete="off"
                                onChange={this.ChangeDisId.bind(this)}
                                name="filterbyid"
                                style={{ width: "300px", maxWidth: "35vw" }}
                                className="StdirLn FntfMin StPdsml BdgTrans DlBrd StSizLn Fntcl"
                                value={this.props.display_id}
                                placeholder={Translate("pickups", "filterbyid")}
                              />
                            </div>
                          }
                          leftElm={
                            <button
                              className="BdgGc DlBrd CrsPoi Inlflx StBgbrd"
                              style={{ padding: "10px", margin: "0 5px" }}
                            >
                              {this.props.display_id ? OutSvg : CheckSvg}
                            </button>
                          }
                        />
                      </div>
                    </form>
                  </div>
                }
                <div>
                  <div
                    className="FlWd InFlx"
                    style={{ height: "48px", margin: "0 20px", gap: "12px" }}
                  >
                    <IconButton
                      clickHandler={this.RefreshPickups.bind(this)}
                      icon={RefreshSvg}
                    />
                  </div>
                </div>
              </div>
              <div className="InFlx flxDrc StmpB3 StOpcVal StFlxWrp2 FlwMax AlgnItm">
                {this.props.status.length > 0 && (
                  <Fragment>
                    <div className="stBdp StSmlS StwdMaxC">
                      {Translate("pickups", "pickupstatus")}
                    </div>
                    {this.props.status.map((elem, key) => {
                      return (
                        <div
                          key={key}
                          onClick={() => this.manageQueryStatus(elem)}
                          className="stBdp CrsPoi SthgMaxC"
                        >
                          <InlinedElement
                            style={{
                              border: "2px solid #3498DB",
                              padding: "3px 6px",
                              backgroundColor: "#3498DB35",
                              borderRadius: "5px",
                            }}
                            secnd={
                              <div className="IcnSiz" style={{ zoom: ".48" }}>
                                {CloseSvg}
                              </div>
                            }
                            first={
                              <div className="StSmlS StwdMaxC">
                                {Translate("statuspickup", elem)}
                              </div>
                            }
                          />
                        </div>
                      );
                    })}
                  </Fragment>
                )}
              </div>
              {PickupUpdateStatus === "0" && <Loader />}
              {PickupGetting === "1" ? (
                <TableLoader />
              ) : PickupGetting === "2" &&
                this.state.count === 0 &&
                this.props.location.search &&
                (this.props.location.search.replace(
                  "?page=" + this.props.page,
                  ""
                ) ||
                  this.props.location.search.replace(
                    "&page=" + this.props.page,
                    ""
                  )) !== "" ? (
                <div className="RlPs">
                  <EmptyScreen
                    titleGlb={Translate("pickups", "nopickup")}
                    TextShow={Translate("orders", "filterempty")}
                    TxtBtn={Translate("orders", "resetfilter")}
                    FuncCall={this.resetFilter}
                  />
                </div>
              ) : (
                <div>
                  <Table
                    thead_elem={PICKUPS_TABLE_HEADER}
                    tbody_elem={bodytab}
                    stickySecnd={false}
                    goTo={this.Gotodetail}
                  />
                  <div role="pagination" className="lytWdp SmMarS1">
                    <div className="InFlx flxDrc FlWd RlPs AlgnItm">
                      <span>
                        {Translate("orders", "showing") +
                          " " +
                          (page * 20 - 19) +
                          " - " +
                          (page * 20 < this.state.count
                            ? page * 20
                            : this.state.count) +
                          " " +
                          Translate("orders", "of") +
                          " " +
                          this.state.count}
                      </span>
                      <PaginationBar
                        className="StAutMr"
                        NbPage={Math.trunc(this.state.count / 20) + 1}
                        currentPage={parseInt(page)}
                        ChangePage={this.props.onChangePage}
                        blockNext={this.state.nextpage}
                        blockPrev={this.state.prevpage}
                      />
                    </div>
                  </div>
                </div>
              )}
              {this.state.alertdelet && (
                <TabShow
                  tab_title={Translate("profile", "confirmaction")}
                  wdth="550px"
                  NotCls={false}
                >
                  <div className="MrAot" style={{ width: "94%" }}>
                    <p className="StdirLn FlWd DltTpMar">
                      {Translate("pickups", "confirmdelete")}
                    </p>
                    <div className="InFlx flxDrc StMrtp MrAot StwdMaxC">
                      <div
                        className="StwdMaxC CrsPoi StWdDst"
                        style={{ height: "30px" }}
                        onClick={() => this.showOpt("alertdelet")}
                      >
                        <BtnLg text={Translate("orders", "no")} classSet={""} />
                      </div>
                      <div
                        className="StwdMaxC CrsPoi StWdDst"
                        style={{ height: "30px" }}
                        onClick={this.CancelPickup}
                      >
                        <BtnLg
                          text={Translate("orders", "yes")}
                          classSet={"TxDng"}
                        />
                      </div>
                    </div>
                  </div>
                </TabShow>
              )}
            </div>
          )}
        </ContainerPage>
      );
    } else {
      return (
        <ContainerPage page_title={Translate("titles", "pickups")}>
          <FeatureLoked />
        </ContainerPage>
      );
    }
  }
}
function mapState(state) {
  const {
    PickupGetting,
    PickupsList,
    PickupUpdateStatus,
    DataUpdateStatus,
    PickupOrdersGetting,
    PickupOrders,
    postingbordereauPickup,
  } = state.pickups;
  const { dtStoreinfo, dataProfile } = state.user;
  return {
    PickupGetting,
    PickupsList,
    PickupOrdersGetting,
    PickupOrders,
    PickupUpdateStatus,
    DataUpdateStatus,
    dataProfile,
    dtStoreinfo,
    postingbordereauPickup,
  };
}

const actionCreators = {
  GetPickups: pickupsActions.GetPickups,
  GetPickupOrders: pickupsActions.GetPickupOrders,
  UpdatePickupStatus: pickupsActions.UpdatePickupStatus,
  SendAlert: alertActions.SendAlert,
  GetAllProduct: productActions.GetAllProduct,
  ClearStore: commonActions.ClearStore,
  ClearPickupsStore: pickupsActions.ClearPickupsStore,
  PrintBordereauPickup: pickupsActions.PrintBordereauPickup,
};
const connectedPickupsPage = connect(mapState, actionCreators)(PickupsPage);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedPickupsPage);
export { QueryProps as PickupsPage };
