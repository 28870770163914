import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Translate,
  FormatCurrency,
  FormatDate,
} from "../../../utils/lang/translate";
import StatusElement from "../_Common/StatusElement";
import Table from "../_Common/Table";
import GroupeBtn from "../_Common/GroupeBtn";
import GroupeBtnSp from "../_Common/GroupeBtnSp";
import {
  DeletSvg,
  EditSvg,
  MoneySvg,
  FlechDwnSvg,
  CloseSvg,
  PrintSvg,
  SerchSVg,
  AddSvg,
} from "../_Common/IconSvg";
import BtnLg from "../_Common/BtnLg";
import TabShow from "../_Common/TabShow";
import { searchActions, ordersActions, commonActions } from "../../../actions";
import TableLoader from "../_Common/TableLoader";
import CheckBoxForm from "../../_Common/CheckBoxForm";
import SmallText from "../_Common/SmallText";
import PaginationBar from "../_Common/PaginationBar";
import { FeatureLoked } from "../_Common/FeatureLoked";
import ContainerPage from "../_Common/ContainerPage";
import Loader from "../../_Common/Loader";
import DropedList from "../_Common/DropedList";
import PropTypes from "prop-types";
import { addUrlProps, UrlQueryParamTypes } from "react-url-query";
import { Event, initGAPg } from "../_Common";
import { SEARCH_TABLE_HEADER } from "../../constants";
const urlPropsQueryConfig = {
  search: { type: UrlQueryParamTypes.string },
  page: { type: UrlQueryParamTypes.number },
  websource: { type: UrlQueryParamTypes.string },
};

class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertdelet: null,
      allSelect: null,
      premmision:
        this.props.dataProfile.is_store || this.props.dataProfile.view_order,
      premisionedit:
        this.props.dataProfile.is_store || this.props.dataProfile.change_order,
    };
    this.crntfilter = "";
    this.selectIdlist = [];
    this.handleChange = this.handleChange.bind(this);
    this.Gotodetail = this.Gotodetail.bind(this);
    this.CloseSearch = this.CloseSearch.bind(this);
    this.RemoveOrder = this.RemoveOrder.bind(this);
    this.handleUseSelect = this.handleUseSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.NewPrint = this.NewPrint.bind(this);
  }
  static defaultProps = {
    search: "",
    page: 1,
  };
  static propTypes = {
    search: PropTypes.string,
    page: PropTypes.number,
    websource: PropTypes.string,
    /********************** */
    onChangeSearch: PropTypes.func,
    onChangePage: PropTypes.func,
    onChangeWebsource: PropTypes.func,
  };
  componentWillReceiveProps(nextProps) {
    if (this.state.premmision) {
      const { page, search, OrderUpdSta } = nextProps;
      if (page !== this.props.page) {
        this.exctuteFilterUrl(this.getFilterToState(nextProps));
      }
      if (search !== this.props.search && search && search.length >= 3) {
        this.exctuteFilterUrl(this.getFilterToState(nextProps));
      }
      if (this.props.OrderUpdSta === "0" && OrderUpdSta === "1") {
        this.exctuteFilterUrl(this.getFilterToState(), true);
      }
    }
  }
  componentDidMount() {
    document.title = "Maystro Delivery - " + Translate("titles", "search");
    initGAPg();
    if (
      this.props.search.length >= 3 &&
      (this.props.Searching !== "1" || this.props.OrderUpdating === "2") &&
      this.state.premmision
    ) {
      this.exctuteFilterUrl(this.getFilterToState());
    }
  }
  getFilterToState(props) {
    let Filter = props ? props.location.search : this.props.location.search;
    return Filter;
  }
  exctuteFilterUrl(nextFilter, force) {
    let prevFilter = this.crntfilter;
    if (prevFilter !== nextFilter || force) {
      this.crntfilter = nextFilter;
      document.getElementById("Root_search").scrollTo(0, 0);
      this.props.SearchOrders(nextFilter);
    }
  }
  RemoveOrder(id) {
    let idremove = this.state.alertdelet;
    if (idremove) {
      this.props.UpdateStaOrder({ status: 50 }, idremove);
      this.setState({
        alertdelet: null,
      });
    } else {
      this.setState({
        alertdelet: id,
      });
    }
  }
  EditOrder(path, pop) {
    const { dtStoreinfo, DataSearch } = this.props;
    if (pop) {
      let bonwindow = window.open(path);
      bonwindow.propsended = dtStoreinfo.is_store
        ? dtStoreinfo
        : { name: dtStoreinfo.store_name, phone: dtStoreinfo.store_phone };
      bonwindow.more = this.state.allSelect
        ? DataSearch.ressuc.results.map((elem) => {
            return elem.display_id;
          })
        : this.selectIdlist;
      bonwindow.bonwindow = DataSearch.ressuc.results;
    } else {
      this.props.history.push(path);
    }
  }

  NewPrint() {
    const orders_ids_ = this.state.allSelect
      ? this.props.DataSearch.ressuc.results.map((elem) => {
          return elem.display_id;
        })
      : this.selectIdlist;

    this.props.GenerateOrdersBordureauStarter(orders_ids_, false);
  }

  Gotodetail(id) {
    this.props.history.push("/order/detail/" + id + "?source=search");
  }
  handleChange(e) {
    const { value } = e.target;
    this.props.onChangeSearch(value);
  }
  CloseSearch() {
    Event("SEARCH", "CLOSE_SEARCH_PAGE", "CLICK_EVENT");
    this.props.ClearStore("CLEAR_SEARCH");
    this.props.history.push(
      this.props.websource ? this.props.websource : "/orders"
    );
  }
  handleUseSelect(e) {
    const { name } = e.target;
    let cuurent = this.selectIdlist;
    let idOrder = name;
    let index = cuurent.indexOf(idOrder);
    if (index === -1) {
      cuurent.push(idOrder);
    } else {
      cuurent.splice(index, 1);
    }
    this.selectIdlist = cuurent;
    if (this.state.allSelect) {
      this.setState({
        allSelect: null,
      });
    } else {
      this.setState({ state: this.state });
    }
  }
  handleSelectAll(e) {
    this.setState({
      allSelect: this.state.allSelect ? null : true,
    });
  }
  render() {
    const { Searching, DataSearch, page, OrderUpdSta } = this.props;
    var bodytab = [];
    if (Searching === "1" && DataSearch && DataSearch.ressuc.results) {
      bodytab = DataSearch.ressuc.results.map((elem) => {
        const {
          id,
          display_id,
          product_name,
          customer_name,
          ordered_at,
          product_price,
          total_price,
          price,
          status,
          wilaya,
          commune_name,
          products,
        } = elem;

        const customer_will_pay = total_price !== null ? 
            total_price 
          : 
            Number(product_price) + Number(price)
          ;

        let checked =
          this.selectIdlist.indexOf(display_id) !== -1 || this.state.allSelect;
        let ordered_at2 = (
          <div style={{ minWidth: "95px" }}>{FormatDate(ordered_at)}</div>
        );
        let btns = "";
        if (this.state.premisionedit) {
          switch (status) {
            case 22:
            case 11:
              btns = (
                <GroupeBtn
                  data_btn={[
                    {
                      type: "BDgInpc",
                      action: () =>
                        this.EditOrder(
                          "/order/add/" + display_id + "?source=search"
                        ),
                      svgbtn: EditSvg,
                    },
                    {
                      type: "BDgInpc",
                      action: () => this.RemoveOrder(id),
                      svgbtn: DeletSvg,
                    },
                  ]}
                />
              );
              break;
            case 41:
              btns = (
                <GroupeBtn
                  data_btn={[
                    {
                      type: "BDgInpc",
                      action: () => this.Gotodetail("/payments"),
                      svgbtn: MoneySvg,
                    },
                  ]}
                />
              );
              break;
            case 50:
              btns = (
                <GroupeBtn
                  data_btn={[
                    {
                      type: "BDgInpc",
                      action: () =>
                        this.EditOrder(
                          "/order/add/" + display_id + "?source=search"
                        ),
                      svgbtn: AddSvg,
                    },
                  ]}
                />
              );
              break;
            default:
              break;
          }
        } else {
          btns = (
            <GroupeBtn
              data_btn={[
                {
                  type: "BDgInpc",
                  action: () =>
                    this.props.GenerateOrdersBordureauStarter([display_id]),
                  svgbtn: PrintSvg,
                  tooltip: Translate("tooltips", "newformatpdf"),
                },
              ]}
            />
          );
        }
        let productsf = "";
        let blockindex = [0, 6, 8];
        if (Array.isArray(products) && products.length > 0) {
          if (products.length > 1) {
            let PrdctList = (
              <div onClick={() => this.Gotodetail(display_id)}>
                {products.map((elem, key) => {
                  return (
                    <div key={key} className="Inlflx TxTrnf Mrtpvl">
                      <strong className="StPaddingVal">
                        {" (" + elem.quantity + ") "}
                      </strong>
                      <SmallText>{elem.logistical_description}</SmallText>
                    </div>
                  );
                })}
              </div>
            );
            productsf = (
              <DropedList
                props={{ ...this.props }}
                title={
                  <div className="InFlx AlgnItm RlPs">
                    <span>{products.length}</span>
                    <span className="StPaddingVal">
                      {Translate("titles", "product")}
                    </span>
                    <span className="DsPlCnt">{FlechDwnSvg}</span>
                  </div>
                }
                dataDrop={PrdctList}
              />
            );
            blockindex.push(1);
          } else {
            productsf = (
              <div className="Inlflx TxTrnf">
                <strong className="StPaddingVal">
                  {" (" + products[0].quantity + ") "}
                </strong>
                <SmallText>{products[0].logistical_description}</SmallText>
              </div>
            );
          }
        } else {
          productsf = product_name;
        }
        return [
          <CheckBoxForm
            name={display_id}
            workfun={this.handleUseSelect}
            check={checked}
            id={"order_print_" + display_id}
          />,
          display_id,
          productsf,
          wilaya + " ( " + commune_name + " ) ",
          customer_name,
          ordered_at2,
          <div>
            {this.props?.dtStoreinfo?.country?.id === 1
              ? FormatCurrency.format(Number(customer_will_pay))
              : Number(customer_will_pay) + "  " + "TND"}
          </div>,
          <StatusElement>{status}</StatusElement>,
          btns,
          { type: "forindex", blocked: blockindex, idaction: display_id },
        ];
      });
    }
    if (this.state.premmision) {
      return (
        <div className="FxPs FlWd FlHg StTpNl StlfNl BdgGc Zindx">
          <div
            className="sTpdwTd FlWd sTbxSz RlPs FlHg OvfAtoY"
            id="Root_search"
          >
            <div
              className="RlPs StPs StTpNl BdgGc sTbtpv Zindx"
              style={{ top: "-20px" }}
            >
              <div className="RlPs StdirLn StDrc">
                <h2>{Translate("search", "searchInf")}</h2>
                <div
                  className="StAbs StLanNl StTpNl FlHg CrsPoi StZoom"
                  onClick={this.CloseSearch}
                >
                  {CloseSvg}
                </div>
              </div>
              <div className="InFlx flxDrc">
                <div className="stbd2 sTmrGlf CrsPoi sTbrBtm">
                  <strong>{Translate("titles", "order")}</strong>
                </div>
                <div
                  className="Inlflx AlgnItm StDrc StFlxStr FlWd"
                  style={{ height: "48px" }}
                >
                  {(this.selectIdlist.length > 0 || this.state.allSelect) && (
                    <GroupeBtnSp
                      style={{ margin: "0", zoom: "1.2" }}
                      data_btn={[
                        {
                          type: "BDgInpc",
                          action: this.NewPrint,
                          svgbtn: PrintSvg,
                          tooltip: Translate("tooltips", "newformatpdf")
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
              <div className="InFlx AlgnItm ClSidTh SmMarS1 StBrdRd sTbtpv sTpfrIn flxDrc">
                <div className="InFlx StMrtg2">{SerchSVg}</div>
                <div className="FlWd">
                  <input
                    type="text"
                    onChange={this.handleChange}
                    className="BdgTrans StdirLn BtNset FlWd DlBrd Fntcl StSmlS"
                    value={this.props.search}
                    name="search_query"
                    placeholder={Translate("search", "Searchaboutanything")}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="FlWd RlPs">
              {OrderUpdSta === "0" && <Loader />}
              {Searching === "0" ? (
                <TableLoader />
              ) : Searching === "1" && DataSearch ? (
                <Fragment>
                  <Table
                    thead_elem={[
                      <CheckBoxForm
                        name="all_order"
                        check={this.state.allSelect}
                        workfun={this.handleSelectAll}
                        id="order_all_order_printed"
                      />,
                      ...SEARCH_TABLE_HEADER,
                    ]}
                    tbody_elem={bodytab}
                    goTo={this.Gotodetail}
                  />
                  <div role="pagination" className="lytWdp SmMarS1">
                    <div className="InFlx flxDrc FlWd RlPs AlgnItm">
                      <span>
                        {Translate("orders", "showing") +
                          " " +
                          (page * 20 - 19) +
                          " - " +
                          (page * 20 < DataSearch.ressuc.count
                            ? page * 20
                            : DataSearch.ressuc.count) +
                          " " +
                          Translate("orders", "of") +
                          " " +
                          DataSearch.ressuc.count}
                      </span>
                      <PaginationBar
                        className="StAutMr"
                        NbPage={Math.trunc(DataSearch.ressuc.count / 20) + 1}
                        currentPage={parseInt(page)}
                        ChangePage={this.props.onChangePage}
                        blockNext={DataSearch.ressuc.next}
                        blockPrev={DataSearch.ressuc.previous}
                      />
                    </div>
                  </div>
                </Fragment>
              ) : (
                ""
              )}
            </div>
          </div>
          {this.state.alertdelet && (
            <TabShow
              tab_title={Translate("profile", "confirmaction")}
              wdth="550px"
              NotCls={true}
            >
              <div className="MrAot" style={{ width: "94%" }}>
                <p className="StdirLn FlWd DltTpMar">
                  {Translate("orders", "confrmdelete")}
                </p>
                <div className="InFlx flxDrc StMrtp MrAot StwdMaxC">
                  <div
                    className="StwdMaxC CrsPoi StWdDst"
                    style={{ height: "30px" }}
                    onClick={() => this.showOpt("alertdelet")}
                  >
                    <BtnLg
                      text={Translate("profile", "cancel")}
                      classSet={""}
                    />
                  </div>
                  <div
                    className="StwdMaxC CrsPoi StWdDst"
                    style={{ height: "30px" }}
                    onClick={this.RemoveOrder}
                  >
                    <BtnLg
                      text={Translate("orders", "cancelorder")}
                      classSet={"TxDng"}
                    />
                  </div>
                </div>
              </div>
            </TabShow>
          )}
        </div>
      );
    } else {
      return (
        <ContainerPage page_title={Translate("search", "searchInf")}>
          <FeatureLoked />
        </ContainerPage>
      );
    }
  }
}
function mapState(state) {
  const { Searching, DataSearch } = state.search;
  const { OrderUpdSta, DataUpdSta, OrderUpdating } = state.orders;
  const { dataProfile, dtStoreinfo } = state.user;
  return {
    Searching,
    DataSearch,
    dataProfile,
    OrderUpdSta,
    DataUpdSta,
    dtStoreinfo,
    OrderUpdating,
  };
}

const actionCreators = {
  SearchOrders: searchActions.SearchOrders,
  UpdateStaOrder: ordersActions.UpdateStaOrder,
  ClearStore: commonActions.ClearStore,
  GenerateOrdersBordureauStarter: ordersActions.GenerateOrdersBordureauStarter,
};

const connectedSearchPage = connect(mapState, actionCreators)(SearchPage);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedSearchPage);
export { QueryProps as SearchPage };
