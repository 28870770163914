import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { FormatDate, Translate } from "../../../utils/lang/translate";
import ContainerPage from "../_Common/ContainerPage";
import { ExchangeListItem, RequestStatus } from "../../../types";
import { alertActions, exchangeActions } from "../../../actions";
import { EmptyScreen } from "../_Common/EmptyScreen";
import { FeatureLoked } from "../_Common/FeatureLoked";
import InlinedElement from "../_Common/InlinedElement";
import { CheckSvg, CloseSvg, FilterSvg2, FlechDwnSvg, OutSvg, PrintSvg, RefreshSvg } from "../_Common/IconSvg";
import { Event } from "../_Common";
import TableLoader from "../_Common/TableLoader";
import Table from "../_Common/Table";
import { useQueryParams, NumberParam, StringParam, encodeQueryParams, encodeDelimitedNumericArray, decodeDelimitedNumericArray } from "use-query-params";
import PaginationBar from "../_Common/PaginationBar";
import { RouterChildContext, useHistory } from "react-router-dom";
import StatusElementTootlip from "../_Common/StatusElementTooltip";
import GroupeBtn from "../_Common/GroupeBtn";
import { exchange_status_list, userConstants } from "../../../constants";
import CheckBoxForm from "../../_Common/CheckBoxForm";
import { stringify } from "query-string";
import Loader from "../../_Common/Loader";
import ExchangePrintModal from "./ExchangePrintModal";
import IconButton from "../_Common/IconButton";

interface ExchangePageProps {
  GettingExchanges: RequestStatus,
  GetExchanges: (filter?: string) => void,
  dataExchanges: { 
    count: number,
    results: ExchangeListItem [],
    next: string | null,
    previous: string | null
  },
  dataProfile: any,
  SendAlert: (code: string, text: string, action: string) => void,
  GenerateExchangesBordureauStarter: (exchanges_ids: string[], all_created: boolean) => void,
  postingbordereau: RequestStatus,
  dtStoreinfo: any,
  UpdateShowExchangePopups: (value: number) => void,
  UpdatingExchangePopups: RequestStatus
}

const CommaArrayParam = {
  encode: (array: (number | null)[] | null | undefined) =>
    encodeDelimitedNumericArray(array, ','),

  decode: (arrayStr: string | (string | null)[] | null | undefined) =>
    decodeDelimitedNumericArray(arrayStr, ',')
};

const Exchanges = ({
  GetExchanges,
  dataExchanges,
  GettingExchanges,
  dataProfile,
  SendAlert,
  GenerateExchangesBordureauStarter,
  postingbordereau,
  dtStoreinfo,
  UpdateShowExchangePopups,
  UpdatingExchangePopups
}: ExchangePageProps) => {

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    display_id_str__icontains: StringParam,
    status__in: CommaArrayParam,	
    type: NumberParam
  });

  const history = useHistory<RouterChildContext['router']['history']>();

  const FilterRef = useRef<HTMLDivElement>(null);
  const statusFilterRef= useRef<HTMLDivElement>(null);

  const [displayId, setDisplayId] = useState<string>("");
  const [refresh, setRefresh] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [exchangeToPrint, setExchangeToPrint] = useState<string | null>(null);
  
  useEffect(() => {
    
    const { page, display_id_str__icontains, status__in } = query;

    const encodedQuery = encodeQueryParams({
      page: NumberParam,
      display_id_str__icontains: StringParam,
      status__in: CommaArrayParam,	
      type: NumberParam
    }, { page, display_id_str__icontains, status__in, type: 2 });
  
    GetExchanges(`?${stringify(encodedQuery)}`); 
  }, [query.page, query.display_id_str__icontains, query.status__in, refresh]);

  useEffect(() => {
    
    if(GettingExchanges === "3"){
      SendAlert("50", Translate("exchanges", "failedtofetchexchanges"), "");
    }

    if(UpdatingExchangePopups === "3"){
      SendAlert("50", "failed to update", "");
    }

  }, [GettingExchanges]);

  const handleDisplayIdSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Event("EXCHANGES", "FILTER_EXCHANGES_BY_ID", "CLICK_EVENT");
    if(query.display_id_str__icontains) {
      setQuery(prev => ({ ...prev, display_id_str__icontains: undefined, page: undefined }));
      setDisplayId("");
    }
    else setQuery(prev => ({ ...prev, display_id_str__icontains: displayId, page: undefined }));
  }

  const ShowFilter = function() {
    Event("EXCHANGES", "CLICK_FILTER", "CLICK_EVENT");
    let clsList = document.getElementById("Filter_container");
    if (clsList && clsList.classList.value.indexOf("hidElem2") !== -1) {
      clsList.classList.remove("hidElem2");
      document.addEventListener("mousedown", handleClickOutsideFilter);
    } else {
      clsList?.classList.add("hidElem2");
      document.removeEventListener("mousedown", handleClickOutsideFilter);
    }
  }

  const handleClickOutsideFilter = function(event: MouseEvent) {
    if (
      FilterRef &&
      FilterRef.current &&
      !FilterRef.current.contains(event.target as Node)
    ) {
      let clsList = document.getElementById("Filter_container");
      if (clsList && !clsList.classList.value.includes("hidElem2")){
        clsList?.classList.add("hidElem2");
      }
    }
  }

  const handleDisplayIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDisplayId(e.target.value);
  }

  const clearFilters = () => {  
    setQuery(prev => ({ ...prev, status__in: undefined, page: undefined }));
  };

  const onChangePage = (pageNumber: number) => {
    setQuery(prev => ({ ...prev, page: pageNumber }));
  }

  const refreshPage = () => {
    Event("EXCHANGES_PAGE", "CLICK_REFRESH_EXCHANGES_PAGE_BUTTON", "CLICK_EVENT");
    setRefresh(prev => !prev)
  };

  const ControlFilter = (filterId: string, ref: React.RefObject<HTMLDivElement>) => {
    let clsList = document.getElementById(filterId);
    console.log(ref)
    if(clsList){
      if (clsList.classList.value.indexOf("hidElem3") !== -1) {
        clsList.classList.remove("hidElem3");
        clsList.style.maxHeight = "400px";
        document.addEventListener(
          "mousedown", 
          (e) => {
            if (
              ref &&
              ref.current &&
              !ref.current.contains(e.target as Node)
            ) {
              let clsList = document.getElementById(filterId);
              if(clsList && !clsList.classList.value.includes("hidElem3")){
                clsList.style.maxHeight = "0px";
                clsList.classList.add("hidElem3");
              }
            }
          }
        );
      } else {
        clsList.style.maxHeight = "0px";
        clsList.classList.add("hidElem3");
        document.removeEventListener(
          "mousedown", 
          (e) => {
            if (
              ref &&
              ref.current &&
              !ref.current.contains(e.target as Node)
            ) {
              let clsList = document.getElementById(filterId);
              if(clsList && !clsList.classList.value.includes("hidElem3")){
                clsList.style.maxHeight = "0px";
                clsList.classList.add("hidElem3");
              }
            }
          }
        );
      }
    }
  };

  const onChangeStatus = (status: number) => {
    setQuery(prev => {
      if(prev.status__in){
        if(prev.status__in.includes(status)) {
          if(prev.status__in.length === 1) return { ...prev, status__in: undefined, page: undefined };
          else return { ...prev, status__in: prev.status__in.filter(item => item !== status), page: undefined };
        }
        else return { ...prev, status__in: [...prev.status__in, status], page: undefined }
      }else return { ...prev, status__in: [status], page: undefined };
    });
  };

  const handlePrintActionClick = (exchangeId: string) => {
    Event("EXCHANGES_PAGE", "CLICK_PRINT_EXCHANGE_RETURN_BUTTON", "CLICK_EVENT");
    if(
      dtStoreinfo?.show_exchange_popups === userConstants.SHOW_BOTH_EXCHANGE_POPUPS
      || dtStoreinfo?.show_exchange_popups === userConstants.SHOW_ONLY_EXCHANE_MODAL_POPUP
    ){
      setExchangeToPrint(exchangeId);
      setShowPrintModal(true);
    }else{
      GenerateExchangesBordureauStarter([exchangeId], false);  
    }
  }

  return (
    <ContainerPage
      page_title={Translate("exchanges", "exchanges")}
    >
      {postingbordereau === "1" && <Loader />}
      {
        (dataProfile.is_store || dataProfile.view_order)
        ?
        <>
          {
            (GettingExchanges && GettingExchanges !== "3")
            ?
              <div>
                <div
                  className="InFlx flxDrc StmpB3 StPs zindX2 BdgGc"
                  style={{ height: "48px", padding: "7px 0", top: "-20px" }}
                >
                  {/* Filters + display_id search */}
                  <div role="filter-data" className="FlHg InFlx StwdMaxC">
                    {/* filters */}
                    <div
                      className="FlHg StMrtg2 InFlx AlgnItm"
                      ref={FilterRef}
                    >
                      <IconButton 
                        clickHandler={ShowFilter} 
                        icon={FilterSvg2} 
                        className="BdgBlcl"
                      />
                      <div
                        id="Filter_container"
                        className="StAbs Zindxsm StLanNlin StBxSh1 StwdMaxC stTranEs StTpval3 HdOvrfl ClSidTh StPdsml StBgbrds hidElem2"
                        style={{ width: "350px" }}
                      >
                        <div id="Sub_Container_filter">
                          <div style={{ marginTop: "8px" }}>
                            <div className="RlPs">
                              <InlinedElement
                                secnd={
                                  <strong>{Translate("orders", "filter")}</strong>
                                }
                                leftElm={
                                  <div
                                    onClick={ShowFilter}
                                    className="InFlx CrsPoi IcnSizSm"
                                  >
                                    {CloseSvg}
                                  </div>
                                }
                                style={{ width: "100%", padding: "0px" }}
                              />
                            </div>
                            {
                              Object.keys(query).filter((key) => {
                                if(key === "page" || key === "display_id_str__icontains" || key === "type") return false;
                                if(key === "status__in" && !query.status__in ) return false;
                                else return true; 
                              }).length > 0
                              &&
                              <span
                                className="CrsPoi StBle DsBlc StdirLn"
                                onClick={clearFilters}
                              >
                                {Translate("orders", "resetfilter")}
                              </span>
                            }
                          </div>
                          <div
                            ref={statusFilterRef}
                            className="BdgClN StBrdRdS StPdsml SmMarS1"
                          >
                            <div
                              className="StHeivl RlPs CrsPoi"
                              onClick={() => ControlFilter("Status_Container", statusFilterRef)}
                            >
                              <InlinedElement
                                secnd={
                                  <strong>
                                    {Translate("exchanges", "exchangestatus")}
                                  </strong>
                                }
                                leftElm={
                                  <div className="InFlx">{FlechDwnSvg}</div>
                                }
                                style={{ width: "100%", padding: "0px" }}
                              />
                            </div>
                            <div
                              id="Status_Container"
                              className="hidElem3 stTranEs"
                              style={{ overflowY: "auto" }}
                            >
                              <div>
                                {
                                  Object.values(exchange_status_list)
                                  .map((value, index) => (
                                    <CheckBoxForm
                                      key={index}
                                      id={"S" + value}
                                      name={"S" + value}
                                      text={Translate("exchanges", value)}
                                      workfun={() => {
                                        Event("EXCHANGES_PAGE", `SELECT_EXCHANGE_STATUS_${value}`, "CLICK_EVENT");
                                        onChangeStatus(value);
                                      }}
                                      check={query.status__in?.includes(value)}
                                    />
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* search with display id */}
                    <form onSubmit={handleDisplayIdSubmit}>
                      <div className="FlHg StBrdRdS ClSidTh">
                        <InlinedElement
                          style={{ padding: "0" }}
                          secnd={
                            <div>
                              <input
                                type="text"
                                autoComplete="off"
                                onChange={handleDisplayIdChange}
                                name="filterbyid"
                                style={{ width: "300px", maxWidth: "30vw" }}
                                className="StdirLn FntfMin StPdsml BdgTrans DlBrd StSizLn Fntcl"
                                value={query.display_id_str__icontains ?? displayId}
                                placeholder={Translate("orders", "filterbyid")}
                              />
                            </div>
                          }
                          leftElm={
                            <button
                              className="BdgGc DlBrd CrsPoi Inlflx StBgbrd"
                              style={{ padding: "10px" }}
                            >
                              { query.display_id_str__icontains ? OutSvg : CheckSvg }
                            </button>
                          }
                        />
                      </div>
                    </form>
                  </div>
                
                  {/* Refresh button + import/export (incoming)  */}
                  <div ref={null}>
                    <div
                      className="FlWd InFlx"
                      style={{ height: "48px", gap: "12px", margin: "0 20px" }}
                    >
                      <IconButton
                        clickHandler={refreshPage}
                        icon={RefreshSvg}
                      />
                    </div>
                  </div>
                </div>

                {/* Selected filters display section */}
                <div className="InFlx flxDrc StmpB3 StOpcVal StFlxWrp2 FlwMax AlgnItm">
                  {
                    query.status__in && query.status__in.length > 0
                    &&
                    <>
                      <div className="stBdp StSmlS StwdMaxC">
                        {Translate("exchanges", "exchangestatus")}
                      </div> 
                        {
                          query.status__in?.map((status, index) => (
                            <div
                              key={index}
                              onClick={() => {
                                Event("EXCHANGES_PAGE", `CLEAR_EXCHANGE_STATUS_${status}`, "CLICK_EVENT");
                                onChangeStatus(status ?? -1);
                              }}
                              className="stBdp CrsPoi SthgMaxC"
                            >
                              <InlinedElement
                                style={{
                                  border: "2px solid #3498DB",
                                  padding: "3px 6px",
                                  backgroundColor: "#3498DB35",
                                  borderRadius: "5px",
                                }}
                                secnd={
                                  <div className="IcnSiz" style={{ zoom: ".48" }}>
                                    {CloseSvg}
                                  </div>
                                }
                                first={
                                  <div className="StSmlS StwdMaxC">
                                    {Translate("exchanges", status)}
                                  </div>
                                }
                              />
                            </div>
                          ))
                        }
                    </>
                  }
                </div> 
                {
                  GettingExchanges === "1"
                  ?
                    <TableLoader />
                  :
                    (GettingExchanges === "2" && dataExchanges.results.length === 0)
                    ?
                      <EmptyScreen
                        titleGlb={Translate("exchanges", "noexchange")}
                        TextShow={Translate("exchanges", "noexchangeyet")}
                        PictShow={"/assets/img/exchange.png"}
                        actionBtns={
                          Object.keys(query).filter(key => key !== "page" && key !== "type").length === 0
                          ?
                            []
                          :
                            [
                              {
                                text: Translate("orders", "resetfilter"),
                                to: "/exchanges",
                              }
                            ]
                        }
                      />
                    :
                      <div>
                        <Table
                          thead_elem={[
                            Translate("product", "id"),
                            Translate("orders", "status"),
                            Translate("orders", "creationdate"),
                            Translate("product", "action"),
                          ]}
                          tbody_elem={
                            dataExchanges.results.map((exchangeItem, _) => {
                              
                              let action_btns = [];
                              if(exchangeItem.status === exchange_status_list.EXCHANGE_STATUS_CREATED){
                                action_btns.push(
                                  {
                                    type: "BDgInpc",
                                    action: () => handlePrintActionClick(exchangeItem.id),
                                    svgbtn: PrintSvg,
                                    tooltip: Translate("tooltips", "printexchangereturnslip"),
                                    notif: exchangeItem.times_printed === 0
                                  }
                                );
                              };

                              return [
                                <div
                                  className="InFlx Stclmnf CrsPoi"
                                  onClick={() =>
                                    history.push(
                                      "/exchange-refund/" + exchangeItem.id
                                    )
                                  }
                                >
                                  {exchangeItem.display_id_str}
                                </div>,
                                <div
                                  onClick={() =>
                                    Event(
                                      "EXCHANGES",
                                      "CLICK_IN_STATUS_EXCHANGE_LIST_" + exchangeItem.status,
                                      "CLICK_EVENT"
                                    )
                                  }
                                >
                                  {/* this needs to be checked agin */}
                                  <StatusElementTootlip
                                    classes="exchange"
                                    category="exchanges"
                                    data_btn={[
                                      {
                                        action: () =>
                                          history.push(
                                            "/exchange-refund/" + exchangeItem.id
                                          ),
                                        svgbtn: exchangeItem.status,
                                      }
                                    ]}
                                    status={exchangeItem.status}
                                  />
                                </div>,
                                <div
                                  className="InFlx Stclmnf CrsPoi"
                                  onClick={() =>
                                    history.push(
                                      "/exchange-refund/" + exchangeItem.id
                                    )
                                  }
                                >
                                  {FormatDate(exchangeItem.created_at)}
                                </div>,
                                <GroupeBtn data_btn={action_btns} />
                              ]
                            })
                          }
                          stickySecnd={true}
                        />

                        <div role="pagination" className="lytWdp SmMarS1">
                          <div className="InFlx flxDrc FlWd RlPs AlgnItm">
                            <span className="d-large">
                              {
                                Translate("orders", "showing") +
                                " " +
                                ((query.page ?? 1) * 20 - 19) +
                                " - " +
                                (
                                  (query.page ?? 1) * 20 < dataExchanges.count ?? 0
                                  ? (query.page ?? 1) * 20
                                  : dataExchanges.count ?? 0
                                ) +
                                " " +
                                Translate("orders", "of") +
                                " " +
                                dataExchanges.count ?? 0
                              }
                            </span>
                            <PaginationBar
                              className="StAutMr"
                              NbPage={Math.trunc((dataExchanges.count ?? 0) / 20) + 1}
                              currentPage={query.page ?? 1}
                              ChangePage={onChangePage}
                              blockNext={dataExchanges.next}
                              blockPrev={dataExchanges.previous}
                            />
                          </div>
                        </div>

                        { 
                          (showPrintModal && exchangeToPrint)
                          &&
                          <ExchangePrintModal
                            onClose={() => setShowPrintModal(false)}
                            onPrint={
                              (neverShowAgain: boolean) => {
                                GenerateExchangesBordureauStarter([exchangeToPrint], false);
                                if(neverShowAgain){
                                  if(dtStoreinfo?.show_exchange_popups === userConstants.SHOW_BOTH_EXCHANGE_POPUPS){
                                    UpdateShowExchangePopups(1);
                                  }
                                  if(dtStoreinfo?.show_exchange_popups === userConstants.SHOW_ONLY_EXCHANE_MODAL_POPUP){
                                    UpdateShowExchangePopups(3);
                                  }
                                }
                              }
                            }
                          />
                        }
                      </div>
                }
              </div>
            :
              <div>
                error loading exchanges!!!
              </div>
          }
        </>
        :
        <FeatureLoked />
      }


    </ContainerPage>
  );
}
 
function mapState(state: any) {
  const { dataExchanges, GettingExchanges, UpdatingExchangePopups } = state.exchanges;
  const { dataProfile, dtStoreinfo } = state.user;
  const { postingbordereau } = state.orders;
  return { dataExchanges, GettingExchanges, dataProfile, postingbordereau, dtStoreinfo, UpdatingExchangePopups }
};

const actionCreators = {
  GetExchanges: exchangeActions.GetExchanges,
  SendAlert: alertActions.SendAlert,
  GenerateExchangesBordureauStarter: exchangeActions.GenerateExchangesBordureauStarter,
  UpdateShowExchangePopups: exchangeActions.UpdateShowExchangePopups
}

const connctedExchangesPage = connect(mapState, actionCreators)(Exchanges);
export { connctedExchangesPage as Exchanges };