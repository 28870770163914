import React, { useEffect, useRef } from "react";

import { Chart, 
  ArcElement, 
  ChartOptions, 
  ChartData, 
  Plugin, 
  LinearScale, 
  LineElement, 
  PointElement, 
  Title, 
  Tooltip, 
  Legend, 
  DoughnutController 
} from 'chart.js';

import { Doughnut } from 'react-chartjs-2';
import { AnyObject } from "chart.js/types/basic";
import Cookies from "js-cookie";
import { Translate } from "../../../utils/lang/translate";

interface RewardsTierChartProps {
  pointsArray: number[],
  pointsPerTierArray :number[],
  deliveriesNumber: number,
  tierNames: Array<"Member" | "Friend" | "Best Friend" | "Family" | "Ambassador">,
  backgroundColors: string[][]
}

const RewardsTierChart = ({ 
  pointsArray, 
  pointsPerTierArray, 
  deliveriesNumber, 
  tierNames, 
  backgroundColors 
}: RewardsTierChartProps) => {
  
  const doughnutRef = useRef<Chart<"doughnut">>()

  Chart.register(LinearScale, ArcElement, LineElement, PointElement, Title, Tooltip, Legend, DoughnutController);

  let accumulatedDeliveries = 0;
  const accumulativeDeliveriesNumber: number[] = pointsPerTierArray.map((value, _) => {
    accumulatedDeliveries += value
    return accumulatedDeliveries;
  });

  const data: ChartData<"doughnut"> = {
    labels: tierNames,
    datasets: [{
      data: pointsPerTierArray.map((_, __) => 1),
      borderWidth: 0,
      borderRadius: 3,
      spacing: 5,
    }]
  };
  
  const options: ChartOptions<"doughnut"> = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false
      },
    },
    circumference: 180,
    rotation: -90,
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    cutout: '82.5%',
    radius: '66%',
    responsive: true
  };
  
  const textCenter: Plugin<"doughnut", AnyObject> = {
    id: "doughnut-center-text",
    beforeDatasetDraw: function (chart, _, __) {
      const { ctx, data } = chart;
      const theme = Cookies.get("theme");
      ctx.save();
      ctx.font = "bold 36px Nunito Sans";
      ctx.fillStyle = theme === "0" ? "#000" : "#fff";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        String(deliveriesNumber), 
        chart.getDatasetMeta(0).data[0].x, 
        chart.getDatasetMeta(0).data[0].y - 50
      );
      ctx.restore(); 
      ctx.save();
      ctx.font = "16px Nunito Sans";
      ctx.fillStyle = theme === "0" ? "#000" : "#E9EAEC";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        Translate("RewardsPage", "Deliveries"), 
        chart.getDatasetMeta(0).data[0].x, 
        chart.getDatasetMeta(0).data[0].y - 15
      );
    }
  }

  const createGradients = (chart: Chart<"doughnut">, originalColor: string) => {
    const ctx = chart.ctx;
    const gradients: CanvasGradient[] = [];
    const meta = chart.getDatasetMeta(0);

    chart.data.datasets[0].data.forEach((_, index) => {
      // Create a gradient for each segment here
      const element = meta.data[index];
      const model = element.getProps(["current"]);
      const { x, y, innerRadius, outerRadius, startAngle, endAngle } = model;

      // Calculate the percentage based on the current value and the corresponding value in the external data array
      const percentage = (pointsArray[index] / Number(pointsPerTierArray[index])) * 100;

      const gradient = ctx.createLinearGradient(
        Number(x) + Number(outerRadius) * Math.cos(Number(startAngle)), 
        Number(y) + Number(outerRadius) * Math.sin(Number(startAngle)), 
        Number(x) + Number(outerRadius) * Math.cos(Number(endAngle)), 
        Number(y) + Number(outerRadius) * Math.sin(Number(endAngle))
      );
      gradient.addColorStop(0, backgroundColors[index][0]);
      gradient.addColorStop(percentage/100, backgroundColors[index][1]);
      gradient.addColorStop(percentage/100, originalColor);     
      gradient.addColorStop(1, originalColor);
      gradients.push(gradient);
    });
  
    return gradients;
  };

  const updateChartDataWithGradients = (chart: Chart<"doughnut">, gradients: CanvasGradient[]) => {
    chart.data.datasets[0].backgroundColor = gradients;
    chart.update();
  };

  const CustomLabelsPlugin: Plugin<"doughnut", AnyObject> = {
    id: "custom-labels",
    afterDraw: (chart, _args, _) => {
      const { ctx, data } = chart;
      const theme = Cookies.get("theme");
      if (data.labels && data.labels.length > 0) {
        const meta = chart.getDatasetMeta(0);
        
        (data.labels as string[]).forEach((label, index) => {
          const element = meta.data[index];
          const model = element.getProps(["current"]);
          const { x, y, outerRadius, startAngle, endAngle } = model;

          let textX = Number(x) + (Number(outerRadius) + 20) * Math.cos(Number(startAngle));
          let textY = Number(y) + (Number(outerRadius) + 20) * Math.sin(Number(startAngle)) - 15;

          if(index !== 0){
            ctx.save();
            ctx.font = "14px Nunito Sans";
            ctx.fillStyle = theme === "0" ? "#000" : "#E9EAEC";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(label, textX, textY);
            ctx.restore();
          }

          if(label === "Family"){
            textX = Number(x) + (Number(outerRadius) + 35) * Math.cos(Number(endAngle));
            textY = Number(y) + (Number(outerRadius) + 35) * Math.sin(Number(endAngle)) - 20;
            ctx.save();
            ctx.font = "13px Nunito Sans";
            ctx.fillStyle = theme === "0" ? "#000" : "#E9EAEC";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText("Ambassador", textX, textY);
            ctx.restore();
          }

          if(label === "Best Friend"){
            textX = Number(x) + (Number(outerRadius) + 20) * Math.cos(Number(endAngle));
            textY = Number(y) + (Number(outerRadius) + 20) * Math.sin(Number(endAngle)) - 20;
            ctx.save();
            ctx.font = "13px Nunito Sans";
            ctx.fillStyle = theme === "0" ? "#000" : "#E9EAEC";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText("Family", textX, textY);
            ctx.restore();
          }

          let valueX = 0;
          let valueY = 0;

          // Display data values outside the doughnut
          if(label === "Family"){
            valueX = Number(x) + (Number(outerRadius) + 20) * Math.cos(Number(startAngle));
            valueY = Number(y) + (Number(outerRadius) + 20) * Math.sin(Number(startAngle));
          }else{
            valueX = Number(x) + (Number(outerRadius) + 20) * Math.cos(Number(endAngle));
            valueY = Number(y) + (Number(outerRadius) + 20) * Math.sin(Number(endAngle));
          }

          ctx.save();
          ctx.font = "14px Nunito Sans";
          ctx.fillStyle = theme === "0" ? "#000" : "#E9EAEC";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillText(
            label === "Family" ? String(2000) : String(accumulativeDeliveriesNumber[index]),
            valueX,
            valueY
          );
          ctx.restore();

          if(label === "Family"){
            valueX = Number(x) + (Number(outerRadius) + 20) * Math.cos(Number(endAngle));
            valueY = Number(y) + (Number(outerRadius) + 20) * Math.sin(Number(endAngle)) - 5;
            ctx.save();
            ctx.font = "14px Nunito Sans";
            ctx.fillStyle = theme === "0" ? "#000" : "#E9EAEC";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(
              "10000",
              valueX,
              valueY
            );
            ctx.restore();
          }

          if(index === 0){
            let valueX_0 = Number(x) + (Number(outerRadius) + 22) * Math.cos(Number(startAngle));
            let valueY_0 = Number(y) + (Number(outerRadius) + 22) * Math.sin(Number(startAngle)) - 20;
            ctx.save();
            ctx.font = "14px Nunito Sans";
            ctx.fillStyle = theme === "0" ? "#000" : "#E9EAEC";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(label, valueX_0, valueY_0);
            ctx.restore();
            if(label === "Member"){
              valueY_0 = Number(y) + (Number(outerRadius) + 22) * Math.sin(Number(startAngle)) - 5;
              ctx.save();
              ctx.font = "14px Nunito Sans";
              ctx.fillStyle = theme === "0" ? "#000" : "#E9EAEC";
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.fillText("0", valueX_0, valueY_0);
              ctx.restore();
            }
          }
          

          const gradients = createGradients(chart as Chart<"doughnut">, theme === "0" ? "#dbdfe5" : "#2F4155");
          updateChartDataWithGradients(chart as Chart<"doughnut">, gradients);

        });
      }
    },
  };

  useEffect(() => {
    const cb = () => {
      doughnutRef.current?.update()
    }
    window.addEventListener('themeChanged', cb)
    return () => window.removeEventListener('themeChanged', cb)
  }, [doughnutRef.current])

  return (
    <div className="InFlx AlgnItm doughnut-chart-container">
      <Doughnut ref={doughnutRef} data={data} options={options} plugins={[textCenter, CustomLabelsPlugin]}/>
    </div>
  );
}
 
export default RewardsTierChart;