import React, { useEffect, useRef } from "react";
import TabShow from "../_Common/TabShow";
import { SoulmateSvg, closeIcon } from "../_Common/IconSvg";
import BtnLg from "../_Common/BtnLg";
import { Translate } from "../../../utils/lang/translate";

interface RewardsNewTierModalProps {
  onClose: () => void,
  newTierName: string,
  newTierStyles: { svg: JSX.Element, border: string, text: string, bgColor: string },
  validDate: string 
}

const RewardsNewTierModal = ({
  onClose,
  newTierName,
  newTierStyles,
  validDate
}: RewardsNewTierModalProps) => {

  const modalRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (e: MouseEvent) => {
    if(modalRef.current && !modalRef.current?.contains(e.target as Node)){
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <TabShow
      darkBg
      noReturn
      noPadding={true}
      style={{
        borderRadius: "8px",
        maxWidth: "450px"
      }}
    >
      <div 
        ref={modalRef} 
        className="InFlx Stclmnf" 
        style={{ padding: "32px", gap: "15px"}}
      >
        <div className="rewards-new-tier-modal-img-container">
          <img className="rewards-new-tier-modal-img" src="/assets/img/new_user.png" alt="..." />
        </div>
        <div className="InFlx StFlxStr" style={{ zIndex: 10 }}>
          <div
            onClick={onClose} 
            className="InFlx AlgnItm sb4R CrsPoi Gray" 
            style={{ padding: "8px" }}
          >
            {closeIcon}
          </div>
        </div>
        <div className="InFlx Stclmnf" style={{ gap: "24px" }}>
          <div className={`rewards-new-tier-card ${newTierStyles.bgColor} ${newTierStyles.border}`}>
            <h5 className="DlMg overview-title">{Translate("RewardsPage", "your_new_tier")}</h5>
            <div className="InFlx Stclmnf AlgnItm" style={{ gap: "5px" }}>
              <div style={{ marginTop: "5px" }}>{newTierStyles.svg}</div>
              <h2 className={`DlMg overview-current-tier ${newTierStyles.text}`}>{newTierName}</h2>
            </div>
            <h4 className="DlMg sTclPg overview-expires-date">{Translate("RewardsPage", "valid_until")} {validDate}</h4>
          </div>
          <div className="InFlx Stclmnf" style={{ gap: "8px" }}>
            <h3 className="DlMg overview-title">{Translate("RewardsPage", "congrats_level_up")}</h3>
            <p className="DlMg sTclPg overview-expires-date"> {Translate("RewardsPage", "access_benifits")} </p>
          </div>
          <div className="FlWd InFlx JstfCnt" style={{ padding: "10px 0" }}>
            <div onClick={onClose}>
              <BtnLg text="OK" style={{ padding: "7px 40px" }} />
            </div>
        </div>
        </div>
      </div>
    </TabShow>
  );
}
 
export default RewardsNewTierModal;