import React from "react";
import { BenefitsYourTierCarret, benefitsTierCheck, benefitsTierLock } from "../../_Common/IconSvg";
import { renderTierStyle } from "../../../../constants/rewards.constants";
import "../RewardsPage.css";
import { Translate } from "../../../../utils/lang/translate";

interface BenefitsProgressTierProps {
  locked: boolean,
  isCurrent: boolean,
  tierName: "Member" | "Friend" | "Best Friend" | "Family" | "Ambassador",
};

const BenefitsProgressTier = ({ locked, isCurrent, tierName }: BenefitsProgressTierProps) => {
  return (
    <div 
      className={`
        InFlx AlgnItm JstfCnt ClSidTh RlPs rewards-benefits-progress-tier
        ${ locked ? "benefits-locked-tier-border" : renderTierStyle[tierName].border }
        ${ isCurrent ? renderTierStyle[tierName].boxShadow : "" }
      `} 
    >
      {
        isCurrent
        ?
            <div className="StAbs InFlx Stclmnf AlgnItm rewards-benefits-your-tier-tooltip">
              <div 
                className={`
                  InFlx AlgnItm StWhSnwr rewards-benefits-your-tier-tooltip-content 
                  ${renderTierStyle[tierName].bgColorClass}
                `}
              >
                {Translate("RewardsPage", "yourtier")}
              </div>
              <BenefitsYourTierCarret color={renderTierStyle[tierName].carretColor}/>
            </div>
          :
            <div 
              className="StAbs StTpNl StRgNl BdgGc InFlx AlgnItm JstfCnt"
              style={{ 
                border: locked ? "1px solid var(--Fnpagnt)" : "1px solid #2BC194",
                borderRadius: "9999px",
                height: "18px",
                width: "18px",  
              }}
            >
              { locked ? benefitsTierLock :  benefitsTierCheck }
            </div>
      }
      {renderTierStyle[tierName].svg}
    </div>
  );
}
 
export default BenefitsProgressTier;