import { teamConstants } from "../constants/team.constants";
import { RequestStatus } from "../types";

interface TeamsState {
  teamMembersData: any,
  teamData: any
  GettingTeamMembersData: RequestStatus,
  GettingTeamData: RequestStatus,
  AddingTeamMember: RequestStatus | null,
  AddTeamMemberData: any,
  UploadingTeamMemberPic: RequestStatus,
  teamMemberPicture: string | null,
  DeletingTeamMember: RequestStatus | null,
  GettingTeamMember: RequestStatus,
  teamMemberData: any,
  UpdatingTeamMember: RequestStatus | null,
  updateTeamMemberData: any,
  dataToggleTeamMember: any
};

interface TeamsAction {
  type: any,
  payload: any
}

export function teams(state: TeamsState, action: TeamsAction): TeamsState {
  switch(action.type){
    case teamConstants.GET_TEAM_MEMBERS_REQUEST:
      return {
        ...state,
        GettingTeamMembersData: "1",
        teamMembersData: state?.teamMembersData
      };
      
    case teamConstants.GET_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        GettingTeamMembersData: "2",
        teamMembersData: action.payload
      };
    
    case teamConstants.GET_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        GettingTeamMembersData: "3",
        teamMembersData: state?.teamMembersData
      };
    
    case teamConstants.GET_TEAM_DATA_REQUEST:
      return {
        ...state,
        GettingTeamData: "1",
        teamData: state?.teamData
      };
    
    case teamConstants.GET_TEAM_DATA_SUCCESS:
      return {
        ...state,
        GettingTeamData: "2",
        teamData: action.payload
      };

    case teamConstants.GET_TEAM_DATA_FAILURE:
      return {
        ...state,
        GettingTeamData: "3",
        teamData: state?.teamData
      };
    
    case teamConstants.ADD_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        AddingTeamMember: "1",
        AddTeamMemberData: state?.AddTeamMemberData
      };
    
    case teamConstants.ADD_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        AddingTeamMember: "2",
        AddTeamMemberData: action.payload
      };

    case teamConstants.ADD_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        AddingTeamMember: "3",
        AddTeamMemberData: action.payload
      };

    case teamConstants.UPLOAD_TEAM_MEMBER_PICTURE_REQUEST:
      return {
        ...state,
        UploadingTeamMemberPic: "1",
      };
    
    case teamConstants.UPLOAD_TEAM_MEMBER_PICTURE_SUCCESS:
      return {
        ...state,
        UploadingTeamMemberPic: "2",
        teamMemberPicture: action.payload
      };

    case teamConstants.UPLOAD_TEAM_MEMBER_PICTURE_FAILURE:
      return {
        ...state,
        UploadingTeamMemberPic: "3",
      };
    
    case teamConstants.TOGGLE_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        DeletingTeamMember: "1",
      };
    
    case teamConstants.TOGGLE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        DeletingTeamMember: "2",
        dataToggleTeamMember: action.payload
      };

    case teamConstants.TOGGLE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        DeletingTeamMember: "3",
      };
    
    case teamConstants.CLEAR_ADD_DELETE_STORE:
      return {
        ...state,
        DeletingTeamMember: null,
        AddingTeamMember: null
      };

    case teamConstants.GET_TEAM_MEMBER_DATA_REQUEST:
      return {
        ...state,
        GettingTeamMember: "1"
      };
    
    case teamConstants.GET_TEAM_MEMBER_DATA_SUCCESS:
      return {
        ...state,
        GettingTeamMember: "2",
        teamMemberData: action.payload
      };

    case teamConstants.GET_TEAM_MEMBER_DATA_FAILURE:
      return {
        ...state,
        GettingTeamMember: "3",
        teamMemberData: action.payload
      };
    
    case teamConstants.UPDATE_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        UpdatingTeamMember: "1"
      };
    
    case teamConstants.UPDATE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        UpdatingTeamMember: "2",
        updateTeamMemberData: action.payload,
      };

    case teamConstants.UPDATE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        UpdatingTeamMember: "3",
      }
    
    case teamConstants.CLEAR_UPDATE_STORE:
      return {
        ...state,
        UpdatingTeamMember: null,
        updateTeamMemberData: null
      };

    default:
      return {
        GettingTeamMembersData: state?.GettingTeamMembersData,
        teamMembersData: state?.teamMembersData,
        teamData: state?.teamData,
        GettingTeamData: state?.GettingTeamData,
        AddingTeamMember: state?.AddingTeamMember,
        AddTeamMemberData: state?.AddTeamMemberData,
        UploadingTeamMemberPic: state?.UploadingTeamMemberPic,
        DeletingTeamMember: state?.DeletingTeamMember,
        GettingTeamMember: state?.GettingTeamMember,
        teamMemberData: state?.teamMemberData,
        UpdatingTeamMember: state?.UpdatingTeamMember,
        updateTeamMemberData: state?.updateTeamMemberData,
        teamMemberPicture: state?.teamMemberPicture,
        dataToggleTeamMember: state?.dataToggleTeamMember
      };
  };
}