import React, { useEffect, useRef } from "react";
import TabShow from "../_Common/TabShow";
import { CoinSvg, FriendTierSvg, closeIcon } from "../_Common/IconSvg";
import BtnLg from "../_Common/BtnLg";
import { Translate } from "../../../utils/lang/translate";

interface RewardsOverveiwModalProps {
  onClose : () => void
};

const RewardsOverviewModal = ({ onClose }: RewardsOverveiwModalProps) => {

  const items = [
    { title: Translate("RewardsPage", "overview_modal_title1"), titleIcon: CoinSvg, text: Translate("RewardsPage", "overview_modal_text1")},
    { title: Translate("RewardsPage", "overview_modal_title2"), titleIcon: FriendTierSvg, text: Translate("RewardsPage", "overview_modal_text2")},
    { title: Translate("RewardsPage", "overview_modal_title3"), titleIcon: null, text: Translate("RewardsPage", "overview_modal_text3")},
  ];

  const modalRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (e: MouseEvent) => {
    if(modalRef.current && !modalRef.current?.contains(e.target as Node)){
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <TabShow
      darkBg
      noReturn
      noPadding={true}
      style={{
        minWidth: "50vw",
        borderRadius: "8px",
      }}
    >
      <div 
        ref={modalRef} 
        className="InFlx Stclmnf reward-modals" 
        style={{ padding: "20px", gap: "5px"}}
      >
        <div className="InFlx StFlxStr">
          <div
            onClick={onClose} 
            className="InFlx AlgnItm sb4R CrsPoi Gray" 
            style={{ padding: "8px" }}
          >
            {closeIcon}
          </div>
        </div>
        <div className="FlWd InFlx Stclmnf" style={{ gap: "32px" }}>
          { 
            items.map((item, index) => (
              <div key={index} className="InFlx FlWd Stclmnf align-items-start" style={{ gap: "12px" }}>
                <div className="InFlx" style={{ gap: "12px" }}>
                  <h3 className="DlMg rewards-overview-title">{ item.title }</h3>
                  <div>{item.titleIcon ?? null}</div>
                </div>
                <p className="DlMg sTclPg rewards-overview-modal-text">
                  { item.text }
                </p>
              </div>
            )) 
          }
        </div>
        <div className="FlWd InFlx JstfCnt" style={{ padding: "10px 0" }}>
          <div onClick={onClose}>
            <BtnLg text={Translate("RewardsPage", "understood")} style={{ padding: "7px 21px" }} />
          </div>
        </div>
      </div>
    </TabShow>
  );
}
 
export default RewardsOverviewModal;