import React from "react";
import DisabledAccountSvg from "./DisabledAccountSvg";
import BtnLg from "../../StorePages/_Common/BtnLg";
import { Translate } from "../../../utils/lang/translate";
import { RouterChildContext, useHistory } from "react-router-dom";
import "./DisabledAccount.css";

const DisabledAccount = () => {

  const history = useHistory<RouterChildContext['router']['history']>();

  return (
    <div style={{ backgroundColor: "#1B2431", color: "white" }}>
      <div 
        className="min-h-screen InFlx Stclmnf AlgnItm JstfCnt" 
        style={{ gap: "50px", maxWidth: "385px", margin: "0 auto" }}
      >
        <DisabledAccountSvg/>
        <div className="InFlx Stclmnf AlgnItm" style={{ gap: "16px" }}>
          <h2 className="DlMg disabled-account-title">{Translate("auths", "accountdisabled")}</h2>
          <p className="DlMg disabled-account-text StOpcVal">{Translate("auths", "hasbeendisabled")}</p>
          <div
            className="Inlflx AlgnItm JstfCnt CrsPoi"
            onClick={() => history.push("/login")}
          >
            <BtnLg
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 7px"
              }} 
              text={Translate("importorder", "goback")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default DisabledAccount;