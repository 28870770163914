import { dashboardConstants } from '../constants';
export function dashboard(state , action) {
	switch (action.type) {
		case dashboardConstants.GET_DASHBOARD_MAIN_STATS_REQUEST: 
			return {
				...state,
				GettingMainStats: "1"
			}
		case dashboardConstants.GET_DASHBOARD_MAIN_STATS_SUCCESS:
			return {
				...state,
				GettingMainStats: "2",
				dashboardStats : action.payload
			}
		case dashboardConstants.GET_DASHBOARD_MAIN_STATS_FAILURE:
			return {
				...state,
				GettingMainStats: "3",
				dashboardStats : action.payload
			}

		case dashboardConstants.GET_DASHBOARD_NOTIFICATIONS_REQUEST:
			return {
				...state,
				GettingDashboardNotifs: "1",
			}
		case dashboardConstants.GET_DASHBOARD_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				GettingDashboardNotifs: "2",
				dashboardNotifications : action.payload
			}
		case dashboardConstants.GET_DASHBOARD_NOTIFICATIONS_FAILURE:
			return {
				...state,
				GettingDashboardNotifs: "3",
				dashboardNotifications : action.payload
			}
		case dashboardConstants.GET_TOP_PRODUCTS_REQUEST:
			return {
				...state,
				GettingTopProducts: "1",
			}
		case dashboardConstants.GET_TOP_PRODUCTS_SUCCESS:
			return {
				...state,
				GettingTopProducts: "2",
				topProductsData: action.payload
			}
		case dashboardConstants.GET_TOP_PRODUCTS_FAILURE:
			return {
				...state,
				GettingTopProducts: "3",
				topProductsData: action.payload
			}
		case dashboardConstants.GET_PERFORMANCE_REQUEST:
			return {
				...state,
				GettingPerformance: "1"
			}
		case dashboardConstants.GET_PERFORMANCE_SUCCESS:
			return {
				...state,
				GettingPerformance: "2",
				performanceData: action.payload
			}
		case dashboardConstants.GET_PERFORMANCE_FAILURE:
			return {
				...state,
				GettingPerformance: "3",
				performanceData: action.payload
			}
		case dashboardConstants.GET_DELIVERED_ORDERS_PER_DAY_REQUEST:
			return {
				...state,
				GettingDeliveryPerDay: "1",
			}
		case dashboardConstants.GET_DELIVERED_ORDERS_PER_DAY_SUCCESS:
			return {
				...state,
				GettingDeliveryPerDay: "2",
				deliveryPerDayData: action.payload
			}
		case dashboardConstants.GET_DELIVERED_ORDERS_PER_DAY_FAILURE:
			return {
				...state,
				GettingDeliveryPerDay: "3",
				deliveryPerDayData: action.payload
			}
		case dashboardConstants.GET_CONFIRMED_ORDERS_REQUEST:
			return {
				...state,
				GettingConfirmedOrders: "1"
			}
		case dashboardConstants.GET_CONFIRMED_ORDERS_SUCCESS:
			return {
				...state,
				GettingConfirmedOrders: "2",
				confirmedOrdersData: action.payload
			}
		case dashboardConstants.GET_CONFIRMED_ORDERS_REQUEST:
			return {
				...state,
				GettingConfirmedOrders: "3",
				confirmedOrdersData: action.payload
			}
		default:
			return { ...state }
	}
}