import React, { useState, Fragment, useEffect } from "react";
import { Translate } from "../../../utils/lang/translate";
import { FormatDate } from "../../../utils/lang/translate";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import TableLoader from "../_Common/TableLoader";
import {
  ExportSvg,
  RefreshSvg,
  ViewSvg,
  OutSvg,
  CheckSvg,
} from "../_Common/IconSvg";
import IconButton from "../_Common/IconButton";
import SmallText from "../_Common/SmallText";
import InlinedElement from "../_Common/InlinedElement";
import ContainerPage from "../_Common/ContainerPage";
import GroupeBtnSp from "../_Common/GroupeBtnSp";
import DatePicker from "../../_Common/DatePicker";
import Table from "../_Common/Table";
import GroupeBtn from "../_Common/GroupeBtn";
import PaginationBar from "../_Common/PaginationBar";
import { RECEIVED_PRODUCT_HEADER } from "../../constants";
import { productActions, commonActions } from "../../../actions";
import { useQueryParam } from "use-query-params";
import PropTypes from "prop-types";

import { addUrlProps, UrlQueryParamTypes } from "react-url-query";

import { NoVoucher } from "../_Common/NoVoucher";
import { initGAPg, Event } from "../_Common";

import CheckBoxForm from "../../_Common/CheckBoxForm";

const urlPropsQueryConfig = {
  page: { type: UrlQueryParamTypes.number },

  began: { type: UrlQueryParamTypes.string },
  end: { type: UrlQueryParamTypes.string },
};
const TakenProduct = ({
  dataExit,
  ExportExitVoucher,
  GettingBonExit,
  GetAllexitsProducts,
  dtStoreinfo,
  onChangePage,
  page = 1,
  onChangeBegan,
  onChangeEnd,
  onChangeDate,
  end,
  began,
}) => {
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [reference, setReference] = useQueryParam("reference");
  const [inputReference, setInputReference] = useState(reference);
  const [titlepage, setTitlePage] = useState(
    <div>
      <Link className="StOpcVal" to="/stock/takenProduct">
        {Translate("titles", "receiveProduct")}
      </Link>
      <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
      <span>{Translate("product", "takenProduct")}</span>
    </div>
  );

  const changeInputReference = (e) => {
    const { value } = e.target;

    setInputReference(value);
  };

  const handleReferenceInput = () => {
    Event("VOUCHER_TAKEN", "FILTER_BY_REFERENCE", "CLICK_EVENT");
    if (inputReference === reference) {
      setInputReference("");
      setReference("");
    } else {
      setReference(inputReference);
    }
  };

  useEffect(() => {
    initGAPg();
  });

  useEffect(() => {
    GetAllexitsProducts(`delivery/vouchers/exit/?page=${page}`);
  }, [page]);

  useEffect(() => {
    if (reference && reference.length > 0)
      GetAllexitsProducts(`delivery/vouchers/exit/?search=${reference}`);
  }, [reference]);

  useEffect(() => {
    if (began && end)
      GetAllexitsProducts(
        `delivery/vouchers/exit/??start_date=${began}&end_date=${end}`
      );
  }, [began, end]);

  function refreshPage() {
    Event("VOUCHER_TAKEN", "USE_REFRESH_VOUCHER_RECEIVE_BUTTON", "CLICK_EVENT");
    window.location.href = "/stock/takenProduct";
  }
  
  let bodytab = [];

  bodytab = dataExit?.results?.map((elem, key) => {
    let actionBtns = [];
    const { id, reference, warehouse, created_at } = elem;

    actionBtns.push({
      type: "BDgInpc",
      action: () => (
        Event("VOUCHER_TAKEN", "VIEW_DETAILS_VOUCHER_TAKEN", "CLICK_EVENT"),
        (window.location.href = `/stock/detailTaken/${id}`)
      ),
      svgbtn: ViewSvg,
    });
    // actionBtns.push({ type: "BDgInpc", svgbtn: PrintSvg });
    return [
      <CheckBoxForm
        id={"select-exit-voucher-checkbox-" + key}
        name={"select-exit-voucher-checkbox-" + key}          
        check={selectedAll || selectedIDs.includes(id)}
        workfun={() => {
          setSelectedAll(false);
          if(selectedIDs.includes(id)) setSelectedIDs(prev => prev.filter(exitVoucherId => exitVoucherId !== id))
          else setSelectedIDs(prev => [ ...prev, id ]);
        }}
      />,
      "N° BS/" + reference,
      <SmallText lengthText={70}>{warehouse}</SmallText>,
      <SmallText lengthText={70}>{FormatDate(created_at)}</SmallText>,

      <GroupeBtn data_btn={actionBtns} />,
    ];
  });
  if (dataExit?.count == 0) {
    return <NoVoucher />;
  } else {
    return (
      <ContainerPage
        page_title={titlepage}
        data_top={
          <div>
            <div className="StAutMr d-sm">
              <div
                className="Inlflx AlgnItm StDrc StFlxStr FlWd"
                style={{ height: "48px" }}
              >
                <GroupeBtnSp
                  style={{ margin: "0", zoom: "1.2" }}
                  data_btn={[
                    {
                      type: "BDgInpc",
                      action: refreshPage,
                      svgbtn: RefreshSvg,
                      tooltip: Translate("tooltips", "refresh"),
                    },
                  ]}
                />
              </div>
            </div>
            <div className="d-large">
              <DatePicker
                from={began}
                to={end}
                changeFrom={onChangeBegan}
                changeTo={onChangeEnd}
                chnageDate={onChangeDate}
              />
            </div>
          </div>
        }
      >
        <div
          className="InFlx flxDrc StmpB3 StPs zindX2 BdgGc"
          style={{ height: "48px", padding: "7px 0", top: "-20px" }}
        >
          <div role="filter-data" className="FlHg InFlx StwdMaxC flxDrc">
            <div className="FlHg StBrdRdS ClSidTh">
              <InlinedElement
                style={{ padding: "0" }}
                secnd={
                  <div>
                    <input
                      type="text"
                      autoComplete="off"
                      name="filterbyid"
                      value={inputReference}
                      onChange={changeInputReference}
                      style={{ width: "300px", maxWidth: "30vw" }}
                      className="StdirLn FntfMin StPdsml BdgTrans DlBrd StSizLn Fntcl"
                      placeholder={Translate("product", "searchVoucher")}
                    />
                  </div>
                }
                leftElm={
                  <button
                    onClick={handleReferenceInput}
                    className="BdgGc DlBrd CrsPoi Inlflx StBgbrd"
                    style={{ padding: "10px", margin: "0 5px" }}
                  >
                    {inputReference === reference ? OutSvg : CheckSvg}
                  </button>
                }
              />
            </div>
          </div>

          <div className="d-large">
            <div
              className="FlWd InFlx"
              style={{ height: "48px", margin: "0 20px", gap: "12px" }}
            >
              <IconButton
                clickHandler={refreshPage}
                icon={RefreshSvg}
              />
              {
                !dtStoreinfo.stock_managed
                &&
                (
                  <IconButton
                    label={Translate("titles", "export")}
                    clickHandler={() => {
                      Event("VOUCHER_TAKEN", "EXIT_VOUCHER_EXCEL_EXPORT", "CLICK_EVENT");
                      if(selectedAll || selectedIDs.length === 0) ExportExitVoucher("xlsx", true, []);
                      else ExportExitVoucher("xlsx", false, selectedIDs);
                    }}
                    icon={ExportSvg}
                  />
                )
              }
            </div>
          </div>
        </div>
        <div className="d-sm" style={{ marginBottom: "20px" }}>
          <DatePicker
            from={began}
            to={end}
            changeFrom={onChangeBegan}
            changeTo={onChangeEnd}
            chnageDate={onChangeDate}
          />
        </div>
        {GettingBonExit == 1 ? (
          <TableLoader />
        ) : (
          <Fragment>
            <Table
              thead_elem={[
                <CheckBoxForm
                  id={"select-all-exit-vouchers-checkbox"}
                  name={"select-all-exit-vouchers-checkbox"}          
                  check={selectedAll}
                  workfun={() => setSelectedAll(prev => !prev)}
                />,
                ...RECEIVED_PRODUCT_HEADER
              ]}
              tbody_elem={bodytab}
            />
            <div role="pagination" className="lytWdp SmMarS1">
              <div className="InFlx flxDrc FlWd RlPs AlgnItm">
                <span className="d-large">
                  {Translate("orders", "showing") +
                    " " +
                    (page * 20 - 19) +
                    " - " +
                    (page * 20 < dataExit?.count
                      ? page * 20
                      : dataExit?.count) +
                    " " +
                    Translate("orders", "of") +
                    " " +
                    dataExit?.count}
                </span>
                <PaginationBar
                  className="StAutMr"
                  NbPage={Math.trunc(dataExit?.count / 20) + 1}
                  currentPage={parseInt(page)}
                  ChangePage={onChangePage}
                  blockNext={dataExit?.next}
                  blockPrev={dataExit?.previous}
                />
              </div>
            </div>
          </Fragment>
        )}
      </ContainerPage>
    );
  }
};

function mapState(state) {
  const { dataExit, GettingBonExit } = state.product;
  const { dtStoreinfo } = state.user;

  return { dataExit, GettingBonExit, dtStoreinfo };
}

const actionCreators = {
  GetAllexitsProducts: productActions.GetAllexitsProducts,
  ExportExitVoucher: productActions.ExportExitVoucher,
};

const connectedProductsAll = connect(mapState, actionCreators)(TakenProduct);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedProductsAll);
export { QueryProps as TakenProduct };

TakenProduct.defaultProps = {
  page: 1,
};

TakenProduct.propTypes = {
  page: PropTypes.number,
  began: PropTypes.string,
  end: PropTypes.string,
  onChangePage: PropTypes.func,
  onChangeBegan: PropTypes.func,
  onChangeEnd: PropTypes.func,
};
